import {useEffect, useMemo, useRef, useState} from 'react';
import {useOutletContext, useNavigate} from 'react-router-dom';

import useGetCouriers from './hooks/useGetCouriers';
import CloseIcon from '../../assets/icons/close-square.svg';
import ExportIcon from '../../assets/icons/export.svg';
import filterIcon from '../../assets/icons/filter-icon.svg';
import MapIcon from '../../assets/icons/map-Icon.svg';
import rightArrow from '../../assets/icons/outline-right-arrow.svg';
import Button from '../../components/common/Button';
import DropDown from '../../components/common/DropDown';
import IconButton from '../../components/common/IconButton';
import Map, {MarkerDetails} from '../../components/common/Map';
import Paginator from '../../components/common/Paginator';
import Spinner from '../../components/common/Spinner';
import CommonTable from '../../components/common/table';
import {CourierType, WalletActivationStatus} from '../../generated';
import {readableWalletStatus} from '../../utils/reusablefunctions';

type Props = {
  courierType?: CourierType;
};

const Couriers = ({courierType}: Props) => {
  const [searchPhrase] = useOutletContext<[string]>();

  const [open, setOpen] = useState(false);
  const [walletStatus, setWalletStatus] = useState<WalletActivationStatus[]>();
  const [height, setHeight] = useState(open ? 'auto' : '0px');
  const [opacity, setOpacity] = useState(open ? 1 : 0);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const {
    columns,
    data,
    error,
    setData,
    parentColumns,
    selectedIds,
    setSelectedIds,
    setColumns,
    sortingColumns,
    loading,
    loadMore,
    count,
    allCouriers,
    formatEnumContent,
  } = useGetCouriers(courierType, walletStatus, searchPhrase);

  const viewRecord = (id: string) => navigate(`/courier/${id}/account`);

  useEffect(() => {
    if (!searchPhrase) {
      setData(
        allCouriers?.couriersWithCursorPagination.edges.map((courier) => ({
          id: courier.node.id || '',
          name: [
            courier.node.user?.name || '-',
            courier.node.avatar?.small || '',
          ],
          phone_number: `+${courier.node.user?.phone?.countryCode || '-'}${
            courier.node.user?.phone?.number || '-'
          }`,
          vehicle_type: formatEnumContent(courier.node.vehicleType || '-'),
          license_plate: courier.node.licensePlate || '-',
          cashtag: courier.node.tag || '-',
          status:
            (courier?.node.wallet?.activation?.status &&
              readableWalletStatus(courier.node.wallet.activation.status)) ||
            '',
        })) || []
      );
      return;
    }

    setData(
      allCouriers?.couriersWithCursorPagination.edges.map((courier) => ({
        id: courier.node.id || '',
        name: [
          courier.node.user?.name || '-',
          courier.node.avatar?.small || '',
        ],
        phone_number: `+${courier.node.user?.phone?.countryCode || '-'}${
          courier.node.user?.phone?.number || '-'
        }`,
        vehicle_type: formatEnumContent(courier.node.vehicleType || '-'),
        license_plate: courier.node.licensePlate || '-',
        cashtag: courier.node.tag || '-',
        status:
          (courier?.node.wallet?.activation?.status &&
            readableWalletStatus(courier?.node.wallet.activation.status)) ||
          '',
      })) || []
    );
  }, [allCouriers, formatEnumContent, searchPhrase, setData]);

  const markers: MarkerDetails[] = useMemo(
    () =>
      allCouriers?.couriersWithCursorPagination.edges.map((courier) => ({
        id: courier.node.id,
        online: courier.node.online,
        name: courier.node.user?.name,
        lat: courier.node.currentPosition?.lat,
        lng: courier.node.currentPosition?.long,
        imgUrl: courier.node.avatar?.small,
      })) || [],
    [allCouriers]
  );

  useEffect(() => {
    if (contentRef.current) {
      setHeight(open ? '600px' : '0px');
      setOpacity(open ? 1 : 0);
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
    setWalletStatus(undefined);
  }, [courierType]);

  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {data.length ? (
        <div className="flex h-full flex-col gap-6 overflow-y-scroll">
          <div className="flex flex-col">
            <div className="flex justify-end ">
              <Button
                label="View live map"
                rightIcon={<img src={MapIcon} className="ml-2" />}
                className="rounded-md bg-mediumPurple px-[15px] disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                labelStyle="text-white text-sm"
                onClick={() => setOpen(!open)}
              />
            </div>
            <div
              ref={contentRef}
              style={{
                maxHeight: `${height}`,
                opacity: `${opacity}`,
                transition:
                  'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
              }}
              className="overflow-hidden">
              <div className="relative ml-1 mt-8 h-[500px] w-full rounded-2xl bg-greyish">
                <div className="absolute left-4 top-4 z-[5]">
                  <Button
                    label="Close"
                    rightIcon={<img src={CloseIcon} className="ml-2" />}
                    className="w-inherit rounded-md bg-white px-[15px] text-black disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                    labelStyle="text-black"
                    onClick={() => setOpen(!open)}
                  />
                </div>
                <Map markers={markers} />
              </div>
            </div>
          </div>
          <div className="flex h-full w-full flex-col justify-between gap-6">
            <CommonTable
              columns={columns}
              data={data}
              setData={setData}
              parentColumns={parentColumns}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              setColumns={setColumns}
              viewRecord={viewRecord}
              sortingColumns={sortingColumns}
              viewRecordIconOrText={ExportIcon}
              moreFilterElement={
                <FilterByWalletStatus
                  formatEnumContent={formatEnumContent}
                  setWalletStatus={setWalletStatus}
                />
              }
            />
            <Paginator
              loadMore={loadMore}
              count={count}
              pageInfo={allCouriers?.couriersWithCursorPagination.pageInfo}
            />
          </div>
        </div>
      ) : (
        <h1 className="rounded-lg bg-slate-200 p-6 normal-case text-black">
          There are no{' '}
          {walletStatus &&
            formatEnumContent(walletStatus[0]).toLocaleLowerCase()}{' '}
          {courierType
            ? formatEnumContent(courierType).toLocaleLowerCase()
            : 'couriers'}{' '}
          to display: NB{' '}
          <span
            className="cursor-pointer text-blue-700 underline"
            onClick={() => setWalletStatus(undefined)}>
            remove wallet filter.
          </span>
        </h1>
      )}
    </>
  );
};

type FilterProps = {
  formatEnumContent: (str: string) => string;
  setWalletStatus: React.Dispatch<
    React.SetStateAction<WalletActivationStatus[] | undefined>
  >;
};

const FilterByWalletStatus = ({
  formatEnumContent,
  setWalletStatus,
}: FilterProps) => {
  return (
    <DropDown
      dropDownPosition="right"
      className="right-0 top-7"
      persistent
      actionElement={<IconButton icon={filterIcon} />}>
      <div className="flex w-[300px] flex-col gap-2 rounded-lg bg-white p-5 py-4 text-sm text-gray-700">
        <div
          onClick={() => setWalletStatus(undefined)}
          className="flex cursor-pointer justify-between rounded-lg border-2 border-dividerGrey p-4">
          <p className="text-base font-medium normal-case">All</p>
          <img src={rightArrow} />
        </div>
        {Object.values(WalletActivationStatus).map((status, i) => (
          <div
            key={i}
            onClick={() => setWalletStatus([status])}
            className="flex cursor-pointer justify-between rounded-lg border-2 border-dividerGrey p-4">
            <p className="text-base capitalize file:font-medium">
              {formatEnumContent(status)}
            </p>
            <img src={rightArrow} />
          </div>
        ))}
      </div>
    </DropDown>
  );
};

export default Couriers;
