import React, {useState, useEffect, useCallback} from 'react';

type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerStyle?: string;
  description?: string;
  descriptionStyle?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: boolean;
  id?: string;
  inputStyle?: string;
  label?: string;
  labelStyle?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  type?: string;
  startAdornment?: React.ReactNode;
  autocompleteValues: AutoCompleteValue[];
  value: AutoCompleteValue | undefined;
  setValue: React.Dispatch<React.SetStateAction<AutoCompleteValue | undefined>>;
};

export type AutoCompleteValue = {
  id?: string | null;
  name: string;
};

const AutoComplete = ({
  id,
  label,
  description,
  labelStyle,
  descriptionStyle,
  containerStyle,
  inputStyle,
  value,
  setValue,
  startAdornment,
  endAdornment,
  placeholder,
  autocompleteValues,
  ...other
}: Props) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutoCompleteValue[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      autocompleteValues.find(
        (item) => item.name.toLowerCase() === event.target.value.toLowerCase()
      ) || {
        id: null,
        name: event.target.value,
      }
    );

    setAutocompleteOptions(
      autocompleteValues.filter((option) =>
        option.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
    setSelectedIndex(null);
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex !== null ? Math.max(prevIndex - 1, 0) : null
          );
          return;
        case 'ArrowDown':
          event.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex !== null
              ? Math.min(prevIndex + 1, autocompleteOptions.length - 1)
              : 0
          );
          return;
        case 'Enter':
          if (selectedIndex !== null) {
            event.preventDefault();
            setValue(autocompleteOptions[selectedIndex]);
            setSelectedIndex(null);
            setAutocompleteOptions([]);
          }
          return;
        default:
          return;
      }
    },
    [selectedIndex, autocompleteOptions, setValue]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown as never);
    return () => {
      window.removeEventListener('keydown', handleKeyDown as never);
    };
  }, [handleKeyDown]);

  const handleDropdownClick = (index: number) => {
    setValue(autocompleteOptions[index]);
    setSelectedIndex(null);
    setAutocompleteOptions([]);
  };

  return (
    <div className={`flex w-full flex-col ${containerStyle || ''}`}>
      <h1 className={`${labelStyle || 'text-xl font-semibold leading-5'}`}>
        {label}
      </h1>
      {description && (
        <p
          className={`${descriptionStyle || 'pt-2.5 leading-5 text-darkGrey'}`}>
          {description}
        </p>
      )}
      <div
        className={`peer flex basis-full flex-row items-center rounded-lg border-half border-greyish bg-offWhite px-2.5 py-2 focus-within:!bg-white focus-within:ring-2 focus-within:ring-black ${
          description ? 'mt-2.5' : ''
        }`}>
        {startAdornment}
        <input
          type="text"
          value={value?.name}
          onChange={handleInputChange}
          className={`peer flex basis-full appearance-none  bg-offWhite px-2.5 py-2 text-sm font-medium text-black placeholder-foggy autofill:!bg-offWhite focus:!bg-white focus:outline-none ${
            inputStyle || ''
          }`}
          placeholder={placeholder}
          {...other}
        />
        <div
          className="cursor-pointer"
          onClick={() => {
            endAdornment &&
            autocompleteOptions.length === autocompleteValues.length
              ? setAutocompleteOptions([])
              : setAutocompleteOptions(autocompleteValues);
          }}>
          {endAdornment}
        </div>
      </div>
      {autocompleteOptions.length ? (
        <ul className="mt-2 rounded-lg bg-veryLightGrey">
          {autocompleteOptions.map((option, index) => (
            <li
              key={index}
              className={`${
                selectedIndex === index ? 'bg-lightPaige' : ''
              } cursor-pointer p-2`}
              onClick={() => handleDropdownClick(index)}>
              {option.name}
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AutoComplete;
