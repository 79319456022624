type IconName = 'icon1' | 'icon2';

type Props = {
  icon1: JSX.Element;
  icon2: JSX.Element;
  onClick1: () => void;
  onClick2: () => void;
  activeIcon: IconName | string;
  containerStyles?: string;
  iconStyles?: string;
};

const ToggleListView = ({
  icon1,
  icon2,
  onClick1,
  onClick2,
  activeIcon,
  containerStyles,
  iconStyles,
}: Props) => {
  return (
    <div
      className={`w-inherit flex h-[38px] items-center justify-between gap-2 rounded-md border border-black bg-transparent px-1 ${
        containerStyles || ''
      }`}>
      <div
        onClick={onClick1}
        className={`flex cursor-pointer items-center justify-center ${
          activeIcon === 'icon1' ? 'rounded bg-purple-300 p-1' : ''
        } ${iconStyles || ''}`}>
        {icon1}
      </div>
      <div
        onClick={onClick2}
        className={`flex cursor-pointer items-center justify-center ${
          activeIcon === 'icon2' ? 'rounded bg-purple-300 p-1' : ''
        } ${iconStyles || ''}`}>
        {icon2}
      </div>
    </div>
  );
};

export default ToggleListView;
