import {useCallback, useEffect, useState} from 'react';

import {useGetReelsQuery, CursorInput} from '../../../generated';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetReel(merchantId: string) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const {
    data: allReels,
    refetch,
    loading: dataLoading,
    error,
  } = useGetReelsQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
        merchantId: merchantId || '',
      },
    },
  });

  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after = allReels?.reels.pageInfo.endCursor;
        cursorLoad.first = 20;
      } else {
        cursorLoad.before = allReels?.reels.pageInfo.startCursor;
        cursorLoad.last = 20;
      }
      const res = await refetch({
        input: {cursor: cursorLoad, merchantId: merchantId || ''},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      count,
      allReels?.reels.pageInfo.endCursor,
      allReels?.reels.pageInfo.startCursor,
      merchantId,
      refetch,
    ]
  );

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'reels',
    'views',
    'likes',
  ]);
  const parentColumns: TableColumnType[] = ['reels', 'views', 'likes'];
  const sortingColumns: TableColumnType[] = ['reels'];

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!allReels?.reels.edges) return;
    setData(
      allReels?.reels.edges.map((reels) => ({
        id: reels.node.id || '',
        reels: [reels?.node?.name || '-', reels?.node?.thumbnail?.small || ''],
        views: `${reels?.node?.views}`,
        likes: `${reels?.node?.likes}`,
      })) || []
    );
  }, [allReels]);

  return {
    data,
    setData,
    allReels,
    count,
    loadMore,
    loading,
    error,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
  };
}
export default useGetReel;
