import {useEffect, useState} from 'react';

import {AutoCompleteValue} from '../../../components/common/AutoComplete';
import {useNestedMerchantCategoryQuery} from '../../../generated';
import {createHashtag} from '../../../utils/reusablefunctions';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetNestedSubCategories(id: string, active: boolean) {
  const {
    data: category,
    loading,
    error,
  } = useNestedMerchantCategoryQuery({
    variables: {id, active},
  });

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'hashtag',
  ]);
  const sortingColumns: TableColumnType[] = ['name'];

  const autocompleteValues: AutoCompleteValue[] =
    category?.merchantCategory?.children?.map((child) => ({
      id: child.id,
      name: child.name,
    })) || [];

  useEffect(() => {
    setData(
      category?.merchantCategory?.children?.map((subcategory) => ({
        id: subcategory.id || '',
        name: subcategory.name || '-',
        children: subcategory.children?.map((item) => {
          return {
            id: item.id || '',
            name: item.name || '-',
            hashtag: createHashtag(item.name),
          };
        }),
      })) || []
    );
  }, [category?.merchantCategory?.children]);

  return {
    data,
    setData,
    category,
    loading,
    error,
    columns,
    setColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
    autocompleteValues,
  };
}

export default useGetNestedSubCategories;
