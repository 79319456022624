import {useEffect, useState} from 'react';

import {useMerchantCategoryQuery} from '../../../generated';
import {createHashtag} from '../../../utils/reusablefunctions';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetSubCategories(id: string) {
  const {
    data: category,
    loading,
    error,
  } = useMerchantCategoryQuery({
    variables: {id},
  });

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'hashtag',
    'subcategories',
  ]);
  const parentColumns: TableColumnType[] = ['name', 'hashtag', 'subcategories'];
  const sortingColumns: TableColumnType[] = ['name'];

  useEffect(() => {
    setData(
      category?.merchantCategory?.children?.map((subcategory) => ({
        id: subcategory.id || '',
        name: [subcategory.name || '-', subcategory.image?.small || ''],
        hashtag: createHashtag(subcategory.name),
        subcategories: 'Select, edit and add sub categories',
      })) || []
    );
  }, [category?.merchantCategory?.children]);

  return {
    data,
    setData,
    category,
    loading,
    error,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
  };
}

export default useGetSubCategories;
