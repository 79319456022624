import {createContext, useContext} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';

import {BreadcrumbType} from '../components/common/Breadcrumb';
import Spinner from '../components/common/Spinner';
import TreeNav, {NavTreeType} from '../components/common/TreeNav';
import {CustomerFieldsFragment, useCustomerQuery} from '../generated';

type CustomerContent = {
  breadcrumbs: BreadcrumbType[];
  customer?: CustomerFieldsFragment;
};
const CustomerContext = createContext<CustomerContent>({
  breadcrumbs: [],
});
export const useCustomerContext = () => useContext(CustomerContext);

const tree = (id: string): NavTreeType[] => {
  return [
    {
      name: undefined,
      link: undefined,
      children: [
        {
          name: 'Account Status',
          link: `/customer/${id}/account`,
        },
        {
          name: 'Personal Details',
          link: `/customer/${id}/account#personal-details`,
        },
        {
          name: 'Wallet Details',
          link: `/customer/${id}/account#wallet-details`,
        },
        {
          name: 'Transactions',
          link: `/customer/${id}/transactions`,
        },
      ],
    },
  ];
};

const CustomerViewLayout = () => {
  const {id: customerId} = useParams();
  const {pathname} = useLocation();
  const {data, loading} = useCustomerQuery({
    variables: {id: customerId || ''},
  });

  const shortenTxt = (txt: string) => {
    if (txt?.length > 20) return `${txt.substring(0, 15)}...`;
    return txt;
  };

  const getBreadCrumbs = () => {
    const breadcrumbs = [
      {
        name: 'Customers',
        location: '/customers',
      },
      {
        name: shortenTxt(data?.customer.user?.name || '-'),
        location: `/customer/${customerId || ''}/account`,
      },
    ];
    if (pathname.includes('transaction')) {
      breadcrumbs.push({
        name: 'Transactions',
        location: `/customer/${customerId || ''}/transactions`,
      });
    }
    return breadcrumbs;
  };

  const value: CustomerContent = {
    breadcrumbs: getBreadCrumbs(),
    customer: data?.customer,
  };

  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="flex h-screen w-80 flex-col gap-6 pt-8">
        <p className="px-[30px] text-2xl font-semibold leading-6">
          {shortenTxt(data?.customer.user?.name || '-')}
        </p>
        <TreeNav tree={tree(customerId || '')} />
      </div>
      <div className="w-full overflow-y-scroll px-8">
        <CustomerContext.Provider value={value}>
          <Outlet />
        </CustomerContext.Provider>
      </div>
    </div>
  );
};

export default CustomerViewLayout;
