import {useCallback, useEffect, useState} from 'react';

import {
  CursorInput,
  WalletActivationStatus,
  useCustomersWithCursorPaginationQuery,
} from '../../../generated';
import {readableWalletStatus} from '../../../utils/reusablefunctions';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';
type UseGetCustomerProps = {
  walletStatus?: WalletActivationStatus[];
  searchTerm?: string;
};
function useGetUsers({walletStatus, searchTerm}: UseGetCustomerProps) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const {
    data: allCustomers,
    loading: dataLoading,
    error,
    refetch,
  } = useCustomersWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
        walletStatus,
        search: searchTerm || '',
      },
    },
  });

  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after =
          allCustomers?.customersWithCursorPagination.pageInfo.endCursor;
        cursorLoad.first = 20;
      } else {
        cursorLoad.before =
          allCustomers?.customersWithCursorPagination.pageInfo.startCursor;
        cursorLoad.last = 20;
      }
      const res = await refetch({
        input: {cursor: cursorLoad, walletStatus},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      allCustomers?.customersWithCursorPagination.pageInfo.endCursor,
      allCustomers?.customersWithCursorPagination.pageInfo.startCursor,
      count,
      refetch,
      walletStatus,
    ]
  );

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'identificationNumber',
    'matchscore',
    'status',
  ]);
  const parentColumns: TableColumnType[] = [
    'name',
    'identificationNumber',
    'matchscore',
    'status',
  ];
  const sortingColumns: TableColumnType[] = ['name', 'status'];

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!allCustomers?.customersWithCursorPagination?.edges) return;
    setData(
      allCustomers?.customersWithCursorPagination?.edges.map((customer) => ({
        id: customer.node.id || '',
        name: [
          customer.node.user?.name || '-',
          customer.node.avatar?.small || '',
        ],
        identificationNumber: customer.node.user?.identificationNumber || '-',
        matchscore: String(
          customer.node.user?.kycVerificationStatus?.matchScore || '0'
        ),
        cashtag: customer.node.tag || '-',
        status:
          (customer.node.wallet?.activation?.status &&
            readableWalletStatus(customer.node.wallet.activation.status)) ||
          '',
      })) || []
    );
  }, [allCustomers]);

  useEffect(() => {
    setCount(1);
  }, [walletStatus]);

  return {
    data,
    setData,
    searchTerm,
    allCustomers,
    count,
    loadMore,
    loading,
    error,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
  };
}

export default useGetUsers;
