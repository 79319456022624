import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import greenClose from '../../../assets/icons/green-close-icon.svg';
import orangeclose from '../../../assets/icons/orange-close-icon.svg';
import redClose from '../../../assets/icons/red-close-icon.svg';
import swgliLines from '../../../assets/icons/swgli-lines.svg';
import Modal from '../../../components/common/Modal';
import Spinner from '../../../components/common/Spinner';
import CommonTable from '../../../components/common/table';
import {
  TransactionStatus,
  TransactionsFieldsFragment,
} from '../../../generated';
import {useMerchant} from '../../../layouts/MerchantDetailsLayout';
import {useMerchantTransactionSelectionContext} from '../../../layouts/MerchantTransactionsLayout.';
import formatDate from '../../../utils/reusablefunctions/formatDate';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import transactionOptions from '../../../utils/reusablefunctions/transactionOptions';
import {TableDataType} from '../../../utils/types/table.types';
import useGetMerchantTransactions from '../hooks/useGetMerchantTransactions';

type TransactionProps = {status?: TransactionStatus};
type ModalSectionProps = {
  right: string | JSX.Element;
  left?: string | null;
  large?: boolean;
};

function Transactions({status}: TransactionProps) {
  const {merchantId} = useParams();
  const {merchant} = useMerchant();
  const {data, columns, allTransactions, sortingColumns, loading, error} =
    useGetMerchantTransactions(merchantId || '', status);
  const {selectedCategory} = useMerchantTransactionSelectionContext();
  const [filteredData, setFilteredData] = useState<TableDataType[]>([]);
  const [selectedTransaction, setselectedTransaction] =
    useState<TransactionsFieldsFragment>();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const viewRecord = (id: string) => {
    setselectedTransaction(
      allTransactions?.transactions.transactions.find((item) => item.id === id)
    );
    toggleModal();
  };

  const statusBgColor = (transactionStatus: TransactionStatus) => {
    return {
      FAILED: ['bg-darkRed', redClose],
      PENDING: ['bg-orangeLight', orangeclose],
      SUCCESS: ['bg-brightGreen', greenClose],
    }[transactionStatus];
  };

  useEffect(() => {
    if (selectedCategory) {
      setFilteredData(
        data.filter((item) => item.transaction_category === selectedCategory)
      );
    } else {
      setFilteredData([...data]);
    }
  }, [data, selectedCategory]);

  if (loading) {
    return (
      <div className="mt-20 flex h-full w-full flex-col">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-4 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {filteredData.length ? (
        <div className="mb-9 flex flex-col pt-4">
          <CommonTable
            columns={columns}
            data={filteredData}
            setData={setFilteredData}
            sortingColumns={sortingColumns}
            viewRecord={viewRecord}
            prettify
          />
          {selectedTransaction && showModal && (
            <Modal
              isVisible={showModal}
              onClose={toggleModal}
              width="630px"
              className="rounded-t-2xl">
              <div className="relative rounded-t-2xl">
                <img
                  src={statusBgColor(selectedTransaction.status)[1]}
                  className="absolute right-6 top-7 cursor-pointer"
                  onClick={toggleModal}
                />
                <div
                  className={`${
                    statusBgColor(selectedTransaction.status)[0]
                  } grid h-[240px] w-[630px] rounded-t-2xl`}>
                  <img
                    src={swgliLines}
                    className="col-start-1 row-start-1 self-center"
                  />
                  <div className="col-start-1 row-start-1 px-5">
                    <p className="ml-3 mt-24 text-sm font-medium text-white">
                      {formatDate(selectedTransaction.date as Date, {
                        withWeekday: true,
                        withShortMonth: true,
                        withTime: true,
                        withYear: true,
                        hour12: true,
                      })}
                    </p>
                    <p className="ml-3 mt-7 text-2xl font-semibold text-white">
                      {
                        transactionOptions(selectedTransaction, merchant?.user)
                          .des
                      }
                    </p>
                  </div>
                </div>
                <div className="flex w-full flex-col px-8">
                  <ModalSection
                    right="Amount"
                    left={`KES ${formatMoney(selectedTransaction.amount)}`}
                    large
                  />
                  <ModalSection
                    right="Category"
                    left={
                      transactionOptions(selectedTransaction, merchant?.user)
                        .type
                    }
                  />
                  <ModalSection
                    right={
                      transactionOptions(selectedTransaction, merchant?.user)
                        .direction || ''
                    }
                    left={
                      transactionOptions(selectedTransaction, merchant?.user)
                        .name
                    }
                  />
                  <ModalSection right="Transaction cost" left="KES 0" />
                  <ModalSection
                    right="Transaction ID"
                    left={selectedTransaction.referenceNumber}
                  />
                </div>
              </div>
            </Modal>
          )}
        </div>
      ) : (
        <h1 className="mt-4 rounded-lg bg-slate-200 p-6 font-normal text-black">
          There are no{' '}
          {selectedCategory
            ? selectedCategory.toLowerCase()
            : status?.toLowerCase()}{' '}
          transactions to display
        </h1>
      )}
    </>
  );
}

const ModalSection = ({right, left, large}: ModalSectionProps) => {
  return (
    <>
      <div className="flex w-full justify-between py-7">
        {typeof right === 'string' ? (
          <p
            className={`${
              large ? 'text-xl' : 'text-lg'
            }	font-normal text-foggy`}>
            {right}:
          </p>
        ) : (
          right
        )}
        <p className={`${large ? 'text-2xl' : 'text-xl'} font-semibold`}>
          {left}
        </p>
      </div>
      <hr className="h-[2px] bg-dividerGrey" />
    </>
  );
};

export default Transactions;
