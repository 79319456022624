import {PropsWithChildren, useCallback, useState} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';

import Avatar from '../assets/avatar.png';
import Arrow from '../assets/icons/arrow-right.svg';
import Car from '../assets/icons/car.svg';
import DashboardIcon from '../assets/icons/dashboard.svg';
import Logout from '../assets/icons/logout.svg';
import Merchant from '../assets/icons/merchant.svg';
import Notebook from '../assets/icons/notes.svg';
// import Support from '../assets/icons/support.svg';
import UserVerification from '../assets/icons/user-search.svg';
import Customers from '../assets/icons/user.svg';
import {useAuth} from '../utils/auth';
import {useUserAuth} from '../utils/user';

const MainLayout = () => {
  const [open, setOpen] = useState(true);
  const {logout} = useAuth();
  const navigate = useNavigate();
  const {user} = useUserAuth();
  const {pathname} = useLocation();

  const Menu = [
    {title: 'Dashboard', icon: DashboardIcon, link: '/dashboard'},
    {title: 'Merchants', icon: Merchant, link: '/merchants'},
    {
      title: 'Merchant categories',
      icon: Notebook,
      link: '/merchant-categories',
    },
    {
      title: 'Customers',
      icon: Customers,
      link: '/customers',
      match: 'customer',
    },
    {
      title: 'Drivers & Riders',
      icon: Car,
      link: '/couriers',
      match: 'courier',
    },
    {
      title: 'User Verification',
      icon: UserVerification,
      link: '/user-verification',
    },
    // {title: 'Support', icon: Support, link: '/support'},
  ];

  const isActive = useCallback(
    (path: string) => pathname.includes(path),
    [pathname]
  );

  return (
    <RequireAuth>
      <div className="flex h-screen w-screen overflow-hidden">
        <div
          className={`relative flex h-screen flex-col gap-10 ${
            open ? 'w-80' : 'w-20'
          } bg-offWhite p-5 pt-10 duration-300`}>
          <div className="flex h-36 flex-col items-center">
            <span
              className="absolute -right-5 top-[200px] flex h-9 w-9 cursor-pointer  items-center justify-center rounded-full border-0 border-black bg-white text-3xl shadow-[0_0_10px_rgba(0,0,0,0.16)]"
              onClick={() => setOpen(!open)}>
              <img
                src={Arrow}
                className={`z-10 h-5 w-5 ${open ? 'rotate-180' : ''}`}
              />
            </span>
            <img className="float-left w-[80px]" src={Avatar} />
            {open && (
              <>
                <h2 className="float-left whitespace-nowrap text-2xl font-semibold text-black duration-300">
                  {user?.name}
                </h2>
                {/* <p className="float-left cursor-pointer whitespace-nowrap text-sm text-foggy duration-300">
                  View my profile
                </p> */}
              </>
            )}
          </div>
          <ul className="flex w-full flex-1 flex-col justify-between overflow-y-auto pt-7">
            <div>
              {Menu.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => navigate(menu.link)}
                  className={`mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-sm text-foggy active:text-mediumPurple ${
                    isActive(menu.match || menu.link)
                      ? 'bg-lightPurple text-mediumPurple'
                      : ''
                  }`}>
                  <img src={menu.icon} className="float-left block h-6 w-6" />
                  {open && (
                    <span className="flex-1 whitespace-nowrap break-keep text-base">
                      {menu.title}
                    </span>
                  )}
                </li>
              ))}
            </div>
            <li
              onClick={logout}
              className="mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-foggy">
              <img src={Logout} />
              {open && (
                <span className="flex-1 whitespace-nowrap break-keep text-base">
                  Log out
                </span>
              )}
            </li>
          </ul>
        </div>
        <div className="w-full bg-white">
          <Outlet />
        </div>
      </div>
    </RequireAuth>
  );
};

const RequireAuth = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export default MainLayout;
