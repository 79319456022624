import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import searchIcon from '../../../assets/icons/search_icon.svg';
import Spinner from '../../../components/common/Spinner';
import TextInput from '../../../components/common/TextInput';
import CommonTable from '../../../components/common/table';
import {useProductQuery} from '../../../generated';
import {useMyContext} from '../../../layouts/MerchantDetailsLayout';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

const VariantList = () => {
  const {productId} = useParams();
  const [loading, setLoading] = useState(false);
  const {onPageChange} = useMyContext();

  const {
    data: product,
    loading: getProductLoading,
    error: getProductError,
  } = useProductQuery({
    variables: {
      id: productId || '',
    },
  });

  useEffect(() => {
    product?.product?.title && onPageChange(product?.product?.title);
  }, [product?.product?.title, onPageChange]);

  const [data, setData] = useState<TableDataType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'variant',
    'price',
    'items_in_stock',
  ]);
  const parentColumns: TableColumnType[] = [
    'variant',
    'price',
    'items_in_stock',
  ];
  const sortingColumns: TableColumnType[] = ['variant'];

  useEffect(() => {
    setLoading(getProductLoading);
  }, [getProductLoading]);

  useEffect(() => {
    if (!product?.product?.variants) return;

    const mappedData = product.product.variants.map((item) => {
      const options = item?.option || [];

      const optionDetails = options.map((option) => {
        const optionName: string = option?.name || '';
        const optionValue: string = option?.value || '';

        return {
          optionName: optionName,
          optionValue: optionValue,
        };
      });

      const firstOption =
        optionDetails.length > 0
          ? `${optionDetails[0].optionName}: ${optionDetails[0].optionValue}`
          : '';
      const secondOption =
        optionDetails.length > 1
          ? `${optionDetails[1].optionName}: ${optionDetails[1].optionValue}`
          : '';

      return {
        id: item.id || '',
        variant: [secondOption, item.images?.[0]?.small || '', firstOption],
        price: `${item?.price?.currencyCode || ''} ${formatMoney(
          item?.price?.amountInCents || 0
        )}`,
        items_in_stock: `${40}`,
        optionDetails: optionDetails,
      };
    });

    setData(mappedData);
  }, [product]);

  if (loading) {
    return (
      <div className="mt-2 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (getProductError) {
    return (
      <h1 className="mt-3 rounded-lg bg-red-500 p-6 text-white">
        Error: {getProductError.message}
      </h1>
    );
  }

  return (
    <div className=" flex w-full basis-full flex-col overflow-scroll pr-8">
      <h1 className="mb-2 text-2xl font-semibold">Variant List</h1>
      <TextInput
        endAdornment={<img src={searchIcon} className="mr-3" />}
        placeholder="Find a Variant..."
        inputStyle="h-5 "
      />
      <div className="flex h-full flex-col gap-6 overflow-y-scroll" />
      <div className="flex h-full w-full flex-col justify-between gap-6">
        <>
          <CommonTable
            columns={columns}
            data={data}
            setData={setData}
            parentColumns={parentColumns}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setColumns={setColumns}
            sortingColumns={sortingColumns}
            viewRecordIconOrText="Restrict"
          />
        </>
      </div>
    </div>
  );
};

export default VariantList;
