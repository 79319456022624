type Props = {
  leftIcon?: string | JSX.Element;
  label: string | JSX.Element;
  rightIcon?: string | JSX.Element;
  onClick?: () => void;
  text?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  labelStyle?: string;
};

const Button = ({
  onClick,
  label,
  leftIcon,
  rightIcon,
  text = false,
  disabled = false,
  fullWidth = false,
  className,
  labelStyle,
}: Props) => {
  return (
    <div
      className={`select-none ${text ? 'bg-transparent' : ''} flex h-[38px] ${
        fullWidth ? 'w-full' : 'w-fit'
      } cursor-pointer flex-row items-center justify-between rounded-md px-5 text-white ${
        className || ''
      } ${disabled && !text ? 'cursor-not-allowed bg-greyish' : 'bg-black'}`}
      onClick={onClick}>
      {typeof leftIcon === 'string' ? <img src={leftIcon} /> : leftIcon}
      <p
        className={`text-center ${text ? 'text-black underline' : ''} ${
          labelStyle || ''
        } ${disabled ? 'cursor-not-allowed text-foggy' : ''} ${
          fullWidth ? 'flex w-full items-center justify-center' : ''
        }`}>
        {label}
      </p>
      {typeof rightIcon === 'string' ? <img src={rightIcon} /> : rightIcon}
    </div>
  );
};

export default Button;
