import notificationIcon from '../../../assets/icons/notification.svg';
import Breadcrumb from '../../../components/common/Breadcrumb';
import Fab from '../../../components/common/Fab';
import ImgWithText from '../../../components/common/ImgWithText';
import {useCourierContext} from '../../../layouts/DriversAndRidersViewLayout';
import {readableWalletStatus} from '../../../utils/reusablefunctions';

const CourierTopBar = () => {
  const {breadcrumbs, courier} = useCourierContext();
  return (
    <div className="sticky top-0 z-20 flex justify-between bg-white pb-5 pt-8">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="flex gap-9">
        <ImgWithText
          text={readableWalletStatus(courier?.wallet?.activation?.status)[0]}
          url={readableWalletStatus(courier?.wallet?.activation?.status)[1]}
          variant="circle"
        />
        <Fab hasAlert>
          <img src={notificationIcon} />
        </Fab>
      </div>
    </div>
  );
};

export default CourierTopBar;
