import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {Line} from 'react-chartjs-2';
import {toast} from 'react-hot-toast';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import CommonEdit, {CommonProps} from './CommonEdit';
import WalletStatusEdit from './WalletStatusEdit';
import CloseIcon from '../../../assets/icons/close-icon.svg';
import Button from '../../../components/common/Button';
import IconButton from '../../../components/common/IconButton';
import {
  DatePreset,
  TimeSeriesPeriod,
  TransactionActivity,
  TransactionType,
  UpdateUserInput,
  WalletActivationStatus,
  useTransactionActivityQuery,
  useUpdateUserMutation,
} from '../../../generated';
import {useCustomerContext} from '../../../layouts/CustomerViewLayout';
import formatDate from '../../../utils/reusablefunctions/formatDate';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  dataStrokeWidth: 3,
  pointDotStrokeWidth: 4,
  scales: {
    y: {
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
      grid: {
        display: true,
      },
    },
    x: {
      ticks: {
        display: true,
      },
      border: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
  },

  plugins: {
    legend: {
      display: true,
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Wallet Balance',
    },
  },
};

type View = 'common' | 'status' | 'wallet';
export type SectionProps = {
  title: string;
  body?: JSX.Element | string;
  value?: WalletActivationStatus | string;
  btnContent?: string | JSX.Element;
  id?: string;
  endAdornment?: React.ReactNode | number;
  view?: View;
  updateUserKey?: keyof UpdateUserInput;
  walletId?: string | null;
  showEditButton?: boolean;
};

const Section = ({
  title,
  body,
  btnContent = 'Edit',
  id,
  endAdornment,
  value,
  view,
  updateUserKey,
  walletId,
  showEditButton = true,
}: SectionProps) => {
  const {hash} = useLocation();
  const {id: customerId} = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(open ? 'auto' : '0px');
  const [opacity, setOpacity] = useState(open ? 1 : 0);
  const contentRef = useRef<HTMLDivElement>(null);
  const [updateUserMutation, {error: updateError}] = useUpdateUserMutation();
  const {customer} = useCustomerContext();

  useEffect(() => {
    setOpen(
      hash === '#wallet-details' && title === 'Wallet details' ? true : false
    );
  }, [hash, title]);

  useEffect(() => {
    if (!updateError) return;
    toast.error(updateError.message, {position: 'bottom-left'});
  }, [updateError]);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(open ? `${contentRef.current.scrollHeight}px` : '0px');
      setOpacity(open ? 1 : 0);
    }
  }, [open]);

  const cancel = () => {
    title === 'Transactions'
      ? navigate(`/customer/${customerId || ''}/transactions`)
      : setOpen(!open);
  };

  const commonType: CommonProps = {
    title,
    value,
    endAdornment,
    cancel,
    updateUserKey,
  };

  const {data: withdrawTransactions} = useTransactionActivityQuery({
    variables: {
      input: {
        walletId: walletId,
        type: [TransactionType.Transfer],
        datePreset: DatePreset.ThisYear,
        timeSeriesConfig: {
          period: TimeSeriesPeriod.Months,
          step: 1,
        },
      },
    },
  });

  const {data: billTransactions} = useTransactionActivityQuery({
    variables: {
      input: {
        walletId: walletId,
        type: [TransactionType.Bill],
        datePreset: DatePreset.ThisYear,
        timeSeriesConfig: {
          period: TimeSeriesPeriod.Months,
          step: 1,
        },
      },
    },
  });

  const {data: depositTransactions} = useTransactionActivityQuery({
    variables: {
      input: {
        walletId: walletId,
        type: [TransactionType.LoadCredit],
        datePreset: DatePreset.ThisYear,
        timeSeriesConfig: {
          period: TimeSeriesPeriod.Months,
          step: 1,
        },
      },
    },
  });

  const {data: reversalsTransactions} = useTransactionActivityQuery({
    variables: {
      input: {
        walletId: walletId,
        type: [TransactionType.Refund],
        datePreset: DatePreset.ThisYear,
        timeSeriesConfig: {
          period: TimeSeriesPeriod.Months,
          step: 1,
        },
      },
    },
  });

  const graphData = useMemo(() => {
    const labelNames: string[] = [];
    const billCount: number[] = [];
    const withdrawCount: number[] = [];
    const depositCount: number[] = [];
    const reversalsCount: number[] = [];

    if (billTransactions != undefined) {
      billTransactions.transactionActivity.map((t: TransactionActivity) => {
        labelNames.push(
          formatDate(t.timeSeriesInterval.end as unknown as Date, {
            withShortMonth: true,
            withTime: false,
            withDate: false,
          })
        );
        billCount.push(t.sum.amountInCents / 100);
      });
    }

    if (withdrawTransactions != undefined) {
      withdrawTransactions.transactionActivity.map((t) => {
        if (billTransactions === undefined) {
          labelNames.push(
            formatDate(t.timeSeriesInterval.end as unknown as Date, {
              withShortMonth: true,
              withTime: false,
              withDate: false,
            })
          );
        }

        withdrawCount.push(t.sum.amountInCents / 100);
      });
    }

    if (depositTransactions != undefined) {
      depositTransactions.transactionActivity.map((t) => {
        if (depositTransactions === undefined) {
          labelNames.push(
            formatDate(t.timeSeriesInterval.end as unknown as Date, {
              withShortMonth: true,
              withTime: false,
              withDate: false,
            })
          );
        }

        depositCount.push(t.sum.amountInCents);
      });
    }

    if (reversalsTransactions != undefined) {
      reversalsTransactions.transactionActivity.map((t) => {
        if (reversalsTransactions === undefined) {
          labelNames.push(
            formatDate(t.timeSeriesInterval.end as unknown as Date, {
              withShortMonth: true,
              withTime: false,
              withDate: false,
            })
          );
        }

        reversalsCount.push(t.sum.amountInCents);
      });
    }

    const data = {
      labels: labelNames,
      datasets: [
        {
          label: 'Withdrawals',
          data: withdrawCount,
          backgroundColor: '#EF4444',
          borderColor: '#EF4444',
          borderWidth: 1,
          tension: 0.2,
        },
        {
          label: 'Deposits',
          data: depositCount,
          backgroundColor: '#21AA47',
          borderColor: '#21AA47',
          borderWidth: 1,
          tension: 0.2,
        },
        {
          label: 'Bills',
          data: billCount,
          backgroundColor: '#0C0D34',
          borderColor: '#0C0D34',
          fill: true,
          tension: 0.4,
        },
        {
          label: 'Reversals',
          data: reversalsCount,
          backgroundColor: '#A65D03',
          borderColor: '#A65D03',
          borderWidth: 1,
          tension: 0.2,
        },
      ],
    };
    return data;
  }, [
    billTransactions,
    depositTransactions,
    reversalsTransactions,
    withdrawTransactions,
  ]);

  const update = useCallback(
    async (
      updateUserKeyString: keyof UpdateUserInput,
      input: UpdateUserInput,
      callBackFunction?: () => void
    ) => {
      const res = await updateUserMutation({
        variables: {
          id: customer?.user?.id,
          input,
        },
      });
      if (res.errors?.length) {
        callBackFunction?.();
        toast.error(`An error occured when updating ${updateUserKeyString}`, {
          position: 'bottom-left',
        });
      }
      if (res.data?.updateUser) callBackFunction?.();
    },
    [customer?.user?.id, updateUserMutation]
  );

  return (
    <div id={id}>
      {height === '0px' && (
        <div className="flex w-full justify-between py-5">
          <div className="flex flex-col gap-3">
            <p className="text-lg font-semibold leading-6">{title}</p>
            {body ? (
              body
            ) : (
              <p className="text-base font-medium leading-5 text-darkGrey">
                {value || '-'}
              </p>
            )}
          </div>

          {showEditButton && // Conditionally render the Edit button
            (typeof btnContent === 'string' ? (
              <Button
                label={btnContent}
                text
                onClick={() => cancel()}
                labelStyle="text-mediumPurple font-semibold text-base leading-5"
              />
            ) : (
              <IconButton icon={btnContent || ''} className="px-5" />
            ))}
        </div>
      )}
      <div
        ref={contentRef}
        style={{
          maxHeight: `${height}`,
          opacity: `${opacity}`,
          transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
        }}
        className="overflow-hidden">
        {view === 'common' && <CommonEdit update={update} {...commonType} />}
        {view === 'status' && (
          <WalletStatusEdit title={title} value={value} cancel={cancel} />
        )}
        {view === 'wallet' && (
          <div className="relative mt-4 flex flex-col gap-3 rounded-lg border border-cashia-grey pb-0 pr-3 pt-6">
            <img
              src={CloseIcon}
              className="absolute right-6 top-6 cursor-pointer"
              onClick={cancel}
            />
            <div className="flex w-[90%] flex-col gap-3 px-4">
              <p className="text-lg font-semibold leading-6">{title}</p>
              <div className="w-[100%] pt-2">
                <p className="text-base text-darkGrey">Wallet balance</p>
                <p className="text-2xl font-semibold text-green-500">
                  KES {value}
                </p>

                <Line
                  options={options}
                  data={graphData}
                  height={500}
                  width={950}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Section;
