import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import DropDown from './DropDown';
import IconButton from './IconButton';
import ImgWithText from './ImgWithText';
import ExportIcon from '../../assets/icons/export.svg';
import settingIcon from '../../assets/icons/settingIcon.svg';
import upDownarrows from '../../assets/icons/upDownarrows.svg';
import {createHashtag} from '../../utils/reusablefunctions';
import {
  CommonProperties,
  TableColumnType,
  TableDataType,
} from '../../utils/types/table.types';

type Props = {
  columns: TableColumnType[];
  parentColumns?: TableColumnType[];
  data: TableDataType[];
  selectedIds?: string[];
  sortingColumns?: string[];
  viewRecordIconOrText?: string;
  openModalIcon?: string;
  setSelectedIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setColumns?: React.Dispatch<React.SetStateAction<TableColumnType[]>>;
  setData?: React.Dispatch<React.SetStateAction<TableDataType[]>>;
  prettify?: boolean;
  openModal?: (row: TableDataType) => void;
  viewRecord?: (id: string) => void;
  moreFilterElement?: JSX.Element;
  textstyle?: string;
  link?: {
    href?: string;
    name?: string;
    id?: string;
  };
};

const CommonTable = ({
  columns,
  data,
  parentColumns,
  selectedIds,
  sortingColumns,
  viewRecordIconOrText,
  openModalIcon,
  link,
  setColumns,
  setSelectedIds,
  prettify,
  setData,
  viewRecord,
  openModal,
  moreFilterElement,
  textstyle,
}: Props) => {
  const navigate = useNavigate();
  const requireSelections = useMemo(
    () => selectedIds && setSelectedIds,
    [selectedIds, setSelectedIds]
  );

  const addOrRemove = (id: string) => {
    if (!requireSelections) return;
    const found = selectedIds?.includes(id);
    if (found) {
      setSelectedIds?.((prev) => prev?.filter((item) => item !== id));
    } else {
      setSelectedIds?.((prev) => [...prev, id]);
    }
  };
  const addAllOrRemoveAll = () => {
    if (!requireSelections) return;

    if (selectedIds?.length === data.length) {
      setSelectedIds?.([]);
    } else {
      setSelectedIds?.(data.map((item) => item.id ?? ''));
    }
  };

  const addOrRemoveColumn = (column: TableColumnType) => {
    if (!setColumns) return;
    const index = parentColumns?.indexOf(column);
    setColumns((prev) =>
      prev.includes(column)
        ? prev.filter((item) => item !== column)
        : [...prev.slice(0, index), column, ...prev.slice(index)]
    );
  };

  const sortColumn = (column: TableColumnType) => {
    if (typeof column === 'boolean') return;
    const sortedData = [...data].sort((a, b) =>
      JSON.stringify(a[column]?.[0] ?? '').localeCompare(
        JSON.stringify(b[column]?.[0] ?? '')
      )
    );
    setData?.(
      data[0][column] === sortedData[0][column]
        ? sortedData.reverse()
        : sortedData
    );
  };

  const formatColumnName = (name: TableColumnType) => {
    if (name.includes('_')) return name.split('_').join(' ');
    if (name === 'identificationNumber') return 'National ID';
    return name;
  };

  const shortenTxt = (txt: string) => {
    if (txt?.length > 20) return `${txt.substring(0, 20)}...`;
    return txt;
  };

  return data.length ? (
    <table className="w-full table-auto text-left text-base text-cashia-grey">
      <thead className="mb-3 font-semibold uppercase leading-5">
        <tr
          className={`${
            prettify ? 'rounded-t-2xl border-none bg-offWhite' : 'border-b'
          } border-cashia-grey`}>
          {requireSelections && (
            <th className="mr-1 flex h-[45px] justify-center">
              <input
                checked={selectedIds?.length === data.length}
                type="checkbox"
                onChange={() => addAllOrRemoveAll?.()}
                value=""
                className="h-4 w-4 cursor-pointer self-center rounded border-2 border-foggy accent-foggy checked:rounded focus:border-none"
              />
            </th>
          )}
          {columns.map((column, key) => {
            return (
              <th
                className={`${prettify && key === 0 ? 'pl-5' : ''} ${
                  prettify ? 'py-5' : ''
                } py-3`}
                key={key}>
                <p className="flex gap-1 font-semibold">
                  {formatColumnName(column)}
                  {setData && sortingColumns?.includes(column) && (
                    <img
                      src={upDownarrows}
                      className="cursor-pointer"
                      onClick={() => sortColumn?.(column)}
                    />
                  )}
                </p>
              </th>
            );
          })}
          <th className="float-right flex gap-6">
            {openModalIcon && <div className="h-3 w-3" />}
            <div className="flex gap-2">
              {moreFilterElement && moreFilterElement}
              {parentColumns && (
                <DropDown
                  dropDownPosition="right"
                  className="top-7"
                  actionElement={<IconButton icon={settingIcon} />}>
                  <div className="w-[240px] py-4 text-sm text-gray-700">
                    <h3 className="mb-[15px] px-6 py-2 text-sm font-semibold leading-3 text-foggy">
                      CUSTOMIZE COLUMNS
                    </h3>
                    {parentColumns?.map((item, i) => (
                      <label
                        key={i}
                        className="flex cursor-pointer gap-2 px-6 py-2 hover:bg-gray-100"
                        htmlFor={`input${i}`}>
                        <input
                          checked={columns.includes(item)}
                          type="checkbox"
                          id={`input${i}`}
                          disabled={
                            columns.length < 2 && columns.includes(item)
                          }
                          onChange={(e) =>
                            addOrRemoveColumn?.(
                              e.target.value as TableColumnType
                            )
                          }
                          value={item}
                          className="h-[16px] w-[16px] cursor-pointer self-center rounded-lg border-2 border-foggy accent-foggy checked:rounded-lg focus:border-none"
                        />
                        <p className="text-base font-medium capitalize leading-5">
                          {formatColumnName(item)}
                        </p>
                      </label>
                    ))}
                  </div>
                </DropDown>
              )}
            </div>
          </th>
          <th />
        </tr>
      </thead>
      <tbody className="font-medium">
        {data.map((row: TableDataType, rowKey) => {
          return (
            <>
              <tr
                key={rowKey}
                className={`${
                  prettify ? 'border-x ' : ''
                } border-b border-cashia-grey py-5 leading-5`}>
                {requireSelections && (
                  <th className="mr-1 flex h-[60px] justify-center self-center">
                    <input
                      checked={Boolean(
                        row?.id && selectedIds?.includes(row.id)
                      )}
                      type="checkbox"
                      onChange={() => row?.id && addOrRemove?.(row.id)}
                      value=""
                      className="h-4 w-4 cursor-pointer self-center rounded border-2 border-foggy accent-foggy checked:rounded focus:border-none"
                    />
                  </th>
                )}
                {columns.map((col, colKey) => {
                  return (
                    <td
                      key={colKey}
                      className={`${
                        prettify && colKey === 0 && !requireSelections
                          ? 'pl-5'
                          : ''
                      } py-3`}>
                      {col === 'matchscore' ? (
                        // <div
                        //   className="radial-progress font-bold text-red-500"
                        //   role="progressbar">
                        //   {row[col]}
                        // </div>
                        <div className="relative h-11 w-11">
                          <svg className="h-full w-full" viewBox="0 0 100 100">
                            <circle
                              className="stroke-current text-gray-200"
                              stroke-width="10"
                              cx="50"
                              cy="50"
                              r="40"
                              fill="transparent"
                            />

                            <circle
                              className="progress-ring__circle  stroke-current text-[#F7D4D4]"
                              stroke-width="10"
                              stroke-linecap="round"
                              cx="50"
                              cy="50"
                              r="40"
                              fill="transparent"
                              stroke-dashoffset="calc(400 - (400 * 45) / 100)"
                            />

                            <text
                              x="50"
                              fontSize={28}
                              y="50"
                              text-anchor="middle"
                              alignment-baseline="middle">
                              {row[col]}
                            </text>
                          </svg>
                        </div>
                      ) : Array.isArray(row[col]) ? (
                        <ImgWithText
                          variant={col !== 'status' ? 'square' : 'circle'}
                          text={shortenTxt(row[col]?.[0] || '')}
                          subText={shortenTxt(row[col]?.[2] || '')}
                          url={row[col]?.[1]}
                        />
                      ) : col === 'subcategories' ? (
                        <p
                          className="cursor-pointer text-[13px] font-medium text-rose-600"
                          onClick={() =>
                            navigate(
                              `${link?.href || ''}${createHashtag(
                                row.name?.[0] || ''
                              )}/${row.id || ''}`,
                              {
                                state: {
                                  name: link?.name || 'sub-category',
                                  id: link?.id || '',
                                },
                              }
                            )
                          }>
                          {row[col]}
                        </p>
                      ) : (
                        <div className={`${textstyle || ''}`}>
                          {shortenTxt(row[col] as string)}
                        </div>
                      )}
                    </td>
                  );
                })}
                <td
                  className={`flex gap-4  ${
                    prettify ? 'pt-5' : 'float-right pt-5'
                  }`}>
                  {openModalIcon && (
                    <img
                      className="inline-block cursor-pointer"
                      src={openModalIcon}
                      onClick={() => openModal?.(row)}
                    />
                  )}

                  {viewRecordIconOrText &&
                    (typeof viewRecordIconOrText === 'string' &&
                    viewRecordIconOrText.includes('.svg') ? (
                      <img
                        className={`cursor-pointer ${
                          moreFilterElement ? 'ml-3' : ''
                        }`}
                        src={viewRecordIconOrText || ExportIcon}
                        onClick={() => row?.id && viewRecord?.(row?.id)}
                      />
                    ) : (
                      <button
                        className={`cursor-pointer text-mediumPurple underline ${
                          moreFilterElement ? 'ml-3' : ''
                        }`}
                        onClick={() => row?.id && viewRecord?.(row?.id)}>
                        {viewRecordIconOrText}
                      </button>
                    ))}
                </td>
              </tr>
              {row.children?.map((item: CommonProperties, itemKey) => (
                <tr
                  key={itemKey}
                  className={`${
                    prettify ? 'border-x ' : ''
                  } border-b border-cashia-grey py-5 leading-5`}>
                  {requireSelections && (
                    <th className="float-right flex h-[60px]">
                      <input
                        checked={Boolean(
                          item?.id && selectedIds?.includes(item.id)
                        )}
                        type="checkbox"
                        onChange={() => item?.id && addOrRemove?.(item.id)}
                        value=""
                        className="h-4 w-4 cursor-pointer self-center rounded border-2 border-foggy accent-foggy checked:rounded focus:border-none"
                      />
                    </th>
                  )}
                  {columns.map((colItem, colKeyItem) => {
                    return (
                      <td key={colKeyItem} className="py-2">
                        {colKeyItem === 0 ? (
                          <p className="ml-5 text-base font-medium text-neutral-800">
                            {`– ${shortenTxt(item[colItem] as string)}`}
                          </p>
                        ) : (
                          <p className="text-base font-medium leading-tight text-neutral-800">
                            {shortenTxt(item[colItem] as string)}
                          </p>
                        )}
                      </td>
                    );
                  })}
                  <td className="float-right flex gap-4 py-4">
                    {openModalIcon && (
                      <img
                        className="inline-block cursor-pointer"
                        src={openModalIcon}
                        onClick={() => openModal?.(item)}
                      />
                    )}

                    {viewRecordIconOrText &&
                      (typeof viewRecordIconOrText === 'string' &&
                      viewRecordIconOrText.includes('.svg') ? (
                        <img
                          className={`cursor-pointer ${
                            moreFilterElement ? 'ml-3' : ''
                          }`}
                          src={viewRecordIconOrText || ExportIcon}
                          onClick={() => item?.id && viewRecord?.(item?.id)}
                        />
                      ) : (
                        <button
                          className={`cursor-pointer text-mediumPurple underline ${
                            moreFilterElement ? 'ml-3' : ''
                          }`}
                          onClick={() => item?.id && viewRecord?.(item?.id)}>
                          {viewRecordIconOrText}
                        </button>
                      ))}
                  </td>
                </tr>
              ))}
            </>
          );
        })}
      </tbody>
    </table>
  ) : (
    <></>
  );
};

export default CommonTable;
