import {ReactNode, useCallback, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';

import Close from '../../../assets/icons/cross_black.svg';
import Button from '../../../components/common/Button';
import IconButton from '../../../components/common/IconButton';
import ImageModal from '../../../components/common/ImageModal';
import TextArea from '../../../components/common/TextArea';
import TextInput from '../../../components/common/TextInput';
import {MerchantUpdateInput} from '../../../generated';
import {useMerchant} from '../../../layouts/MerchantDetailsLayout';
import AccountStatus from '../components/AccountStatus';

type RowProps = {
  label: string;
  value: ReactNode;
  update: (input: MerchantUpdateInput) => Promise<void>;
  updateKey: keyof MerchantUpdateInput;
  multiline?: boolean;
  limit?: number;
  image?: boolean;
  description?: string;
};

const MerchantDetails = () => {
  const {merchant, update, error} = useMerchant();

  useEffect(() => {
    if (!error) return;
    toast.error(error.message, {position: 'bottom-left'});
  });

  return (
    <div className="mr-[30px] flex basis-full flex-col overflow-scroll">
      <h1 className="pb-6 text-[22px] font-semibold">About {merchant?.name}</h1>
      <AccountStatus value={merchant?.approvalState} />
      <Row
        updateKey="name"
        label="Store name"
        value={
          <span className="font-normal text-darkGrey">
            {merchant?.name || ''}
          </span>
        }
        update={update}
        limit={40}
      />
      <Row
        updateKey="description"
        label="Store description"
        value={
          <span className="font-normal text-darkGrey">
            {merchant?.description || ''}
          </span>
        }
        update={update}
        multiline
        limit={150}
      />
      <Row
        updateKey="logo"
        label="Upload a profile photo (optional)"
        value={merchant?.logo?.medium || ''}
        update={update}
        image
        description="A profile picture enables customers to identify your business easily. It creates trust between you and your clients."
      />
      <Row
        updateKey="coverImage"
        label="Upload a cover photo (optional)"
        value={merchant?.coverImage?.medium || ''}
        update={update}
        image
        description="A cover picture enables customers to identify your business easily. It creates trust between you and your clients."
      />
    </div>
  );
};

const Row = ({
  label,
  description,
  value,
  update,
  updateKey,
  limit,
  image = false,
  multiline = false,
}: RowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (!value) return;
    setText(value as string);
  }, [value]);

  useEffect(() => {
    if (limit && text.length >= limit) setText(text.substring(0, limit));
  }, [limit, text]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setText(value as string);
  }, [value]);

  const handleSave = useCallback(async () => {
    if (!text) return;
    await update({[updateKey]: text});
    setIsEditing(false);
  }, [text, update, updateKey]);

  const handleSaveImage = useCallback(
    async (id: string) => {
      if (!id) return;
      await update({[updateKey]: id});
      setIsEditing(false);
    },
    [update, updateKey]
  );

  const imageModalToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <div className="flex justify-between border-b border-dividerGrey py-[20px]">
      {isEditing ? (
        <div className="flex w-full flex-col rounded-lg border border-dividerGrey ">
          <div className="flex flex-row items-center justify-between p-[20px]">
            <h6 className="mb-[10px] text-[18px] font-semibold">{label}</h6>
            <IconButton onClick={handleCancel} icon={Close} />
          </div>
          {multiline ? (
            <TextArea
              containerStyle="ml-[20px] mr-[80px]"
              value={text}
              onChange={(e) => setText(e.target.value)}
              helperText={
                limit && (
                  <span className="pt-[10px] text-[13px] font-medium text-darkGrey">{`${text.length}/${limit}`}</span>
                )
              }
            />
          ) : (
            <TextInput
              containerStyle="ml-[20px] mr-[80px]"
              value={text}
              onChange={(e) => setText(e.target.value)}
              endAdornment={
                limit && (
                  <span className="text-cashiaBlue">{limit - text.length}</span>
                )
              }
            />
          )}
          <div className="mt-[30px] flex basis-full justify-between border-t border-dividerGrey p-[20px] pt-[15px]">
            <Button onClick={handleCancel} text label="Cancel" />
            <Button label="Save" onClick={handleSave} />
          </div>
        </div>
      ) : image ? (
        <>
          <div>
            <h6 className="mb-[10px] text-[18px] font-semibold">{label}</h6>
            <ImageModal
              title={label}
              description={description}
              onToggle={imageModalToggle}
              getUploadedImgId={handleSaveImage}
              imgUrl={value as string}
              isOpen={isOpen}
              thumbnailStyle={`h-[200px] w-[200px] ${
                updateKey === 'logo' ? 'rounded-full overflow-hidden' : ''
              }`}
            />
          </div>
          <Button
            onClick={() => setIsOpen(true)}
            labelStyle="text-mediumPurple"
            label="Edit"
            text
          />
        </>
      ) : (
        <>
          <div>
            <h6 className="mb-[10px] text-[18px] font-semibold">{label}</h6>
            <p>{value}</p>
          </div>
          <Button
            onClick={() => setIsEditing(true)}
            labelStyle="text-mediumPurple"
            label="Edit"
            text
          />
        </>
      )}
    </div>
  );
};

export default MerchantDetails;
