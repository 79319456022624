type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerStyle?: string;
  description?: string;
  descriptionStyle?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: boolean;
  id?: string;
  inputStyle?: string;
  label?: string;
  labelStyle?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  type?: string;
  value?: string;
  rows?: number;
  maxRows?: number;
  minRows?: number;
  startAdornment?: React.ReactNode;
  helperText?: React.ReactNode;
};

const TextArea = ({
  containerStyle,
  description,
  descriptionStyle,
  endAdornment,
  helperText,
  id,
  inputStyle,
  label,
  labelStyle,
  placeholder,
  startAdornment,
  rows = 4,
  ...other
}: Props) => {
  return (
    <div className={`flex flex-col ${containerStyle || ''}`}>
      <h1 className={`text-xl font-semibold leading-5 ${labelStyle || ''}`}>
        {label}
      </h1>
      {description && (
        <p
          className={`pt-2.5 leading-5 text-darkGrey ${
            descriptionStyle || ''
          }`}>
          {description}
        </p>
      )}
      <div
        className={`peer flex basis-full flex-row items-center rounded-lg border-half border-greyish bg-offWhite px-2.5 py-2 focus-within:!bg-white focus-within:ring-2 focus-within:ring-black ${
          description ? 'mt-2.5' : ''
        }`}>
        {startAdornment}
        <textarea
          className={`peer flex basis-full appearance-none  bg-offWhite px-2.5 py-2 text-sm font-medium text-black placeholder-foggy autofill:!bg-offWhite focus:!bg-white focus:outline-none ${
            inputStyle || ''
          }`}
          placeholder={placeholder}
          rows={rows}
          {...other}
        />
        {endAdornment}
      </div>
      {helperText}
    </div>
  );
};

export default TextArea;
