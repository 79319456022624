import greenPhoneIcon from '../../assets/icons/green-phone-icon.svg';
import mpesaIcon from '../../assets/icons/mpesa-icon.svg';
import noPhoto from '../../assets/icons/noPhoto.svg';
import redPhoneIcon from '../../assets/icons/red-phone-icon.png';
import Dstv from '../../assets/images/dstv_logo.jpeg';
import Gotv from '../../assets/images/gotv_logo.jpeg';
import NairobiWater from '../../assets/images/nairobi_water_logo.png';
import Zuku from '../../assets/images/zuku_logo.png';
import {
  Biller,
  TransactionStatus,
  TransactionsFieldsFragment,
  UserFieldsFragment,
} from '../../generated';

interface Options {
  name?: string | null;
  type?: string;
  logo?: string;
  direction?: string;
  last4Digits?: string;
  des?: string;
}

export enum Categories {
  MoneySent = 'Money sent',
  MoneyRecieved = 'Money received',
  Payments = 'Payments',
  Bills = 'Bills',
  WalletTopup = 'Wallet topup',
  Withdrawals = 'Withdrawals',
  Reversals = 'Reversals',
}

const billerLogo = (biller: Biller) => {
  return {
    DSTV: Dstv,
    GOTV: Gotv,
    NAIROBI_WATER: NairobiWater,
    ZUKUTV: Zuku,
    ZUKU_INTERNET: Zuku,
  }[biller];
};

const transactionOptions: (
  transaction: TransactionsFieldsFragment,
  user?: UserFieldsFragment | null
) => Options = (transaction, user) => {
  const unKnownTransaction = {
    name: 'Unknown transaction',
    type: 'unknown',
    logo: noPhoto,
    direction: 'To',
    des: 'unknown',
  };

  const formatStatus = (status: TransactionStatus) => {
    return {
      FAILED: 'failed',
      PENDING: 'pending',
      SUCCESS: 'successful',
    }[status];
  };

  switch (transaction.__typename) {
    case 'LoadCreditTransaction':
      return transaction.paymentMethod.__typename === 'CreditCardPaymentMethod'
        ? {
            last4Digits: transaction.paymentMethod.last4Digits,
            name:
              transaction.paymentMethod.type +
              transaction.paymentMethod.last4Digits,
            type: Categories.WalletTopup,
            direction: 'From',
            des: `Topup ${formatStatus(transaction.status)}`,
          }
        : transaction.paymentMethod.__typename === 'PhoneNumberPaymentMethod'
        ? {
            name: 'M-Pesa',
            logo: mpesaIcon,
            type: Categories.WalletTopup,
            direction: 'From',
            des: `Topup ${formatStatus(transaction.status)}`,
          }
        : transaction.paymentMethod.__typename ===
          'InterswitchBankAccountPaymentMethod'
        ? {
            name: transaction.paymentMethod.interswitchBank?.name,
            logo: transaction.paymentMethod.interswitchBank?.logo?.small
              ? transaction.paymentMethod.interswitchBank.logo.small
              : noPhoto,
            type: Categories.WalletTopup,
            direction: 'From',
            des: `Topup ${formatStatus(transaction.status)}`,
          }
        : unKnownTransaction;
    case 'WithdrawTransaction':
      return transaction.paymentMethod.__typename === 'CreditCardPaymentMethod'
        ? {
            last4Digits: transaction.paymentMethod.last4Digits,
            name:
              transaction.paymentMethod.type +
              transaction.paymentMethod.last4Digits,
            type: Categories.Withdrawals,
            direction: 'To',
            des: `Withdrawal ${formatStatus(transaction.status)}`,
          }
        : transaction.paymentMethod.__typename === 'PhoneNumberPaymentMethod'
        ? {
            name: 'M-Pesa',
            logo: mpesaIcon,
            type: Categories.Withdrawals,
            direction: 'To',
            des: `Withdrawal ${formatStatus(transaction.status)}`,
          }
        : transaction.paymentMethod.__typename ===
          'InterswitchBankAccountPaymentMethod'
        ? {
            name: transaction.paymentMethod.interswitchBank?.name,
            logo: transaction.paymentMethod.interswitchBank?.logo?.small
              ? transaction.paymentMethod.interswitchBank?.logo?.small
              : noPhoto,
            type: Categories.Withdrawals,
            direction: 'To',
            des: `Withdrawal ${formatStatus(transaction.status)}`,
          }
        : unKnownTransaction;
    case 'TransferTransaction': {
      const transactionInitiator =
        transaction.transferReceiver?.user?.id === user?.id
          ? {
              displayUser: transaction.transferIssuer,
              type: Categories.MoneyRecieved,
              direction: 'From',
            }
          : {
              displayUser: transaction.transferReceiver,
              type: Categories.MoneySent,
              direction: 'To',
            };

      return {
        name: transactionInitiator.displayUser.user?.name,
        logo:
          transaction.status === TransactionStatus.Failed
            ? redPhoneIcon
            : greenPhoneIcon,
        type: transactionInitiator.type,
        direction: transactionInitiator.direction,
        des: `Transfer ${formatStatus(transaction.status)}`,
      };
    }
    case 'OrderBatchTransaction': {
      const orderBatchInfo =
        transaction.orderBatch.buyer.user?.id === user?.id
          ? {
              name: transaction.orderBatch.merchant.name,
              logo: transaction.orderBatch.merchant.logo?.small || noPhoto,
              direction: 'To',
            }
          : {
              name: transaction.orderBatch.buyer.user?.name,
              logo: transaction.orderBatch.buyer.avatar?.small || noPhoto,
              direction: 'From',
            };
      return {
        name: orderBatchInfo.name,
        logo: orderBatchInfo.logo,
        direction: orderBatchInfo.direction,
        type: Categories.Payments,
        des: `Transfer ${formatStatus(transaction.status)}`,
      };
    }
    case 'BillTransaction':
      return {
        name: transaction.biller.split('_').join(' ').toLowerCase(),
        logo: billerLogo(transaction.biller),
        type: Categories.Bills,
        direction: 'to',
        des: `Transfer ${formatStatus(transaction.status)}`,
      };
    case 'RefundTransaction':
      return {
        name: transaction.orderBatch.merchant.name,
        logo: transaction.orderBatch.merchant.logo?.small
          ? transaction.orderBatch.merchant.logo?.small
          : noPhoto,
        type: Categories.Reversals,
        direction: 'From',
        des: `Reversal ${formatStatus(transaction.status)}`,
      };
    default:
      return unKnownTransaction;
  }
};

export default transactionOptions;
