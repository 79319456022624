import React, {useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as GridCatergory} from '../../../assets/icons/category.svg';
import downArrow from '../../../assets/icons/downArrow.svg';
import {ReactComponent as Likes} from '../../../assets/icons/heart.svg';
import {ReactComponent as RowCatergory} from '../../../assets/icons/row-vertical.svg';
import searchIcon from '../../../assets/icons/search_icon.svg';
import {ReactComponent as Views} from '../../../assets/icons/views.svg';
import Button from '../../../components/common/Button';
import DropDown from '../../../components/common/DropDown';
import Paginator from '../../../components/common/Paginator';
import Spinner from '../../../components/common/Spinner';
import Tabs from '../../../components/common/Tabs';
import TextInput from '../../../components/common/TextInput';
import ToggleListView from '../../../components/common/ToggleListView';
import CommonTable from '../../../components/common/table';
import {useMerchant} from '../../../layouts/MerchantDetailsLayout';
import useGetReels from '../hooks/useGetReels';

const Reels = () => {
  const navigate = useNavigate();
  const {merchant} = useMerchant();
  const {
    columns,
    data,
    setData,
    parentColumns,
    selectedIds,
    setSelectedIds,
    setColumns,
    sortingColumns,
    allReels,
    loadMore,
    count,
    loading,
    error,
  } = useGetReels(merchant?.id || '');

  const location = useLocation();

  const reels = useMemo(() => allReels?.reels.edges, [allReels]);
  const commonPath = `/merchants/merchant/view/reels/${merchant?.id || ''}`;

  const tabs = useMemo(
    () => [
      {name: 'All', link: commonPath},
      {name: 'Unrestricted', link: `${commonPath}/unrestricted`},
      {name: 'Restricted', link: `${commonPath}/restricted`},
    ],
    [commonPath]
  );

  const [viewType, setViewType] = useState('grid');
  const [activeButton, setActiveButton] = React.useState('icon1');

  const isActive = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname]
  );

  const filteredReels = useMemo(() => {
    const currentTab = tabs.find((tab) => isActive(tab.link));

    switch (currentTab?.name) {
      case 'Unrestricted':
        return reels?.filter((reel) => reel.node.restricted === false);
      case 'Restricted':
        return reels?.filter((reel) => reel.node.restricted === true);
      default:
        return reels;
    }
  }, [reels, isActive, tabs]);

  const handleGridClick = () => {
    setViewType('grid');
    setActiveButton('icon1');
  };

  const handleListClick = () => {
    setViewType('list');
    setActiveButton('icon2');
  };
  const viewRecord = (id: string) =>
    navigate(
      `/merchants/merchant/view/reels/analytic/${merchant?.id || ''}/${
        id || ''
      }`
    );

  if (loading) {
    return (
      <div className="mt-2 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-3 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <div className="no-scrollbar mr-[30px] flex h-full w-full flex-col overflow-scroll">
      <div className="flex flex-col py-4">
        <h4 className="mb-0 text-2xl font-semibold leading-6">Reels</h4>

        <div className="mt-0 flex flex-auto flex-col justify-center">
          <Tabs tabs={tabs} tabContainerStyles=" mb-4 flex-1" />

          {filteredReels?.length !== undefined && filteredReels?.length > 0 && (
            <div className="flex flex-row ">
              <div className="flex-1">
                <TextInput
                  endAdornment={
                    <img src={searchIcon} className="mr-1 h-5 w-5" />
                  }
                  placeholder="Find a reel..."
                  inputStyle="h-6"
                />
              </div>

              <div className="ml-5 flex justify-end gap-3">
                <DropDown
                  dropDownPosition="left"
                  className=" top-12"
                  actionElement={
                    <Button
                      label="Newest to Oldest"
                      rightIcon={<img src={downArrow} className="ml-2" />}
                      className="w-inherit rounded-md border border-black bg-transparent disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                      labelStyle="text-black text-sm "
                    />
                  }
                />
                <ToggleListView
                  icon1={<GridCatergory height={18} />}
                  icon2={<RowCatergory height={18} />}
                  onClick1={handleGridClick}
                  onClick2={handleListClick}
                  activeIcon={activeButton}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className="mt-2 flex h-full w-full flex-col justify-center">
          <Spinner width={45} height={45} />
        </div>
      ) : filteredReels?.length ? (
        viewType === 'grid' ? (
          <div className="flex w-full basis-full flex-col pr-8 ">
            <div className="mt-3 flex-1 overflow-y-auto pl-[10px]">
              <ul className="xs:grid-cols-4 grid justify-between gap-10 md:grid-cols-3 lg:grid-cols-4">
                {filteredReels?.map((reel) => (
                  <li
                    key={reel.node.id}
                    className="mb-4 w-[180px] cursor-pointer  space-y-3">
                    <div
                      onClick={() =>
                        navigate(
                          `/merchants/merchant/view/reels/analytic/${
                            merchant?.id || ''
                          }/${reel.node.id || ''}`
                        )
                      }>
                      <div className="relative flex flex-col justify-center">
                        <img
                          src={reel.node.thumbnail.large || ''}
                          className="h-[333px] w-[213px] rounded-xl bg-cover object-cover"
                        />
                        <span className="absolute bottom-0 left-0 mr-2 mt-2 flex items-center rounded-md bg-opacity-50 bg-none px-2 py-1 text-white">
                          <Views />
                          <span className="ml-1">{reel.node.views}</span>
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-base font-medium text-black">
                        {reel.node.name}
                      </p>
                      <div className="flex justify-between">
                        <p className="flex items-center justify-center gap-1 text-[14px] text-foggy">
                          {reel.node.products.length} Products
                        </p>
                        <p className="flex items-center justify-center gap-1 text-[14px] text-foggy">
                          {reel.node.likes}
                          <Likes width={14} height={14} fill="fill-foggy" />
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Paginator
              loadMore={loadMore}
              count={count}
              pageInfo={allReels?.reels?.pageInfo}
            />
          </div>
        ) : (
          <div className="mr-[30px] flex w-full basis-full flex-col overflow-scroll pr-8">
            <div className="flex h-full flex-col gap-6 overflow-y-scroll">
              <div className="flex h-full w-full flex-1 flex-col justify-between gap-6">
                <>
                  <CommonTable
                    columns={columns}
                    data={data}
                    setData={setData}
                    parentColumns={parentColumns}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    setColumns={setColumns}
                    sortingColumns={sortingColumns}
                    viewRecord={viewRecord}
                  />
                  <Paginator
                    loadMore={loadMore}
                    count={count}
                    pageInfo={allReels?.reels?.pageInfo}
                  />
                </>
              </div>
            </div>
          </div>
        )
      ) : (
        <h1 className="mr-[30px] max-h-20 w-full rounded-lg bg-slate-200 p-6 font-normal text-black">
          There are no reels to display
        </h1>
      )}
    </div>
  );
};
export default Reels;
