import {useState, useEffect, useCallback} from 'react';

import CloseIcon from '../../../assets/icons/close-icon.svg';
import Button from '../../../components/common/Button';
import TextInput from '../../../components/common/TextInput';
import {UpdateUserInput, WalletActivationStatus} from '../../../generated';

export type CommonProps = {
  endAdornment?: React.ReactNode | number;
  updateUserKey?: keyof UpdateUserInput;
  title: string;
  value?: string | WalletActivationStatus;
  cancel: () => void;
  update?: (
    updateKey: keyof UpdateUserInput,
    input: UpdateUserInput,
    callBackFunction?: () => void
  ) => Promise<void>;
};

const CommonEdit = ({
  title,
  value,
  cancel,
  updateUserKey,
  endAdornment = 25,
  update,
}: CommonProps) => {
  const [text, setText] = useState('');

  const close = useCallback(() => {
    cancel();
    setText(value || '');
  }, [cancel, value]);

  const updateUserInfo = useCallback(async () => {
    if (!updateUserKey) return;
    if (value === text || !updateUserKey) return;
    const input: UpdateUserInput = {};
    switch (updateUserKey) {
      case 'phone':
        input.phone = {
          countryCode: '254',
          number: text,
        };
        break;
      case 'name':
        input.name = text;
        break;
      case 'email':
        input.email = text;
        break;
      case 'identificationNumber':
        input.identificationNumber = text;
        break;
      default:
        break;
    }
    await update?.(updateUserKey, input, close);
  }, [close, text, update, updateUserKey, value]);

  useEffect(() => {
    if (!value) return;
    setText(value);
  }, [value]);

  useEffect(() => {
    if (!(typeof endAdornment === 'number')) return;
    if (text.length >= endAdornment) setText(text.substring(0, endAdornment));
  }, [endAdornment, text]);

  return (
    <div className="relative mt-4 flex flex-col gap-3 rounded-lg border border-cashia-grey py-6 pr-3">
      <img
        src={CloseIcon}
        className="absolute right-6 top-6 cursor-pointer"
        onClick={close}
      />
      <div className="flex w-[90%] flex-col gap-3 px-4">
        <p className="text-lg font-semibold leading-6">{title}</p>
        <TextInput
          value={text}
          autoFocus
          endAdornment={
            typeof endAdornment === 'number'
              ? endAdornment - (text?.length || 0)
              : endAdornment
          }
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <hr className="mt-3" />
      <div className="flex justify-between px-4">
        <Button
          label="Cancel"
          text
          labelStyle="font-semibold text-base leading-5"
          onClick={close}
        />
        <Button
          label="Save"
          labelStyle="font-semibold text-base leading-5"
          disabled={value === text}
          onClick={updateUserInfo}
        />
      </div>
    </div>
  );
};

export default CommonEdit;
