import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import archiveIcon from '../../../assets/icons/archive-product.svg';
import ExportIcon from '../../../assets/icons/export.svg';
import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal';
import Paginator from '../../../components/common/Paginator';
import Spinner from '../../../components/common/Spinner';
import CommonTable from '../../../components/common/table';
import {useMyContext} from '../../../layouts/MerchantDetailsLayout';
import {TableDataType} from '../../../utils/types/table.types';
import useGetProducts from '../hooks/useGetProducts';

const ViewMerchantProducts = () => {
  const {
    columns,
    data,
    setData,
    parentColumns,
    setColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
    loading,
    error,
    loadMore,
    count,
    allProducts,
  } = useGetProducts();

  const navigate = useNavigate();
  const {merchantId} = useParams();
  const {setShowRestrictProduct} = useMyContext();

  useEffect(() => {
    setShowRestrictProduct?.(false);
  }, [setShowRestrictProduct]);

  const [showModal, setShowModal] = useState(false);
  const [modalProductName, setModalProductName] = useState('');

  const toggleModal = () => setShowModal(!showModal);

  const openModal = (row: TableDataType) => {
    setModalProductName(row?.product?.[0] || '');
    toggleModal();
  };

  const goToProductDetails = (productId: string) => {
    if (merchantId) {
      navigate(
        `/merchants/merchant/view/products/productDetails/${merchantId}/${productId}`
      );
    } else {
      return;
    }
  };

  const deactivateProduct = () => {
    toggleModal();
  };
  if (loading) {
    return (
      <div className="mt-[-200px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mr-6 mt-3 max-h-20 w-full rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {data.length ? (
        <div className="mr-[30px] flex basis-full flex-col overflow-scroll">
          <div className="flex h-full flex-col justify-between gap-6 border-b border-dividerGrey pt-[20px]">
            <CommonTable
              columns={columns}
              data={data}
              setData={setData}
              setColumns={setColumns}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              sortingColumns={sortingColumns}
              parentColumns={parentColumns}
              openModalIcon={archiveIcon}
              openModal={openModal}
              viewRecordIconOrText={ExportIcon}
              viewRecord={goToProductDetails}
            />
            <Paginator
              loadMore={loadMore}
              count={count}
              pageInfo={allProducts?.productsWithCursorPagination.pageInfo}
            />
          </div>
          <Modal
            isVisible={showModal}
            onClose={toggleModal}
            height="200px"
            width="300px">
            <div>
              <div className="flex">
                <h2 className="p-4 text-lg font-semibold">
                  Are you sure you want to
                  <br />
                  archive this product?
                </h2>
              </div>
              <div className="flex items-center justify-center pb-3">
                <p className="text-slate-500">"{modalProductName}"</p>
              </div>
              <hr className="h-px w-full pb-2" />
              <div className="flex justify-between px-5">
                <Button label="Cancel" text={true} onClick={toggleModal} />
                <Button
                  label="Archive"
                  className="bg-mediumPurple"
                  onClick={deactivateProduct}
                />
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <h1 className="mr-[30px] max-h-20 w-full rounded-lg bg-slate-200 p-6 font-normal text-black">
          There are no products to display
        </h1>
      )}
    </>
  );
};

export default ViewMerchantProducts;
