import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import Spinner from '../../../components/common/Spinner';
import {
  OperatingTimeFieldsFragment,
  useMerchantDetailsQuery,
} from '../../../generated';
import {days, DayOfWeek, CardItem} from '../../../utils/helpers';

export const genCardItems = (
  operatingTimes?: OperatingTimeFieldsFragment
): CardItem[] | undefined => {
  const obj: Record<string, DayOfWeek[]> = {};
  if (!operatingTimes) return;
  Object.entries(operatingTimes).forEach(([key, value]) => {
    if (value === 'OperatingTime' || key === '__typename') return;
    value?.map((item) => {
      if (item?.from && item?.to && item?.from) {
        obj[item?.from + item?.to] = obj[item?.from + item?.to]?.concat([
          key as DayOfWeek,
        ]) || [key as DayOfWeek];
      }
    });
  });
  if (!obj) return;
  return Object.entries(obj).map(([key, value]) => ({
    days: value.map((entry) => days.filter((day) => day.key === entry)).flat(),
    openingHours: key.slice(0, 5),
    closingHours: key.slice(5),
  }));
};

const OperatingHours = () => {
  const {merchantId} = useParams();
  const [cards, setCards] = useState<CardItem[]>([]);

  const {data, loading, error} = useMerchantDetailsQuery({
    variables: {
      id: merchantId ? merchantId : '',
    },
  });

  const operatingTime = data?.merchant?.operatingTime;

  useEffect(() => {
    if (!operatingTime) return;
    setCards(genCardItems(operatingTime) || []);
  }, [operatingTime]);

  if (loading) {
    return (
      <div className="mt-3 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-2 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4">
        <h1 className="text-3xl font-semibold">Operating Hours</h1>
      </div>
      {cards.length ? (
        cards.map((cardData, i) => (
          <div
            key={i}
            className="border-1 my-4 flex w-[340px] flex-col rounded-md border-solid border-offWhite p-4 shadow-[0_0_10px_-3px_rgba(0,0,0,0.3)]">
            <ul className="my-3 flex text-sm font-semibold text-mediumPurple">
              <li className=" flex gap-[20px]">
                {cardData.days.map((day, idx) => (
                  <span key={`selected_day_${idx}`}>{day.display}</span>
                ))}
              </li>
            </ul>
            <div className="divide-x-3 flex gap-4">
              <div>
                <p className="mb-2 text-xs font-medium">Opening hours</p>
                <h1 className="text-2xl font-semibold">
                  {cardData.openingHours} AM
                </h1>
              </div>
              <hr className="h-12 w-px bg-offWhite" />
              <div>
                <p className="mb-2 text-xs font-medium">Closing hours</p>
                <h1 className="text-2xl font-semibold">
                  {cardData.closingHours} PM
                </h1>
              </div>
            </div>
          </div>
        ))
      ) : (
        <h1 className="mt-2 max-h-20 w-full rounded-lg font-normal text-black">
          There are no operating hours to display
        </h1>
      )}
    </div>
  );
};

export default OperatingHours;
