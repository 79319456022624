import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {
  CursorInput,
  ProductVariantFieldsFragment,
  useProductsWithCursorPaginationQuery,
} from '../../../generated';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetProducts() {
  const {merchantId} = useParams();
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const {
    data: allProducts,
    loading: dataLoading,
    error,
    refetch,
  } = useProductsWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
        merchantId: merchantId || '',
      },
    },
  });
  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after =
          allProducts?.productsWithCursorPagination.pageInfo.endCursor;
        cursorLoad.first = 20;
      } else {
        cursorLoad.before =
          allProducts?.productsWithCursorPagination.pageInfo.startCursor;
        cursorLoad.last = 20;
      }
      const res = await refetch({
        input: {cursor: cursorLoad, merchantId: merchantId || ''},
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      allProducts?.productsWithCursorPagination.pageInfo.endCursor,
      allProducts?.productsWithCursorPagination.pageInfo.startCursor,
      count,
      merchantId,
      refetch,
    ]
  );
  const [data, setData] = useState<TableDataType[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'product',
    'price',
    'items_in_stock',
  ]);
  const parentColumns: TableColumnType[] = [
    'product',
    'price',
    'items_in_stock',
  ];
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const sortingColumns: TableColumnType[] = ['product'];
  const getDefaultVariant = (
    variants: ProductVariantFieldsFragment[]
  ): ProductVariantFieldsFragment => {
    const varr = variants.find((variant) => variant?.default);

    if (varr !== undefined) {
      return varr;
    } else {
      return variants[0];
    }
  };

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!allProducts?.productsWithCursorPagination?.edges) return;
    setData(
      allProducts.productsWithCursorPagination?.edges.map((product) => ({
        id: product?.node.id || '',
        product: [
          product?.node.title || '',
          getDefaultVariant(product?.node.variants || [])?.images?.[0]?.small ||
            '',
        ],
        price: `KES ${formatMoney(
          getDefaultVariant(product?.node.variants || []).price || 0
        )}`,

        items_in_stock: '24 items' || '',
        active: product?.node.active ? 'true' : 'false',
      })) || []
    );
  }, [allProducts]);

  return {
    data,
    setData,
    allProducts,
    loading,
    error,
    loadMore,
    count,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
  };
}
export default useGetProducts;
