import React from 'react';
import {useNavigate} from 'react-router-dom';

import addCategoryIcon from '../../assets/icons/addCategoryIcon.svg';
import noPhoto from '../../assets/icons/noPhoto.svg';
import notificationIcon from '../../assets/icons/notification.svg';
import Fab from '../../components/common/Fab';
import Spinner from '../../components/common/Spinner';
import {useMerchantCategoriesQuery} from '../../generated';
import {createHashtag} from '../../utils/reusablefunctions';

const MerchantCategories = () => {
  const navigate = useNavigate();
  const {data, loading} = useMerchantCategoriesQuery();

  if (loading) {
    return (
      <div className="flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }
  return (
    <div className="flex min-h-full items-center justify-center">
      <div className="absolute right-10 top-8">
        <Fab hasAlert>
          <img src={notificationIcon} />
        </Fab>
      </div>
      <div className="h-inherit flex w-[60%] flex-col gap-7">
        <h1 className="text-4xl font-semibold">Select a category</h1>
        <div className="flex flex-wrap gap-4">
          {data?.merchantCategories.map((item) => (
            <div
              key={item.id}
              onClick={() => navigate(`${createHashtag(item.name)}/${item.id}`)}
              className="flex w-[178px] cursor-pointer flex-col gap-2 rounded-lg border-[1.7px] border-greyish p-4 hover:border-black">
              <img
                src={item.image?.small || noPhoto}
                className="h-9 w-9 rounded object-cover"
              />
              <p className="text-base font-medium leading-4">{item.name}</p>
            </div>
          ))}
          <div
            onClick={() => navigate('add')}
            className="flex w-[178px] cursor-pointer flex-col gap-2 rounded-lg border-[1.7px] border-cashiaBlue border-opacity-60 p-4 hover:border-opacity-100">
            <img src={addCategoryIcon} className="h-9 w-9" />
            <p className="text-base font-medium	leading-4">Create category</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantCategories;
