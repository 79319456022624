import React from 'react';
import {Outlet, useParams} from 'react-router-dom';

import Tabs from '../components/common/Tabs';

const MerchantDocumentsLayout = () => {
  const {merchantId} = useParams();
  const path = `/merchants/merchant/documents/${merchantId || ''}`;
  const tabs = [
    {name: 'All', link: path},
    {name: 'Pending', link: `${path}/pending`},
    {name: 'Approved', link: `${path}/approved`},
    {name: 'Denied', link: `${path}/denied`},
  ];
  return (
    <div className="flex w-full flex-col pr-8">
      <div className="text-2xl font-semibold leading-[30px] text-neutral-800">
        Documents
      </div>
      <Tabs tabs={tabs} />
      <div className="mb-5 flex w-full basis-full flex-col overflow-scroll">
        <Outlet />
      </div>
    </div>
  );
};

export default MerchantDocumentsLayout;
