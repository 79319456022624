import {PropsWithChildren} from 'react';

type Props = {
  className?: string;
  hasAlert?: boolean;
  alertStyle?: string;
  onClick?: () => void;
};

const Fab = ({
  onClick,
  className,
  hasAlert,
  alertStyle,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      onClick={onClick}
      className={`relative flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-full bg-white drop-shadow-light ${
        className || ''
      }`}>
      {hasAlert && (
        <div
          className={`absolute right-0 top-0 z-10 h-[8px] w-[8px] rounded-full bg-brightRed ${
            alertStyle || ''
          }`}
        />
      )}
      {children}
    </div>
  );
};

export default Fab;
