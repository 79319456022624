import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export type Tab = {
  name: string;
  count?: number;
  link: string;
  navState?: {name: string; id: string};
};

type Props = {
  tabs: Tab[];
  rightElement?: JSX.Element;
  tabContainerStyles?: string;
  tabLabelStyles?: string;
};

const Tabs = ({
  tabs,
  rightElement,
  tabContainerStyles,
  tabLabelStyles,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  return (
    <div className={`relative mt-8 flex w-full ${tabContainerStyles || ''}`}>
      <ul className="z-10 flex w-full gap-12">
        {tabs.map((tab, i) => (
          <li
            key={i}
            className={`cursor-pointer border-b-4 py-2 text-lg font-medium transition-colors duration-300 ease-in-out
                ${
                  isActive(tab.link) ? 'border-cashiaBlue font-semibold' : ''
                } ${tabLabelStyles || ''}
              `}
            onClick={() => navigate(tab.link, {state: tab.navState})}>
            <p
              className={`${
                isActive(tab.link) ? 'text-cashiaBlue' : 'text-foggy'
              }`}>{`${tab.name} ${
              tab.count ? `(${String(tab.count)})` : ''
            }`}</p>
          </li>
        ))}
        {rightElement && (
          <li className="ml-auto mr-3 cursor-pointer self-center py-2">
            {rightElement}
          </li>
        )}
      </ul>
      <div className="absolute bottom-0 z-0 h-[4px] w-full bg-lightGrey" />
    </div>
  );
};

export default Tabs;
