import React, {useEffect} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import useGetUsers from './hooks/useGetUsers';
import ExportIcon from '../../assets/icons/export.svg';
import Paginator from '../../components/common/Paginator';
import Spinner from '../../components/common/Spinner';
import CommonTable from '../../components/common/table';
import {readableWalletStatus} from '../../utils/reusablefunctions';

const UserVerification = () => {
  const navigate = useNavigate();
  const [searchPhrase] = useOutletContext<[searchPhrase: string]>();

  const {
    data,
    setData,
    loading,
    columns,
    setColumns,
    allCustomers,
    parentColumns,
    selectedIds,
    loadMore,
    count,
    error,
    setSelectedIds,
  } = useGetUsers({searchTerm: searchPhrase || ''});

  const gotToUserVerificationDetails = (userId: string) => {
    navigate(`user/${userId}`);
  };

  useEffect(() => {
    if (!searchPhrase) {
      setData(
        allCustomers?.customersWithCursorPagination.edges.map((customer) => ({
          id: customer.node.id || '',
          name: [
            customer.node.user?.name || '-',
            customer.node.avatar?.small || '',
          ],
          identificationNumber: customer.node.user?.identificationNumber || '-',
          matchscore: String(
            customer.node.user?.kycVerificationStatus?.matchScore || '0'
          ),
          cashtag: customer.node.tag || '-',
          status:
            (customer.node.wallet?.activation?.status &&
              readableWalletStatus(customer.node.wallet.activation.status)) ||
            '',
        })) || []
      );
      return;
    }
    setData(
      allCustomers?.customersWithCursorPagination.edges.map((customer) => ({
        id: customer.node.id || '',
        name: [
          customer.node?.user?.name || '-',
          customer.node.avatar?.small || '',
        ],
        identificationNumber: customer.node.user?.identificationNumber || '-',
        matchscore: String(
          customer.node.user?.kycVerificationStatus?.matchScore || '0'
        ),
        cashtag: customer.node.tag || '-',
        status:
          (customer.node.wallet?.activation?.status &&
            readableWalletStatus(customer.node.wallet.activation.status)) ||
          '',
      })) || []
    );
  }, [
    allCustomers?.customersWithCursorPagination.edges,
    searchPhrase,
    setData,
  ]);
  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="rounded-lg bg-red-500 p-6 text-white">
        Error: {error?.message}
      </h1>
    );
  }

  return (
    <>
      {data.length ? (
        <div className="flex h-full w-full flex-col justify-between gap-5">
          <CommonTable
            columns={columns}
            data={data}
            setData={setData}
            parentColumns={parentColumns}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setColumns={setColumns}
            viewRecord={gotToUserVerificationDetails}
            viewRecordIconOrText={ExportIcon}
          />
          <Paginator
            loadMore={loadMore}
            pageInfo={allCustomers?.customersWithCursorPagination.pageInfo}
            count={count}
          />
        </div>
      ) : (
        <h1 className="rounded-lg bg-slate-200 p-6 text-black">
          There are no user to display
        </h1>
      )}
    </>
  );
};

export default UserVerification;
