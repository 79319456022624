import {TypePolicies} from '@apollo/client';
const typePolicies: TypePolicies = {
  Customer: {
    fields: {
      merchant: {
        merge: false,
      },
    },
  },
  Merchant: {
    fields: {
      categories: {
        merge: false,
      },
    },
  },
  Category: {
    fields: {
      children: {
        merge: false,
      },
    },
  },
  Product: {
    fields: {
      options: {
        merge: false,
      },
      variants: {
        merge: false,
      },
    },
  },
  ProductVariant: {
    fields: {
      images: {
        merge: false,
      },
    },
  },
};

export default typePolicies;
