import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

import Button from '../../components/common/Button';
import ImageModal from '../../components/common/ImageModal';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import TextInput from '../../components/common/TextInput';
import {
  MerchantCategoriesDocument,
  MerchantCategoriesQuery,
  useAddOrUpdateMerchantCategoryMutation,
} from '../../generated';

const AddMerchantCategory = () => {
  const navigate = useNavigate();

  const [addOrUpdateMerchantCategory, {loading, error}] =
    useAddOrUpdateMerchantCategoryMutation();

  const breadcrumbs = [
    {name: 'Merchants categories', location: '/merchant-categories'},
    {name: 'Add', location: '/merchant-categories/add'},
  ];
  const [newData, setNewData] = useState({
    name: '',
    hashtag: '',
    uploadedImgId: '',
  });

  const getUploadedImgId = (id: string) =>
    setNewData({...newData, uploadedImgId: id});

  const add = useCallback(async () => {
    const res = await addOrUpdateMerchantCategory({
      variables: {
        input: {
          name: newData.name,
          imageId: newData.uploadedImgId,
        },
      },
      update: (cache, result) => {
        const cached = cache.readQuery<MerchantCategoriesQuery>({
          query: MerchantCategoriesDocument,
        });
        if (!cached) return;
        cache.writeQuery({
          query: MerchantCategoriesDocument,
          data: {
            ...cached,
            merchantCategories: [
              result.data?.addOrUpdateMerchantCategory,
              ...cached.merchantCategories,
            ],
          },
        });
      },
    });
    if (res.data?.addOrUpdateMerchantCategory) navigate('/merchant-categories');
  }, [
    addOrUpdateMerchantCategory,
    navigate,
    newData.name,
    newData.uploadedImgId,
  ]);

  useEffect(() => {
    if (!error) return;
    toast.error(error.message, {position: 'bottom-left'});
  }, [error]);

  return (
    <div className="relative flex h-full flex-col">
      <Navbar
        title="Merchants categories"
        placeholder="Find a category..."
        breadcrumbs={breadcrumbs}
      />
      <div className="mt-6 flex flex-col justify-between overflow-y-scroll scroll-smooth px-11 py-7 pb-0">
        <div className="flex w-[80%] flex-col gap-[30px]">
          <h1 className="mb-4 text-3xl font-semibold">Add a new category</h1>
          <TextInput
            label="Name"
            description="This name is how the category will appear on all platforms"
            onChange={(e) => setNewData({...newData, name: e.target.value})}
            inputStyle="h-[38px]"
          />
          <TextInput
            label="Hashtag"
            description="The “hashtag” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers,
          and hyphens."
            onChange={(e) => setNewData({...newData, hashtag: e.target.value})}
            inputStyle="h-[38px]"
          />
          <div className="mb-24 flex flex-col">
            <h1 className="text-xl font-semibold leading-5">Thumbnail</h1>
            <p
              className={`pt-2.5 leading-5 text-darkGrey
                `}>
              Add a thumbnail for your category
            </p>
            <ImageModal
              getUploadedImgId={getUploadedImgId}
              title="Upload a thumbnail image (optional)"
              description="This will be displayed alongside the parent category."
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 float-left flex cursor-pointer flex-row items-center justify-between gap-2 border-t bg-veryLightGrey p-7 py-2">
          <Button
            label="Cancel"
            text
            onClick={() => navigate('/merchant-categories')}
          />
          <Button
            disabled={
              !(
                !!newData.name.length &&
                !!newData.hashtag.length &&
                !!newData.uploadedImgId.length
              )
            }
            label={loading ? <Spinner fillColor="fill-white" /> : 'Add'}
            onClick={add}
          />
        </div>
      </div>
    </div>
  );
};

export default AddMerchantCategory;
