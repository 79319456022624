import {ReactNode} from 'react';

type Props = {
  title: string;
  closedChildren: ReactNode | string;
  buttonLabel?: string;
  onButtonClick?: () => void;
};

export const EditAccordion = ({
  title,
  onButtonClick,
  closedChildren,
  buttonLabel,
}: Props) => {
  return (
    <div className="flex justify-between border-b border-dividerGrey py-[20px]">
      <div>
        <div className="flex items-center justify-between">
          <h6 className="mb-[10px] text-[18px] font-semibold">{title}</h6>
          <button
            className="mb-[10px] mr-1 text-[16px] font-semibold text-mediumPurple underline"
            onClick={onButtonClick}>
            {buttonLabel}
          </button>
        </div>
        {closedChildren}
      </div>
    </div>
  );
};
