type Props = {
  className?: string;
  iconStyle?: string;
  onClick?: () => void;
  icon: string | JSX.Element;
};

const IconButton = ({iconStyle, className, onClick, icon}: Props) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer bg-transparent ${className || ''}`}>
      {typeof icon === 'string' ? (
        <img src={icon} className={iconStyle} />
      ) : (
        icon
      )}
    </div>
  );
};

export default IconButton;
