import {useState} from 'react';
import {Outlet} from 'react-router-dom';

import Navbar from '../components/common/Navbar';

const UserVerificationLayout = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  return (
    <div className="flex h-full flex-col">
      <div className="flex-2">
        <Navbar
          title="User Verification"
          placeholder="Find a user..."
          setSearchPhrase={setSearchPhrase}
        />
      </div>
      <div className="flex-1 overflow-y-scroll scroll-smooth px-11 py-7 pb-0">
        <Outlet context={[searchPhrase]} />
      </div>
    </div>
  );
};

export default UserVerificationLayout;
