import {useState, useEffect, useCallback} from 'react';
import {toast} from 'react-hot-toast';

import CloseIcon from '../../../assets/icons/close-icon.svg';
import Button from '../../../components/common/Button';
import Spinner from '../../../components/common/Spinner';
import {
  CustomerDocument,
  CustomerQuery,
  WalletActivationStatus,
  useChangeCustomerWalletStateMutation,
} from '../../../generated';
import {useCustomerContext} from '../../../layouts/CustomerViewLayout';

type Props = {
  title: string;
  value?: WalletActivationStatus | string;
  cancel: () => void;
};

const status = [
  {
    name: 'Verified',
    des: 'Status when all forms of verifications are completed',
    color: 'bg-green-500',
    value: WalletActivationStatus.Verified,
  },
  {
    name: 'In Progress',
    des: 'Status when either verification starts, phone verification or identity',
    color: 'bg-orange-500',
    value: WalletActivationStatus.InProgress,
  },
  {
    name: 'Pending',
    des: 'The default status',
    color: 'bg-orange-500',
    value: WalletActivationStatus.Pending,
  },
];

const WalletStatusEdit = ({title, value, cancel}: Props) => {
  const {customer} = useCustomerContext();
  const [state, setState] = useState<WalletActivationStatus | string>();
  const [changeCustomerWalletStateMutation, {loading}] =
    useChangeCustomerWalletStateMutation();

  const close = useCallback(() => {
    cancel();
    setState(value);
  }, [cancel, value]);

  const save = useCallback(async () => {
    if (value === state) return;
    const res = await changeCustomerWalletStateMutation({
      variables: {
        id: customer?.id,
        state: state as WalletActivationStatus,
        reason: 'change state',
      },
      update: (cache) => {
        const variables = {
          id: customer?.id,
        };
        const cached = cache.readQuery<CustomerQuery>({
          query: CustomerDocument,
          variables,
        });
        if (!cached) return;
        cache.writeQuery({
          query: CustomerDocument,
          variables,
          data: {
            ...cached,
            customer: {
              ...cached.customer,
              wallet: {
                ...cached.customer.wallet,
                activation: {
                  ...cached.customer.wallet?.activation,
                  status: state,
                },
              },
            },
          },
        });
        close();
      },
    });
    if (res.errors?.length) {
      close();
      toast.error(`An error occured when updating wallet status`, {
        position: 'bottom-left',
      });
    }
  }, [changeCustomerWalletStateMutation, close, customer?.id, state, value]);

  useEffect(() => {
    if (!value) return;
    setState(value);
  }, [value]);

  return (
    <div className="relative mt-4 flex flex-col gap-3 rounded-lg border border-cashia-grey py-6 pr-3">
      <img
        src={CloseIcon}
        className="absolute right-6 top-6 cursor-pointer"
        onClick={cancel}
      />
      <div className="flex w-[90%] flex-col gap-3 px-4">
        <p className="text-lg font-semibold leading-6">{title}</p>
        <div className="flex flex-col gap-5">
          {status.map((item, i) => (
            <div key={i} className="flex justify-start gap-4 align-top">
              <input
                id="default-radio-1"
                type="radio"
                value={item.value}
                checked={state === item.value}
                name="default-radio"
                className="h-6 w-6 cursor-pointer accent-black"
                onChange={(e) => setState(e.target.value)}
              />
              <div className="flex justify-start gap-2 align-top">
                <div className={`${item.color} mt-1 h-4 w-4 rounded-full`} />
                <div className="flex flex-col">
                  <p className="text-base font-medium">{item.name}</p>
                  <p className="text-sm font-normal text-foggy">{item.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr className="mt-3" />
      <div className="flex justify-between px-4">
        <Button
          label="Cancel"
          text
          labelStyle="font-semibold text-base leading-5"
          onClick={() => cancel()}
        />
        <Button
          label={loading ? <Spinner fillColor="fill-white" /> : 'Save'}
          labelStyle="font-semibold text-base leading-5"
          disabled={value === state}
          onClick={save}
        />
      </div>
    </div>
  );
};

export default WalletStatusEdit;
