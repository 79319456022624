import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import React, {
  useContext,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';

import {
  localStorageRefreshTokenKey,
  localStorageAccessTokenKey,
  apiUrl,
  baseUrl,
} from './constants';
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  RefreshTokenDocument,
} from '../generated';

interface NewTokens {
  newAccessToken: string;
  newRefreshToken: string;
}

interface Context {
  isAuthenticated: boolean;
  logout(): Promise<void>;
  makeLoginWithPost(email: string, password: string): Promise<void>;
  loginError?: string;
  getNewTokens(): Promise<NewTokens | undefined>;
}

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  logout: async () => undefined,
  makeLoginWithPost: async () => undefined,
  getNewTokens: async () => undefined,
});

interface LoginData {
  data: {access_token: string; refresh_token: string};
}

export const useAuth = (): Context => useContext(AuthContext);
export const promiseToObservable = (
  promise: Promise<NewTokens | undefined>
): Observable<unknown> =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const AuthProvider = ({children}: PropsWithChildren) => {
  const [, setAccessToken] = useState<string | null | undefined>();
  const [, setRefreshToken] = useState<string | null | undefined>();
  const [erroLogin, setErrorLogin] = useState('');

  // Refresh tokens
  const getNewTokens = async () => {
    const httpLink = createHttpLink({
      uri: apiUrl,
    });
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
    });
    const localStorageRefreshToken = localStorage.getItem(
      localStorageRefreshTokenKey
    );
    if (!localStorageRefreshToken) return;
    let res: FetchResult<RefreshTokenMutation, Record<string, unknown>>;
    try {
      res = await client.mutate<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
      >({
        mutation: RefreshTokenDocument,
        variables: {
          input: {
            refreshToken: localStorageRefreshToken,
          },
        },
      });
    } catch (e) {
      await logout();
      return;
    }

    const newAccessToken = res.data?.refreshToken?.accessToken;
    const newRefreshToken = res.data?.refreshToken?.refreshToken;
    if (newAccessToken && newRefreshToken) {
      setTokens(newAccessToken, newRefreshToken);
      return {newAccessToken, newRefreshToken};
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem(localStorageAccessTokenKey)
  );

  // Utiliity
  const setTokens = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(localStorageAccessTokenKey, accessToken);
    localStorage.setItem(localStorageRefreshTokenKey, refreshToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setIsAuthenticated(true);
  }, []);

  const removeTokens = useCallback(() => {
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setIsAuthenticated(false);
  }, []);

  // Functions
  const logout = useCallback(async (): Promise<void> => {
    removeTokens();
  }, [removeTokens]);

  const makeLoginWithPost = async (email: string, password: string) => {
    await fetch(baseUrl + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: {
          app: 'admin',
          password,
          email: email,
        },
      }),
    })
      .then((res) => {
        if (res.status === 403) {
          throw new Error(`Wrong credentials, please try again`);
        }
        return res.json();
      })
      .then((data) => {
        const {
          data: {access_token, refresh_token},
        } = data as LoginData;
        setTokens(access_token, refresh_token);
      })
      .catch((e: Error) => {
        setErrorLogin(e.message || `Unable to log in, please try again later`);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logout,
        makeLoginWithPost,
        getNewTokens,
        loginError: erroLogin,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
