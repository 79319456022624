import React, {useContext, useState, useEffect, PropsWithChildren} from 'react';
import {toast} from 'react-hot-toast';

import {isAdmin, isUser} from './apollo/helpers';
import {useAuth} from './auth';
import {useMeQuery, UserFieldsFragment, MeFieldsFragment} from '../generated';

interface Context {
  user?: UserFieldsFragment;
  admin?: MeFieldsFragment;
}

export const UserContext = React.createContext<Context>({});

export const useUserAuth = (): Context => useContext(UserContext);

const UserProvider = ({children}: PropsWithChildren) => {
  const [admin, setAdmin] = useState<MeFieldsFragment>();
  const [user, setUser] = useState<UserFieldsFragment>();
  const {isAuthenticated} = useAuth();
  const {data, error} = useMeQuery({skip: !isAuthenticated});

  useEffect(() => {
    if (!!data?.me && isAdmin(data?.me) && isUser(data?.me?.user) && !error) {
      setUser(data?.me?.user || undefined);
      setAdmin(data?.me || undefined);
    }
  }, [data, error]);

  const [errorMessage, setErrorMessage] = useState('');

  !!errorMessage.length &&
    toast.error(errorMessage, {
      duration: 4000,
      position: 'bottom-left',
    });

  useEffect(() => {
    if (!error || !isAuthenticated) return;
    setErrorMessage(`Unable to get user info. ${error.message}`);
  }, [error, isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        user,
        admin,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
