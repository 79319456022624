import {useCallback, useEffect, useState} from 'react';

import ApproveIcon from '../../../assets/icons/approve.svg';
import DeactivateIcon from '../../../assets/icons/deactivated.svg';
import PendingIcon from '../../../assets/icons/timer.svg';
import {
  ApprovalState,
  CursorInput,
  useMerchantsWithCursorPaginationQuery,
} from '../../../generated';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';
import {useUserAuth} from '../../../utils/user';

export const merchantReadableStatus = (status: ApprovalState) => {
  return {
    APPROVED: ['Active', ApproveIcon],
    REJECTED: ['Rejected', DeactivateIcon],
    REVIEW_PENDING: ['Pending', PendingIcon],
    CHANGES_REQUIRED: ['Changes Required', PendingIcon],
    NOT_IN_REVIEW: ['Not in Review', PendingIcon],
  }[status];
};
type UseGetMerchantsProps = {
  approvalStates?: ApprovalState[];
  searchTerm?: string;
};
function useGetMerchants({approvalStates, searchTerm}: UseGetMerchantsProps) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const {admin} = useUserAuth();

  const {
    data: allMerchants,
    loading: dataLoading,
    error,
    refetch,
  } = useMerchantsWithCursorPaginationQuery({
    variables: {
      input: {
        cursor: {
          first: 20,
        },
        approvalStates,
        search: searchTerm || '',
      },
    },
  });

  const loadMore = useCallback(
    async (next: boolean) => {
      setLoading(true);
      const cursorLoad: CursorInput = {};
      if (next) {
        cursorLoad.after =
          allMerchants?.merchantsWithCursorPagination.pageInfo.endCursor;
        cursorLoad.first = 20;
      } else {
        cursorLoad.before =
          allMerchants?.merchantsWithCursorPagination.pageInfo.startCursor;
        cursorLoad.last = 20;
      }
      const res = await refetch({
        input: {
          cursor: cursorLoad,
          approvalStates,
          search: admin ? searchTerm : '',
        },
      });
      if (res.data) {
        next ? setCount(count + 1) : setCount(count - 1);
      }
      setLoading(false);
    },
    [
      allMerchants?.merchantsWithCursorPagination.pageInfo.endCursor,
      allMerchants?.merchantsWithCursorPagination.pageInfo.startCursor,
      count,
      refetch,
      approvalStates,
      admin,
      searchTerm,
    ]
  );

  const [data, setData] = useState<TableDataType[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'email',
    'phone_number',
    'cashtag',
    'status',
  ]);
  const parentColumns: TableColumnType[] = [
    'name',
    'email',
    'phone_number',
    'website',
    'cashtag',
    'status',
  ];
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const sortingColumns: TableColumnType[] = ['name', 'status'];

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    if (!allMerchants?.merchantsWithCursorPagination.edges) return;
    setData(
      allMerchants?.merchantsWithCursorPagination?.edges?.map((merchant) => ({
        id: merchant.node.id || '',
        name: [merchant.node?.name || '-', merchant.node.logo?.small || ''],
        email: merchant.node.user?.email || '-',
        phone_number: `+${merchant.node.user?.phone?.countryCode || '-'}${
          merchant.node.user?.phone?.number || '-'
        }`,
        website: merchant.node.website || '-',
        cashtag: `@${merchant.node.tag || '-'}`,
        status:
          (merchant?.node.approvalState &&
            merchantReadableStatus(merchant?.node.approvalState)) ||
          '',
      })) || []
    );
  }, [allMerchants]);

  useEffect(() => {
    setCount(1);
  }, [approvalStates]);

  return {
    data,
    setData,
    allMerchants,
    loading,
    error,
    columns,
    setColumns,
    parentColumns,
    selectedIds,
    setSelectedIds,
    sortingColumns,
    loadMore,
    count,
    searchTerm,
  };
}

export default useGetMerchants;
