import {createContext, useContext} from 'react';
import {Outlet, useParams} from 'react-router-dom';

import {BreadcrumbType} from '../components/common/Breadcrumb';
import Spinner from '../components/common/Spinner';
import TreeNav, {NavTreeType} from '../components/common/TreeNav';
import {CourierFieldsFragment, useCourierQuery} from '../generated';

type CourierContent = {
  breadcrumbs: BreadcrumbType[];
  courier?: CourierFieldsFragment;
};
const CourierContext = createContext<CourierContent>({
  breadcrumbs: [],
});
export const useCourierContext = () => useContext(CourierContext);

const tree = (id: string): NavTreeType[] => {
  return [
    {
      name: undefined,
      link: undefined,
      children: [
        {
          name: 'Personal Details',
          link: `/courier/${id}/account`,
        },
        // {
        //   name: 'Deliveries',
        //   link: `/courier/${id}/account`,
        // },
      ],
    },
  ];
};

const DriversAndRidersViewLayout = () => {
  const {id: courierId} = useParams();
  // const {pathname} = useLocation();
  const {data, loading} = useCourierQuery({
    variables: {id: courierId || ''},
  });

  const shortenTxt = (txt: string) => {
    if (txt?.length > 20) return `${txt.substring(0, 15)}...`;
    return txt;
  };

  const getBreadCrumbs = () => {
    const breadcrumbs = [
      {
        name: 'Drivers & Riders',
        location: '/couriers',
      },
      {
        name: shortenTxt(data?.courier?.user?.name || '-'),
        location: `/customer/${courierId || ''}/account`,
      },
    ];
    // if (pathname.includes('deliveries')) {
    //   breadcrumbs.push({
    //     name: 'Deliveries',
    //     location: `/customer/${courierId || ''}/deliveries`,
    //   });
    // }
    return breadcrumbs;
  };

  const value: CourierContent = {
    breadcrumbs: getBreadCrumbs(),
    courier: data?.courier,
  };

  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="flex h-screen w-80 flex-col gap-6 pt-8">
        <p className="px-[30px] text-2xl font-semibold leading-6">
          {shortenTxt(data?.courier.user?.name || '-')}
        </p>
        <TreeNav tree={tree(courierId || '')} />
      </div>
      <div className="w-full overflow-y-scroll px-8">
        <CourierContext.Provider value={value}>
          <Outlet />
        </CourierContext.Provider>
      </div>
    </div>
  );
};

export default DriversAndRidersViewLayout;
