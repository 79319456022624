import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';

import Button from './Button';
import Modal from './Modal';
import Spinner from './Spinner';
import closeIcon from '../../assets/icons/closeIcon.svg';
import noPhoto from '../../assets/icons/noPhoto.svg';
import trashIcon from '../../assets/icons/trashIcon.svg';
import {useUploadMediaMutation} from '../../generated';

type Img = {
  url?: string;
  file?: File;
};

type Props = {
  imgUrl?: string;
  getUploadedImgId?: (id: string) => void;
  title?: string;
  description?: string;
  isOpen?: boolean;
  onToggle?: (value: boolean) => void;
  thumbnailStyle?: string;
  imageStyle?: string;
};

const ImageModal = ({
  imgUrl,
  getUploadedImgId,
  title,
  description,
  isOpen,
  onToggle,
  thumbnailStyle,
  imageStyle,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<Img | undefined>({
    url: imgUrl,
    file: undefined,
  });

  useEffect(() => {
    if (!isOpen) return;
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!imgUrl) return;
    setImage({
      url: imgUrl,
      file: undefined,
    });
  }, [imgUrl, isOpen]);

  const [uploadMedia, {loading, error}] = useUploadMediaMutation();

  const handleImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  }, []);

  const toggle = useCallback(() => {
    setOpen(!open);
    onToggle?.(!open);
  }, [onToggle, open]);

  const save = useCallback(async () => {
    if (!image?.file) return;
    const res = await uploadMedia({variables: {file: image.file}});
    getUploadedImgId?.(res.data?.uploadMedia?.id || '');
    toggle();
  }, [getUploadedImgId, image?.file, toggle, uploadMedia]);

  useEffect(() => {
    if (!error) return;
    toast.error(error.message, {position: 'bottom-left'});
  }, [error]);

  return (
    <>
      <div
        className={`mt-3 h-[100px] w-[100px] cursor-pointer ${
          thumbnailStyle || ''
        }`}
        onClick={toggle}>
        <img
          src={image?.url ? image.url : noPhoto}
          className="h-full w-full rounded-lg object-cover"
        />
      </div>
      <Modal
        isVisible={open}
        onClose={toggle}
        containerStyle="bg-modalGrey"
        className="rounded-2xl">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between p-7 pb-4">
            <h4 className="text-xl font-semibold leading-5">{title}</h4>
            <img src={closeIcon} className="cursor-pointer" onClick={toggle} />
          </div>
          <hr />
          <div className="flex items-center justify-center p-7">
            <div className="relative h-[200px] w-[200px] rounded-lg bg-greyish">
              {image?.url && (
                <img
                  src={trashIcon}
                  className={`absolute right-2 top-2 rounded bg-white ${
                    imageStyle || ''
                  }`}
                  onClick={() => setImage(undefined)}
                />
              )}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImage}
                id="img"
              />
              <label htmlFor="img">
                <img
                  src={image?.url ? image.url : noPhoto}
                  className="h-full w-full cursor-pointer rounded-lg object-cover"
                />
              </label>
            </div>
            <div className="ml-7 flex basis-[57%] flex-col gap-5">
              <p className="text-base font-normal leading-5 text-darkGrey">
                {description}
              </p>
              <Button
                label={loading ? <Spinner /> : 'Save'}
                fullWidth
                disabled={!image?.file}
                className="w-inherit border-2 border-black bg-transparent disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                labelStyle="text-black"
                onClick={save}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageModal;
