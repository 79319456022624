import {useState, useRef, useEffect, PropsWithChildren} from 'react';

type Positions = 'left' | 'right' | 'center';

type Props = {
  actionElement: JSX.Element;
  dropDownPosition: Positions;
  className?: string;
  persistent?: boolean;
};

const DropDown = ({
  actionElement,
  dropDownPosition,
  className,
  persistent = false,
  children,
}: PropsWithChildren<Props>) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const getPostion = (type: Positions) => {
    return {
      left: 'left-0',
      right: 'right-0',
      center: 'left-1/2 transform -translate-x-1/2',
    }[type];
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const menuCondition =
        persistent || !menuRef?.current?.contains(e.target as Node);
      if (menuCondition && !btnRef?.current?.contains(e.target as Node)) {
        setShow(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => window.removeEventListener('click', handleOutsideClick);
  }, [persistent, show]);

  return (
    <div className="relative w-fit">
      <div ref={btnRef} onClick={() => setShow(!show)}>
        {actionElement}
      </div>
      {show && (
        <div
          ref={menuRef}
          id="dropdown"
          className={`absolute top-11 ${getPostion(dropDownPosition)} z-10
          divide-y divide-gray-100 rounded-lg bg-white shadow-[0px_0px_10px_rgba(0,0,0,0.25)] transition-all duration-500 ease-in-out ${
            className || ''
          }`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default DropDown;
