import {useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import DocIcon from '../../../assets/icons/document-text.svg';
import GreenConfirmIcon from '../../../assets/icons/green-confirm.svg';
import PendingIcon from '../../../assets/icons/pendingIcon.svg';
import RedDeniedIcon from '../../../assets/icons/red-denied.svg';
import TrashIcon from '../../../assets/icons/trashIcon.svg';
import Spinner from '../../../components/common/Spinner';
import {
  DocumentStatus,
  DocumentType,
  MerchantDocumentFieldsFragment,
  useMerchantDocumentsQuery,
} from '../../../generated';

type DocumentProps = {status?: DocumentStatus};

const MerchantDocuments = ({status}: DocumentProps) => {
  const {merchantId} = useParams();
  const navigate = useNavigate();

  const {data, loading, error} = useMerchantDocumentsQuery({
    variables: {
      input: {
        merchantId,
        status,
      },
    },
  });

  const readableDocStatus = (docStatus?: DocumentStatus | null) => {
    if (!docStatus) return [];
    return {
      DENIED: [
        'border-red-700',
        'Denied',
        RedDeniedIcon,
        'bg-rose-200',
        'text-red-700',
      ],
      APPROVED: [
        'border-green-600',
        'Approved',
        GreenConfirmIcon,
        'bg-emerald-100',
        'text-green-600',
      ],
      DELETED: [
        'border-red-900',
        'Deleted',
        TrashIcon,
        'bg-rose-300',
        'text-black',
      ],
      PENDING: [
        'border-stone-300',
        'Pending',
        PendingIcon,
        'bg-orange-200',
        'text-[#FF8732]',
      ],
    }[docStatus];
  };

  const groupedDocs = useMemo(() => {
    const result: Record<
      DocumentType,
      {title: string; docs: MerchantDocumentFieldsFragment[]}
    > = {
      [DocumentType.KraPin]: {title: 'KRA PIN', docs: []},
      [DocumentType.Cr12]: {title: 'CR12 Certificate', docs: []},
      [DocumentType.RegistrationCertificate]: {
        title: 'Certificate of Registration',
        docs: [],
      },
      [DocumentType.IncorporationCertificate]: {
        title: 'Certificate of Incorporation',
        docs: [],
      },
      [DocumentType.Directors]: {
        title: 'Identification documents of directors',
        docs: [],
      },
      [DocumentType.PartnershipDeed]: {
        title: 'Partnership Deed/Affidavit',
        docs: [],
      },
    };

    data?.merchantDocuments.forEach((doc) => {
      const checkedDoc = status ? (doc.status === status ? doc : null) : doc;
      if (checkedDoc && doc.documentType) {
        result[doc.documentType].docs.push(checkedDoc);
      }
    });
    return result;
  }, [data?.merchantDocuments, status]);

  if (loading) {
    return (
      <div className="mt-[-150px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-2 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {data?.merchantDocuments.length ? (
        Object.values(groupedDocs).map(({title, docs}, i) =>
          docs.length ? (
            <div className="mt-8 flex flex-col gap-2" key={i}>
              <h2 className="text-lg font-semibold leading-snug text-neutral-800">
                {title}
              </h2>
              <ul className="flex flex-col gap-3">
                {docs.map((doc) => (
                  <li
                    className="flex w-full flex-col rounded-[10px] border border-stone-300 bg-neutral-50 shadow"
                    key={doc.id}>
                    <div className="flex w-full justify-between p-6">
                      <div className="flex items-center gap-2">
                        <img src={DocIcon} />
                        <p className="truncate text-base font-medium leading-tight text-neutral-800">
                          {doc.fileName}
                        </p>
                      </div>
                      <div className="flex w-[30%] items-center justify-between">
                        <div
                          className={`inline-flex items-center justify-start gap-[5px] rounded-[28px] px-2.5 py-1 ${
                            readableDocStatus(doc.status)[3]
                          }`}>
                          <img
                            className="relative h-5 w-5 fill-white"
                            src={readableDocStatus(doc.status)[2]}
                          />
                          <div
                            className={`text-[13px] font-medium ${
                              readableDocStatus(doc.status)[4]
                            }`}>
                            {readableDocStatus(doc.status)[1]}
                          </div>
                        </div>
                        <button
                          className="inline-flex h-[33px] w-[67px] items-center justify-start gap-[5px] rounded-[36px] border border-purple-700 bg-neutral-50 px-[15px] py-2.5 text-[13px] font-medium text-purple-700"
                          onClick={() =>
                            navigate(
                              `/merchants/verify-documents/${
                                merchantId || ''
                              }/${doc.id || ''}`
                            )
                          }>
                          Verify
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )
        )
      ) : (
        <h1 className="mt-4 rounded-lg bg-slate-200 p-6 text-black">
          There are no {status?.toLowerCase()} documents to display
        </h1>
      )}
    </>
  );
};

export default MerchantDocuments;
