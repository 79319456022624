import {useEffect} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import useGetMerchants, {merchantReadableStatus} from './hooks/useGetMerchants';
import downArrow from '../../assets/icons/downArrow.svg';
import ExportIcon from '../../assets/icons/export.svg';
import Button from '../../components/common/Button';
import DropDown from '../../components/common/DropDown';
import Paginator from '../../components/common/Paginator';
import Spinner from '../../components/common/Spinner';
import CommonTable from '../../components/common/table';
import {ApprovalState} from '../../generated';
import {useUserAuth} from '../../utils/user';

type Props = {
  approvalStates?: ApprovalState[];
};

const Merchants = ({approvalStates}: Props) => {
  const navigate = useNavigate();
  const [searchPhrase] = useOutletContext<[string]>();
  const {admin} = useUserAuth();

  const {
    columns,
    data,
    setData,
    selectedIds,
    setSelectedIds,
    setColumns,
    sortingColumns,
    parentColumns,
    allMerchants,
    loadMore,
    count,
    loading,
    error,
  } = useGetMerchants({
    approvalStates,
    searchTerm: admin ? searchPhrase : '',
  });

  const goToMerchantDetails = (merchantId: string) => {
    navigate(`/merchants/merchant/view/location/${merchantId}`);
  };

  useEffect(() => {
    if (!searchPhrase) {
      setData(
        allMerchants?.merchantsWithCursorPagination.edges.map((merchant) => ({
          id: merchant.node.id || '',
          name: [merchant.node?.name || '-', merchant.node.logo?.small || ''],
          email: merchant.node.user?.email || '-',
          phone_number: `+${merchant.node.user?.phone?.countryCode || '-'}${
            merchant.node.user?.phone?.number || '-'
          }`,
          website: merchant.node.website || '-',
          cashtag: `@${merchant.node.tag || '-'}`,
          status:
            (merchant?.node.approvalState &&
              merchantReadableStatus(merchant?.node.approvalState)) ||
            '',
        })) || []
      );
      return;
    }
    setData(
      allMerchants?.merchantsWithCursorPagination.edges.map((merchant) => ({
        id: merchant.node.id || '',
        name: [merchant.node?.name || '-', merchant.node.logo?.small || ''],
        email: merchant.node.user?.email || '-',
        phone_number: `+${merchant.node?.phone?.countryCode || '-'}${
          merchant.node.phone?.number || '-'
        }`,
        website: merchant.node.website || '-',
        cashtag: `@${merchant.node.tag || '-'}`,
        status:
          (merchant?.node.approvalState &&
            merchantReadableStatus(merchant?.node.approvalState)) ||
          '',
      })) || []
    );
  }, [allMerchants, searchPhrase, setData]);

  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {data.length ? (
        <div className="flex h-full flex-col gap-6 overflow-y-scroll">
          <div className="flex flex-row gap-2">
            <DropDown
              dropDownPosition="left"
              className="left-1 top-12"
              actionElement={
                <Button
                  label="Type of store"
                  rightIcon={<img src={downArrow} className="ml-3" />}
                  className="w-inherit rounded-md border border-black bg-transparent"
                  labelStyle="text-black"
                />
              }
            />
          </div>
          <div className="flex h-full w-full flex-col justify-between gap-6">
            <CommonTable
              columns={columns}
              data={data}
              setData={setData}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              setColumns={setColumns}
              sortingColumns={sortingColumns}
              parentColumns={parentColumns}
              viewRecord={goToMerchantDetails}
              viewRecordIconOrText={ExportIcon}
            />
            <Paginator
              loadMore={loadMore}
              count={count}
              pageInfo={allMerchants?.merchantsWithCursorPagination.pageInfo}
            />
          </div>
        </div>
      ) : (
        <h1 className="rounded-lg bg-slate-200 p-6 text-black">
          There are no merchants to display
        </h1>
      )}
    </>
  );
};

export default Merchants;
