type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerStyle?: string;
  description?: string;
  descriptionStyle?: string;
  disabled?: boolean;
  onClick?: () => void;
  endAdornment?: React.ReactNode;
  error?: boolean;
  id?: string;
  inputStyle?: string;
  label?: string;
  labelStyle?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  type?: string;
  value?: string;
  startAdornment?: React.ReactNode;
};

const TextInput = ({
  id,
  label,
  description,
  labelStyle,
  descriptionStyle,
  containerStyle,
  inputStyle,
  startAdornment,
  endAdornment,
  placeholder,
  ...other
}: Props) => {
  return (
    <div className={`flex flex-col ${containerStyle || ''}`}>
      <h1 className={`${labelStyle || 'text-xl font-semibold leading-5'}`}>
        {label}
      </h1>
      {description && (
        <p
          className={`${descriptionStyle || 'pt-2.5 leading-5 text-darkGrey'}`}>
          {description}
        </p>
      )}
      <div
        className={`peer flex basis-full flex-row items-center rounded-lg border-half border-greyish bg-offWhite px-2.5 py-2 focus-within:!bg-white focus-within:ring-2 focus-within:ring-black ${
          description ? 'mt-2.5' : ''
        }`}>
        {startAdornment}
        <input
          className={`peer flex basis-full appearance-none  bg-offWhite px-2.5 py-2 text-sm font-medium text-black placeholder-foggy autofill:!bg-offWhite focus:!bg-white focus:outline-none ${
            inputStyle || ''
          }`}
          placeholder={placeholder}
          {...other}
        />
        {endAdornment}
      </div>
    </div>
  );
};

export default TextInput;
