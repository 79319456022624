import {ReactNode} from 'react';

import {ReactComponent as LockIcon} from '../../../assets/images/add_display.svg';

const EmptyProductsDisplay = ({icon}: {icon?: ReactNode}) => {
  return (
    <div className="inline-flex h-[235px] w-full flex-col items-center justify-center gap-5 rounded-[10px] border border-neutral-200 bg-neutral-50 p-[30px]">
      {icon ? icon : <LockIcon />}
      <div className="flex flex-col items-center justify-center gap-2.5">
        <p className="text-center text-2xl font-semibold text-neutral-800">
          Add your First Product
        </p>
        <p className="text-center font-['Roboto'] text-base font-normal leading-tight text-neutral-400">
          Add the products you would like to attach
          <br />
          to this post
        </p>
      </div>
    </div>
  );
};

export default EmptyProductsDisplay;
