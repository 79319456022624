import {useEffect, useState} from 'react';

import SuccessIcon from '../../../assets/icons/approve.svg';
import FailedIcon from '../../../assets/icons/deactivated.svg';
import PendingIcon from '../../../assets/icons/timer.svg';
import {
  Role,
  TransactionSelectionInput,
  TransactionStatus,
  useTransactionsQuery,
} from '../../../generated';
import {useCustomerContext} from '../../../layouts/CustomerViewLayout';
import formatDate from '../../../utils/reusablefunctions/formatDate';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import transactionOptions from '../../../utils/reusablefunctions/transactionOptions';
import {TableColumnType, TableDataType} from '../../../utils/types/table.types';

function useGetCustomerTransactions(id: string, status?: TransactionStatus) {
  const {customer} = useCustomerContext();
  const input: TransactionSelectionInput = {
    page: 1,
    pageSize: 30,
    role: Role.Customer,
    roleId: customer?.id,
  };
  if (status) {
    input.status = status;
  }
  const {
    data: allTransactions,
    loading,
    error,
  } = useTransactionsQuery({
    variables: {
      input,
    },
  });

  const [data, setData] = useState<TableDataType[]>([]);
  const [columns, setColumns] = useState<TableColumnType[]>([
    'name',
    'date',
    'amount',
    'status',
  ]);

  const sortingColumns: TableColumnType[] = ['name'];

  const readableStatus = (transactionStatus: TransactionStatus) => {
    return {
      FAILED: ['Failed', FailedIcon],
      PENDING: ['Pending', PendingIcon],
      SUCCESS: ['Success', SuccessIcon],
    }[transactionStatus];
  };

  useEffect(() => {
    if (!allTransactions) return;
    setData(
      allTransactions.transactions.transactions.map((transaction) => ({
        name: [
          transactionOptions(transaction, customer?.user).name || '-',
          transactionOptions(transaction, customer?.user).logo || '-',
          transactionOptions(transaction, customer?.user).type || '-',
        ],
        date: formatDate(transaction.date as Date, {
          withShortMonth: true,
          withTime: false,
          withYear: true,
        }),
        amount: `KES ${formatMoney(transaction.amount)}`,
        status: readableStatus(transaction.status),
        id: transaction.id,
        transaction_category: transactionOptions(transaction, customer?.user)
          .type,
      }))
    );
  }, [allTransactions, customer?.user]);

  return {
    data,
    setData,
    allTransactions,
    loading,
    error,
    columns,
    setColumns,
    sortingColumns,
  };
}

export default useGetCustomerTransactions;
