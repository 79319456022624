import notificationIcon from '../../../assets/icons/notification.svg';
import Breadcrumb, {
  BreadcrumbType,
} from '../../../components/common/Breadcrumb';
import Fab from '../../../components/common/Fab';
import ImgWithText from '../../../components/common/ImgWithText';
import {readableWalletStatus} from '../../../utils/reusablefunctions';
import {useUserContext} from '../view/user';

const UserTopBar = () => {
  const {customer} = useUserContext();
  const breadcrumbs: BreadcrumbType[] = [
    {name: 'User Verification', location: '/user-verification'},
    {name: customer?.user?.name || ''},
  ];
  return (
    <div className="sticky top-0 z-20 flex justify-between bg-white pb-5 pt-8">
      <div className=" flex min-w-[200px] gap-5 self-center">
        <p className="px-[40px] text-2xl font-semibold leading-6">
          User Verification
        </p>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </div>
      <div className="v flex min-w-[200px] gap-9 px-[40px]">
        <ImgWithText
          text={readableWalletStatus(customer?.wallet?.activation?.status)[0]}
          url={readableWalletStatus(customer?.wallet?.activation?.status)[1]}
          variant="circle"
        />
        <Fab hasAlert>
          <img src={notificationIcon} />
        </Fab>
      </div>
    </div>
  );
};

export default UserTopBar;
