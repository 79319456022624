import type {PDFDocumentProxy} from 'pdfjs-dist';
import {useCallback, useEffect, useState} from 'react';
import {pdfjs, Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {useNavigate, useParams} from 'react-router-dom';

import ApproveIcon from '../../../assets/icons/approve.svg';
import DeactivateIcon from '../../../assets/icons/deactivated.svg';
import {ReactComponent as MaxMizeIcon} from '../../../assets/icons/maximize-4.svg';
import notificationIcon from '../../../assets/icons/notification.svg';
import PendingIcon from '../../../assets/icons/timer.svg';
import TrashIcon from '../../../assets/icons/trash.svg';
import Fab from '../../../components/common/Fab';
import ImgWithText from '../../../components/common/ImgWithText';
import Modal from '../../../components/common/Modal';
import Spinner from '../../../components/common/Spinner';
import TextArea from '../../../components/common/TextArea';
import {
  DocumentStatus,
  MerchantDocumentFieldsFragment,
  MerchantDocumentsDocument,
  MerchantDocumentsQuery,
  useChangeMerchantDocumentStatusMutation,
  useInformaKybQuery,
  useMerchantDocsInfoLazyQuery,
} from '../../../generated';
import {formatEmuns} from '../../../utils/reusablefunctions';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const readableStatus = (status?: DocumentStatus) => {
  if (!status) return [];
  return {
    PENDING: ['Pending', PendingIcon],
    APPROVED: ['Approved', ApproveIcon],
    DENIED: ['Denied', DeactivateIcon],
    DELETED: ['Deleted', TrashIcon],
  }[status];
};

export default function VerifyDocument() {
  const navigate = useNavigate();
  const {merchantId, documentId} = useParams();
  const [base64Value, setBase64Value] = useState<string>();
  const [numPages, setNumPages] = useState<number>();
  const [document, setDocument] = useState<MerchantDocumentFieldsFragment>();
  const [statusChanged, setStatusChanged] = useState<DocumentStatus>();
  const [expandpdfModal, setExpandpdfModal] = useState<boolean>(false);
  const [deniedModal, setDeniedModal] = useState<boolean>(false);
  const [denialReason, setDenialReason] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const [merchantDocsQuery, {data}] = useMerchantDocsInfoLazyQuery({
    variables: {
      id: merchantId || '',
    },
  });

  const {error: merchantInformaError} = useInformaKybQuery({
    variables: {
      id: merchantId || '',
      input: {
        getIndividualKraPinByIdNumber: '37748548',
      },
    },
  });
  const [changeMerchantDocumentStatusMutation, {loading: changeLoading}] =
    useChangeMerchantDocumentStatusMutation();

  const updateStatus = async (newStatus: DocumentStatus, reason?: string) => {
    setStatusChanged(newStatus);
    await changeMerchantDocumentStatusMutation({
      variables: {
        input: {
          documentId: documentId || '',
          status: newStatus,
          reason,
        },
      },
      update: (cache, result) => {
        const cached = cache.readQuery<MerchantDocumentsQuery>({
          query: MerchantDocumentsDocument,
          variables: {merchantId},
        });
        if (cached?.merchantDocuments) {
          cache.writeQuery({
            query: MerchantDocumentsDocument,
            variables: {merchantId},
            data: {
              ...cached,
              merchantDocuments: [...cached.merchantDocuments].map((doc) =>
                doc.id === documentId
                  ? result.data?.changeMerchantDocumentStatus
                  : doc
              ),
            },
          });
        }
        setDocument(result.data?.changeMerchantDocumentStatus);
        setDeniedModal(false);
        setStatusChanged(undefined);
        navigate(`/merchants/merchant/documents/${merchantId || ''}`);
      },
    });
  };

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const downloadFile = useCallback(async () => {
    const res = await merchantDocsQuery();
    const selectedDocument = res.data?.merchant?.merchantDocuments?.find(
      (item) => item.id === documentId
    );
    setDocument(selectedDocument);
    const response = await fetch(
      `https://corsproxy.io/?${encodeURIComponent(
        (selectedDocument?.url || '').replace(/ /g, '%20')
      )}`
    );
    const arrayBufferToBase64Value = arrayBufferToBase64(
      await response.arrayBuffer()
    );
    setBase64Value(arrayBufferToBase64Value);
  }, [documentId, merchantDocsQuery]);

  useEffect(() => {
    void downloadFile();
  }, [downloadFile]);

  return (
    <>
      {base64Value ? (
        <div className="h-full w-full">
          <div className="flex h-[8%] w-full items-center justify-between pl-8 pr-9">
            <h1 className="text-2xl font-semibold leading-[30px] text-neutral-800">
              Verify Document
            </h1>
            <div className="flex gap-9">
              <ImgWithText
                text={
                  document?.status ? readableStatus(document?.status)[0] : ''
                }
                url={
                  document?.status ? readableStatus(document?.status)[1] : ''
                }
                variant="circle"
              />
              <Fab hasAlert>
                <img src={notificationIcon} />
              </Fab>
            </div>
          </div>
          <div className="flex h-[84%] w-full px-20">
            {base64Value && (
              <div className="group relative flex h-fit w-[390px] flex-col gap-2 shadow-lg">
                <div className="inline-flex h-[65px] w-full items-center justify-between rounded-[10px] bg-zinc-100 p-[15px]">
                  <div className="flex flex-col gap-[5px]">
                    <p className="self-stretch text-lg font-semibold capitalize leading-[18px] text-neutral-800">
                      Merchant Uploaded Certificate
                    </p>
                    <p className="self-stretch text-[10px] font-normal leading-3 text-neutral-800">
                      Uploaded by the merchant during the onboarding process.
                    </p>
                  </div>
                </div>
                <Document
                  className="flex w-[390px] shadow-lg"
                  loading={
                    <div className="flex h-[552px] w-[390px] items-center justify-center bg-zinc-100/60">
                      <Spinner width={150} />
                    </div>
                  }
                  file={`data:application/pdf;base64,${base64Value || ''}`}
                  onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} width={390} />
                </Document>
                <div className="absolute bottom-6 left-1/2 z-10 hidden -translate-x-1/2 transform cursor-pointer items-center rounded border bg-white text-sm shadow-md group-hover:flex">
                  <button
                    className="flex cursor-pointer items-center justify-center p-2 px-4 hover:bg-stone-300"
                    disabled={pageNumber <= 1}
                    onClick={() => changePage(-1)}>
                    &lt;
                  </button>
                  <p className="px-1">
                    {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </p>
                  <button
                    className="flex cursor-pointer items-center justify-center p-2 px-4 hover:bg-stone-300"
                    disabled={pageNumber >= (numPages || 0)}
                    onClick={() => changePage(1)}>
                    &gt;
                  </button>
                </div>
                <div
                  className="absolute bottom-7 right-3 z-10 inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded bg-stone-300 shadow"
                  onClick={() => setExpandpdfModal(!expandpdfModal)}>
                  <MaxMizeIcon className="h-5 w-5" />
                </div>
              </div>
            )}
            <div className="flex h-full w-full flex-col gap-6 p-10 pb-4 pr-5 pt-0">
              <div className="inline-flex w-full flex-col items-start justify-start gap-[15px] rounded-[15px] bg-[#fff] p-[25px] shadow-lg">
                <h4 className="text-xl font-semibold text-zinc-700">
                  Merchant info
                </h4>
                <div className="flex w-full flex-col items-start justify-start gap-4">
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Merchant Type</p>
                    <p className="font-medium capitalize">
                      {data?.merchant?.merchantType &&
                        formatEmuns(data?.merchant?.merchantType)}
                    </p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Document Type</p>
                    <p className="font-medium capitalize">
                      {document?.documentType &&
                        formatEmuns(document?.documentType)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="inline-flex w-full flex-col items-start justify-start gap-[15px] overflow-hidden rounded-[15px] bg-[#fff] p-[25px] shadow-lg">
                <h4 className="text-xl font-semibold text-zinc-700">
                  InformaKYB info
                </h4>
                <div className="flex h-full w-full flex-col items-start justify-start gap-4 overflow-y-scroll">
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Bussiness Status</p>
                    <p className="font-medium">registered</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Registration Number</p>
                    <p className="font-medium">CPR/2001/00001</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Registration Date</p>
                    <p className="font-medium">1 January 1950</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Postal Address</p>
                    <p className="font-medium">00001</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Physical Address</p>
                    <p className="font-medium">Jane Doe Center</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Phone Number</p>
                    <p className="font-medium">254700123456</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">kra Pin</p>
                    <p className="font-medium">A12345678</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Email</p>
                    <p className="font-medium">info@doe.co.ke</p>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <p className="text-neutral-500">Business Name</p>
                    <p className="font-medium">Doe Inc.</p>
                  </div>
                  <div className="flex h-full w-full flex-col gap-4">
                    <h4 className="text-center text-lg font-medium text-zinc-700">
                      Patners
                    </h4>
                    <div className="flex h-full w-full flex-col gap-1 rounded bg-lightGrey p-3">
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Name</p>
                        <p className="font-medium">Patner one</p>
                      </div>
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Id Type</p>
                        <p className="font-medium">Alien</p>
                      </div>
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Id Number</p>
                        <p className="font-medium">34543534543</p>
                      </div>
                    </div>
                    <div className="flex h-full w-full flex-col gap-1 rounded bg-lightGrey p-3">
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Name</p>
                        <p className="font-medium">Patner two</p>
                      </div>
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Id Type</p>
                        <p className="font-medium">Passport</p>
                      </div>
                      <div className="flex w-full flex-row items-center justify-between">
                        <p className="text-neutral-500">Id Number</p>
                        <p className="font-medium">qwer565675rfghd</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {document?.status !== DocumentStatus.Deleted && (
            <div className="flex h-[8%] w-full items-center justify-between border-t border-neutral-200 px-5">
              <button
                className="inline-flex h-9 w-[81px] items-center justify-center gap-2.5 rounded-lg border border-stone-300 bg-neutral-50 px-5 py-2.5 text-center text-base font-medium text-neutral-800 disabled:cursor-not-allowed disabled:opacity-50"
                disabled={!(document?.status !== DocumentStatus.Denied)}
                onClick={() => setDeniedModal(!deniedModal)}>
                {statusChanged === DocumentStatus.Denied && changeLoading ? (
                  <Spinner fillColor="fill-neutral-800" />
                ) : (
                  'Deny'
                )}
              </button>
              <button
                className="inline-flex h-9 w-[106px] items-center justify-center gap-2.5 rounded-lg bg-green-600 px-5 py-2.5 text-center text-base font-medium text-neutral-50 disabled:cursor-not-allowed disabled:opacity-50"
                disabled={!(document?.status !== DocumentStatus.Approved)}
                onClick={() => {
                  void updateStatus(DocumentStatus.Approved);
                }}>
                {statusChanged === DocumentStatus.Approved && changeLoading ? (
                  <Spinner fillColor="fill-white" />
                ) : (
                  'Approve'
                )}
              </button>
            </div>
          )}
        </div>
      ) : merchantInformaError ? (
        <div className="flex items-center justify-center">
          <p className="text-lg text-red-500">
            Error: {merchantInformaError.message}
          </p>
        </div>
      ) : (
        <div className="mt-72 flex items-center justify-center">
          <Spinner width={60} height={60} />
        </div>
      )}
      <Modal
        isVisible={expandpdfModal}
        onClose={() => setExpandpdfModal(!expandpdfModal)}
        width="500px"
        className="rounded-t-2xl">
        {base64Value && (
          <div className="group relative flex h-[707px] w-[500px] flex-col gap-2 bg-white shadow-lg">
            <Document
              className="w-[500px]"
              loading={
                <div className="flex h-[707px] w-[500px] items-center justify-center bg-zinc-100/60">
                  <Spinner width={150} />
                </div>
              }
              file={`data:application/pdf;base64,${base64Value || ''}`}
              onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} width={500} />
            </Document>
            <div className="absolute bottom-6 left-1/2 z-10 hidden -translate-x-1/2 transform cursor-pointer items-center rounded border bg-white text-sm shadow-md group-hover:flex">
              <button
                className="flex cursor-pointer items-center justify-center p-2 px-4 hover:bg-stone-300"
                disabled={pageNumber <= 1}
                onClick={() => changePage(-1)}>
                &lt;
              </button>
              <p className="px-1">
                {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button
                className="flex cursor-pointer items-center justify-center p-2 px-4 hover:bg-stone-300"
                disabled={pageNumber >= (numPages || 0)}
                onClick={() => changePage(1)}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isVisible={deniedModal}
        onClose={() => changeLoading || setDeniedModal(!deniedModal)}
        width="640px"
        className="rounded-2xl">
        <div className="flex flex-col gap-7 rounded-2xl bg-neutral-50 px-7 py-12">
          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold leading-[30px] text-neutral-800">
              Please give a reason for this denial
            </h4>
            <p className="w-[580px] text-base font-normal text-zinc-700">
              This is the justification that will be sent to the merchant
              explaining their denial
            </p>
          </div>
          <TextArea
            value={denialReason}
            onChange={(e) => setDenialReason(e.target.value)}
          />
          <button
            className="flex h-12 w-[181px] items-center justify-center self-end rounded-[10px] bg-violet-900 text-white disabled:cursor-not-allowed disabled:bg-stone-300"
            disabled={!denialReason.length}
            onClick={() => {
              void updateStatus(DocumentStatus.Denied, denialReason);
            }}>
            {statusChanged === DocumentStatus.Denied && changeLoading ? (
              <Spinner fillColor="fill-white" />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </Modal>
    </>
  );
}
