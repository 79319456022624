import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from '@react-google-maps/api';
import React, {useRef, useState} from 'react';

import IconButton from './IconButton';
import Spinner from './Spinner';
import LocationIcon from '../../assets/icons/blue-location.svg';
import Circle from '../../assets/icons/location-circle.svg';
import Close from '../../assets/icons/map-close.svg';
import noPhoto from '../../assets/icons/noPhoto.svg';
import {useGeoCoordinatesToLocationLazyQuery} from '../../generated';
import {mapsApi} from '../../utils/constants';

export type MarkerDetails = {
  id?: string | null;
  name?: string | null;
  address?: string;
  online?: boolean;
  lat?: number;
  lng?: number;
  imgUrl?: string | null;
};

type Props = {
  markers?: MarkerDetails[];
};

const options: google.maps.MapOptions = {
  clickableIcons: false,
  streetViewControl: true,
  mapTypeControl: false,
};

const Map = ({markers: receivedMarkers}: Props) => {
  const [getFormatedAddress, {loading, error}] =
    useGeoCoordinatesToLocationLazyQuery();

  const mapRef = useRef<google.maps.Map>();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState<MarkerDetails>();
  const [markers, setMarkers] = useState<MarkerDetails[] | undefined>(
    receivedMarkers
  );
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: mapsApi,
  });

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({lat, lng}) => lat && lng && bounds.extend({lat, lng}));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = async (marker: MarkerDetails) => {
    setInfoWindowData(marker);
    setIsOpen(true);
    const result = await getFormatedAddress({
      variables: {
        input: {
          lat: marker.lat || 0,
          long: marker.lng || 0,
        },
      },
    });
    if (result?.data?.geoCoordinatesToLocation) {
      setMarkers(
        markers?.map((item) =>
          item.id === marker.id
            ? {
                ...marker,
                address:
                  result?.data?.geoCoordinatesToLocation[0].formattedAddress,
              }
            : item
        )
      );
    }
  };

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerClassName="map-container"
          onLoad={onLoad}
          options={options}
          onClick={() => setIsOpen(false)}>
          {markers?.map((marker) => (
            <MarkerF
              key={marker.id}
              position={{lat: marker.lat || 0, lng: marker.lng || 0}}
              icon={Circle}
              onClick={() => handleMarkerClick(marker)}>
              {isOpen && infoWindowData?.id === marker.id && (
                <InfoWindowF onCloseClick={() => setIsOpen(false)}>
                  <div className="relative max-w-[235px]">
                    <IconButton
                      icon={Close}
                      className="absolute left-4 top-4"
                      onClick={() => setIsOpen(false)}
                    />
                    <img
                      src={marker.imgUrl || noPhoto}
                      className="h-[190px] w-[235px] bg-slate-300 object-cover"
                    />
                    <div className="flex flex-col gap-1 p-5 pt-3">
                      <h3 className="text-base font-semibold">{marker.name}</h3>
                      <div className="flex gap-1">
                        <img src={LocationIcon} />
                        {loading ? (
                          <div className="min-h-[11px] w-[75%] animate-pulse rounded-2xl bg-slate-100" />
                        ) : (
                          <p className="self-center text-[12px] font-medium text-foggy">
                            {marker.address}
                          </p>
                        )}
                        {error && !loading && (
                          <p className="self-center text-[12px] font-medium text-smoothRed">
                            {error.message}
                          </p>
                        )}
                      </div>
                      <span
                        className={`${
                          marker.online
                            ? 'bg-lightGreen text-brightGreen'
                            : 'bg-lightRed text-smoothRed'
                        } max-w-[60px] rounded-2xl px-3 py-[5px]`}>
                        {marker.online ? 'Online' : 'Offline'}
                      </span>
                    </div>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}
        </GoogleMap>
      ) : (
        <div className="flex h-full w-full flex-col justify-center">
          <Spinner width={45} height={45} />
        </div>
      )}
    </>
  );
};

export default Map;
