interface Options {
  withWeekday?: boolean;
  withTime?: boolean;
  withDate?: boolean;
  withMonth?: boolean;
  withShortMonth?: boolean;
  withYear?: boolean;
  hour12?: boolean;
  locales?: string | string[];
}

type FormatDate = (date: Date, options?: Options) => string;

const formatDate: FormatDate = (
  date,
  {
    withWeekday = false,
    withDate = true,
    withTime = true,
    withMonth = false,
    withYear = false,
    withShortMonth = false,
    hour12 = false,
    locales = 'en-KE',
  } = {
    withWeekday: false,
    withDate: true,
    withTime: true,
    withMonth: false,
    withYear: false,
    withShortMonth: false,
    hour12: false,
    locales: 'en-KE',
  }
) => {
  const options: Intl.DateTimeFormatOptions = {};

  if (withDate) {
    options.month = 'long';
    options.day = 'numeric';
  }

  if (withTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
  }

  if (withMonth) {
    options.month = 'long';
  }

  if (withShortMonth) {
    options.month = 'short';
  }

  if (withYear) {
    options.year = 'numeric';
  }

  if (hour12) {
    options.hour12 = true;
  }

  if (withWeekday) {
    options.weekday = 'long';
  }

  const formatter = new Intl.DateTimeFormat(locales, options);

  return formatter.format(new Date(date));
};

export default formatDate;
