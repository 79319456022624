import {useCallback, useEffect, useRef, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useParams} from 'react-router-dom';

import useGetSubCategories from './hooks/useGetSubCategories';
import closeIcon from '../../assets/icons/close-icon.svg';
import editIcon from '../../assets/icons/edit-icon.svg';
import redTrash from '../../assets/icons/red-trash.svg';
import Button from '../../components/common/Button';
import ImageModal from '../../components/common/ImageModal';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import TextInput from '../../components/common/TextInput';
import CommonTable from '../../components/common/table';
import {
  MerchantCategoryDocument,
  MerchantCategoryQuery,
  useAddOrUpdateMerchantCategoryMutation,
} from '../../generated';
import {createHashtag} from '../../utils/reusablefunctions';

export const SubCategories = () => {
  const {id} = useParams();

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(open ? 'auto' : '0px');
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    category,
    columns,
    data,
    setData,
    parentColumns,
    selectedIds,
    setSelectedIds,
    setColumns,
    sortingColumns,
    loading: dataLoading,
  } = useGetSubCategories(id || '');
  const [newData, setNewData] = useState({
    name: '',
    hashtag: '',
    uploadedImgId: '',
  });
  const [addOrUpdateMerchantCategory, {loading, error}] =
    useAddOrUpdateMerchantCategoryMutation();

  const breadcrumbs = [
    {name: 'Merchants categories', location: '/merchant-categories'},
    {
      name: category?.merchantCategory?.name || '',
      location: `/merchant-categories/${createHashtag(
        category?.merchantCategory?.name || ''
      )}/${id || ''}`,
    },
  ];

  const getUploadedImgId = (imgId: string) =>
    setNewData({...newData, uploadedImgId: imgId});

  const add = useCallback(async () => {
    if (!newData.name) return;
    const res = await addOrUpdateMerchantCategory({
      variables: {
        input: {
          parentId: category?.merchantCategory?.id,
          name: newData.name,
          imageId: newData.uploadedImgId,
        },
      },
      update: (cache, result) => {
        const cached = cache.readQuery<MerchantCategoryQuery>({
          query: MerchantCategoryDocument,
          variables: {id: id || ''},
        });
        if (!cached?.merchantCategory) return;
        cache.writeQuery({
          query: MerchantCategoryDocument,
          variables: {id: id || ''},
          data: {
            ...cached,
            merchantCategory: {
              ...cached.merchantCategory,
              children: [
                result.data?.addOrUpdateMerchantCategory,
                ...(cached?.merchantCategory?.children || []),
              ],
            },
          },
        });
      },
    });
    if (res.data?.addOrUpdateMerchantCategory) {
      setOpen(!open);
      setNewData({
        name: '',
        hashtag: '',
        uploadedImgId: '',
      });
    }
  }, [
    addOrUpdateMerchantCategory,
    category?.merchantCategory?.id,
    id,
    newData.name,
    newData.uploadedImgId,
    open,
  ]);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(open ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [open]);

  useEffect(() => {
    if (!error) return;
    toast.error(error.message, {position: 'bottom-left'});
  }, [error]);

  if (dataLoading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="flex-2">
          <Navbar
            title="Merchants categories"
            placeholder="Find a category..."
            breadcrumbs={breadcrumbs}
          />
        </div>
        <div className="flex-1 overflow-y-scroll scroll-smooth px-11 py-7 pb-0">
          <div className="ml-1 flex justify-between">
            <Button
              label="Add subcategory +"
              className={`w-inherit rounded-md px-[15px] ${
                open ? 'border-greyish bg-greyish' : 'bg-mediumPurple'
              }`}
              labelStyle="text-white text-sm"
              onClick={() => setOpen(!open)}
            />
          </div>
          <div
            ref={contentRef}
            style={{
              maxHeight: `${height}`,
              transition:
                'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
            }}
            className="overflow-hidden">
            <div className="relative mt-4 flex h-[400px] w-full flex-col gap-3 rounded-lg border border-cashia-grey py-5 pr-3">
              <img
                src={closeIcon}
                className="absolute right-6 top-6 cursor-pointer"
                onClick={() => setOpen(!open)}
              />
              <h1 className="text-center text-xl font-semibold leading-snug text-neutral-800 underline">
                Add subcategories
              </h1>
              <div className="flex gap-3 px-4">
                <div className="flex flex-col">
                  <p className="text-lg font-semibold text-neutral-800">
                    Thumbnail
                  </p>
                  <ImageModal
                    getUploadedImgId={getUploadedImgId}
                    title="Upload a thumbnail image (optional)"
                    description="This will be displayed alongside the category."
                    thumbnailStyle="h-[200px] w-[200px]"
                  />
                </div>
                <div className="flex w-full flex-col gap-5">
                  <div className="flex flex-col px-4">
                    <TextInput
                      label="Name"
                      value={newData.name}
                      description="This name is how the subcategory will appear on all
                      platforms"
                      descriptionStyle="text-[13px] font-medium text-neutral-500 pt-0"
                      labelStyle="text-base font-medium text-neutral-800"
                      inputStyle="h-6"
                      onChange={(e) =>
                        setNewData({...newData, name: e.target.value})
                      }
                    />
                  </div>
                  <div className="flex flex-col px-4">
                    <TextInput
                      label="Hashtag"
                      value={createHashtag(newData.name)}
                      disabled
                      description="The “hashtag” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers and hyphens."
                      descriptionStyle="text-[13px] font-medium text-neutral-500 pt-0"
                      labelStyle="text-base font-medium text-neutral-800"
                      inputStyle="h-6"
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-3" />
              <div className="flex justify-between px-4">
                <Button
                  label="Cancel"
                  text
                  labelStyle="font-semibold text-base leading-5"
                  onClick={() => setOpen(!open)}
                />
                <Button
                  disabled={!newData.name.length}
                  label={loading ? <Spinner fillColor="fill-white" /> : 'Save'}
                  onClick={add}
                />
              </div>
            </div>
          </div>
          {data.length ? (
            <div className="my-10 flex w-full flex-col justify-between">
              <CommonTable
                columns={columns}
                data={data}
                setData={setData}
                parentColumns={parentColumns}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                setColumns={setColumns}
                sortingColumns={sortingColumns}
                viewRecordIconOrText={editIcon}
                openModalIcon={redTrash}
                link={{
                  name: category?.merchantCategory?.name,
                  href: `/merchant-categories/${createHashtag(
                    category?.merchantCategory?.name || ''
                  )}/`,
                  id: category?.merchantCategory?.id || '',
                }}
              />
            </div>
          ) : (
            <div className="flex h-[90%] flex-col items-center justify-center">
              <p className="text-center text-[32px] font-semibold capitalize leading-tight text-black">
                No active Subcategories
              </p>
              <p className="mb-16 mt-4 w-[392px] text-center text-base font-normal leading-tight text-neutral-500">
                All categories for your store will be listed here
              </p>
              <Button
                label="Add subcategory +"
                className={`w-inherit rounded-md px-[15px] ${
                  open ? 'border-greyish bg-greyish' : 'bg-mediumPurple'
                }`}
                labelStyle="text-white text-sm"
                onClick={() => setOpen(!open)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
