
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Actor": [
      "Admin",
      "Courier",
      "Customer",
      "Merchant"
    ],
    "CybersourceJwt": [
      "CybersourceDeviceCollectionJwt",
      "CybersourceStepupJwt"
    ],
    "Error": [
      "TransactionError",
      "ValidationError"
    ],
    "Event": [
      "DeliveryStatusUpdate",
      "PaymentRequestEvent",
      "SystemNotification",
      "TransactionEvent"
    ],
    "KycVerificationLog": [
      "IdentificationNumberLog",
      "LivenessLog",
      "PhoneNumberLog"
    ],
    "PaymentMethod": [
      "CreditCardPaymentMethod",
      "FreeMoneyDevPaymentMethod",
      "InterswitchBankAccountPaymentMethod",
      "PhoneNumberPaymentMethod"
    ],
    "Transaction": [
      "BillTransaction",
      "LoadCreditTransaction",
      "OrderBatchTransaction",
      "RefundTransaction",
      "TransferTransaction",
      "WithdrawTransaction"
    ]
  }
};
      export default result;
    