import React from 'react';

const Support = () => {
  return (
    <div className="p-7">
      <h1 className="text-2xl font-semibold">Support</h1>
    </div>
  );
};

export default Support;
