import {ReelsProductFieldsFragment} from '../../../generated';
import {cn} from '../../../utils/reusablefunctions';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';

type Props = {
  products?: ReelsProductFieldsFragment[];
  onClickAction?: (product: ReelsProductFieldsFragment) => void;
  remove?: boolean;
  omitActions?: boolean;
};

const shortenTxt = (txt: string) => {
  if (txt?.length > 5) return `${txt.substring(0, 5)}...`;
  return txt;
};
const titles = ['Product', 'Price (kes)', 'Items in stock'];

const defaultVariant = (
  product: ReelsProductFieldsFragment | undefined | null
) => product?.variants?.find((v) => v.default);

const ProductsDisplay = ({
  products,
  onClickAction,
  remove,
  omitActions,
}: Props) => {
  return (
    <div className="flex h-[75%] max-h-[450px] w-full flex-col overflow-auto rounded-[10px] border border-neutral-200">
      <div className=" sticky top-0 z-10 flex h-20 w-full items-center justify-start  bg-neutral-100 p-[15px]">
        {titles.map((name) => (
          <p
            key={name}
            className="text-md h-[40px]  flex-1 truncate font-semibold uppercase leading-tight text-neutral-800">
            {name}
          </p>
        ))}
        {!omitActions && <div className="flex-2 h-[38px] w-[135px]" />}
      </div>
      {products?.map((product) => (
        <div className="">
          <div
            key={product.id}
            className="flex h-[68px] w-full items-center justify-start   overflow-scroll border-b border-neutral-200 px-[15px] py-[15px]">
            <div className="flex flex-1 items-center gap-1">
              <img
                className="h-[35px] w-[58.48px] rounded object-cover"
                src={defaultVariant(product)?.images?.[0]?.small || ''}
              />
              <p className="truncate text-base font-medium leading-tight text-neutral-800">
                {shortenTxt(product.title || '')}
              </p>
            </div>
            <p className="flex-1 truncate  text-base font-medium leading-tight text-neutral-800">
              KES {formatMoney(defaultVariant(product)?.price || 0, 2)}
            </p>
            <p className="flex-1 truncate text-base font-medium leading-tight text-neutral-800">
              In stock
            </p>
            {!omitActions && (
              <button
                className={cn(
                  'flex-2 flex h-[38px] w-[135px] items-center justify-center gap-2.5 rounded-[10px] border border-cashiaBlue bg-cashiaBlue px-2.5 py-[11px]',
                  {'border-red-600 bg-red-600': remove}
                )}
                onClick={() => onClickAction?.(product)}>
                <p className="truncate text-center text-sm font-medium text-neutral-50">
                  {remove ? 'Remove item' : 'Add to Display'}
                </p>
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductsDisplay;
