import {useCallback, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useParams} from 'react-router-dom';

import Close from '../../../assets/icons/cross_black.svg';
import Button from '../../../components/common/Button';
import IconButton from '../../../components/common/IconButton';
import ImgWithText from '../../../components/common/ImgWithText';
import Spinner from '../../../components/common/Spinner';
import {
  ApprovalState,
  MerchantDetailsDocument,
  MerchantDetailsQuery,
  useChangeMerchantApprovalStateMutation,
} from '../../../generated';
import {merchantReadableStatus} from '../hooks/useGetMerchants';

type RowProps = {
  value?: ApprovalState | null;
};

const status = [
  {
    name: 'Active',
    des: 'Status when all forms of verifications are completed',
    color: 'bg-green-500',
    value: ApprovalState.Approved,
  },
  {
    name: 'Pending',
    des: 'Status when review is pending',
    color: 'bg-orange-500',
    value: ApprovalState.ReviewPending,
  },
  {
    name: 'Changes Required',
    des: 'Status when merchant is required to make changes',
    color: 'bg-orange-500',
    value: ApprovalState.ChangesRequired,
  },
  {
    name: 'Not in Review',
    des: 'The default status for new merchant',
    color: 'bg-orange-500',
    value: ApprovalState.NotInReview,
  },
  {
    name: 'Rejected',
    des: 'Status when merchant is rejected',
    color: 'bg-red-500',
    value: ApprovalState.Rejected,
  },
];

const AccountStatus = ({value}: RowProps) => {
  const {merchantId} = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<ApprovalState | string>();
  const [changeMerchantApprovalStateMutation, {loading}] =
    useChangeMerchantApprovalStateMutation();

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const save = useCallback(async () => {
    if (value === state) return;
    const res = await changeMerchantApprovalStateMutation({
      variables: {
        id: merchantId || '',
        changeMerchantApprovalStateInput: {
          state: state as ApprovalState,
        },
      },
      update: (cache, result) => {
        const variables = {
          id: merchantId,
        };
        const cached = cache.readQuery<MerchantDetailsQuery>({
          query: MerchantDetailsDocument,
          variables,
        });
        if (!cached) return;
        cache.writeQuery({
          query: MerchantDetailsDocument,
          variables,
          data: {
            ...cached,
            merchant: {
              ...cached.merchant,
              approvalState:
                result.data?.changeMerchantApprovalState?.approvalState,
            },
          },
        });
        toggle();
      },
    });
    if (res.errors?.length) {
      toggle();
      toast.error(`An error occured when updating wallet status`, {
        position: 'bottom-left',
      });
    }
    toggle();
  }, [changeMerchantApprovalStateMutation, merchantId, state, toggle, value]);

  useEffect(() => {
    if (!value) return;
    setState(value);
  }, [value]);

  return (
    <div className="flex justify-between border-b border-dividerGrey py-[20px]">
      {isOpen ? (
        <div className="flex w-full flex-col rounded-lg border border-dividerGrey ">
          <div className="flex flex-row items-center justify-between p-[20px]">
            <h6 className="mb-[10px] text-[18px] font-semibold">
              Account status
            </h6>
            <IconButton onClick={toggle} icon={Close} />
          </div>
          <div className="flex flex-col gap-5 px-7">
            {status.map((item, i) => (
              <div key={i} className="flex justify-start gap-4 align-top">
                <input
                  id="default-radio-1"
                  type="radio"
                  value={item.value}
                  checked={state === item.value}
                  name="default-radio"
                  className="h-6 w-6 cursor-pointer accent-black"
                  onChange={(e) => setState(e.target.value)}
                />
                <div className="flex justify-start gap-2 align-top">
                  <div className={`${item.color} mt-1 h-4 w-4 rounded-full`} />
                  <div className="flex flex-col">
                    <p className="text-base font-medium">{item.name}</p>
                    <p className="text-sm font-normal text-foggy">{item.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-[30px] flex basis-full justify-between border-t border-dividerGrey p-[20px] pt-[15px]">
            <Button onClick={toggle} text label="Cancel" />
            <Button
              label={loading ? <Spinner fillColor="fill-white" /> : 'Save'}
              labelStyle="font-semibold text-base leading-5"
              disabled={value === state}
              onClick={save}
            />
          </div>
        </div>
      ) : (
        <>
          <div>
            <h6 className="mb-[10px] text-[18px] font-semibold">
              Account Status
            </h6>
            <ImgWithText
              text={(value && merchantReadableStatus(value)[0]) || ''}
              url={(value && merchantReadableStatus(value)[1]) || ''}
              variant="circle"
            />
          </div>
          <Button
            onClick={toggle}
            labelStyle="text-mediumPurple"
            label="Edit"
            text
          />
        </>
      )}
    </div>
  );
};

export default AccountStatus;
