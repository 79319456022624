import {Toaster} from 'react-hot-toast';
import {Route, Routes, Navigate} from 'react-router-dom';

import {
  ApprovalState,
  CourierType,
  DocumentStatus,
  TransactionStatus,
  WalletActivationStatus,
} from '../generated';
import CustomerLayout from '../layouts/CustomerLayout';
import CustomerTransactionsLayout from '../layouts/CustomerTransactionsLayout';
import CustomerViewLayout from '../layouts/CustomerViewLayout';
import DriversAndRidersLayout from '../layouts/DriversAndRidersLayout';
import DriversAndRidersViewLayout from '../layouts/DriversAndRidersViewLayout';
import MainLayout from '../layouts/MainLayout';
import MerchantDetailsLayout from '../layouts/MerchantDetailsLayout';
import MerchantDocumentsLayout from '../layouts/MerchantDocumentsLayout';
import MerchantTransactionsLayout from '../layouts/MerchantTransactionsLayout.';
import MerchantsLayout from '../layouts/MerchantsLayout';
import UserVerificationLayout from '../layouts/UserVerificationLayout';
import Login from '../pages/Login';
import Couriers from '../pages/couriers';
import CourierAccountDetails from '../pages/couriers/pages/CourierAccountDetails';
import Customers from '../pages/customers';
import AccountDetails from '../pages/customers/pages/AccountDetails';
import Transactions from '../pages/customers/pages/Transactions';
import Dashboard from '../pages/dashboard/Dashboard';
import AddMerchantCategory from '../pages/merchant_categories/AddMerchantCategory';
import MerchantCategories from '../pages/merchant_categories/MerchantCategories';
import NestedSubCategories from '../pages/merchant_categories/NestedSubCategories';
import {SubCategories} from '../pages/merchant_categories/SubCategories';
import Merchants from '../pages/merchants';
import ReelsAnalytics from '../pages/merchants/pages/ReelsAnalytics';
import MerchantDetails from '../pages/merchants/pages/about';
import ProductDetails from '../pages/merchants/pages/productDetails';
import VariantList from '../pages/merchants/pages/variantList';
import MerchantDocuments from '../pages/merchants/view/Documents';
import MerchantTransactions from '../pages/merchants/view/Transactions';
import VerifyDocument from '../pages/merchants/view/VerifyDocument';
import OperatingHours from '../pages/merchants/view/hours';
import ViewMerchantLocations from '../pages/merchants/view/location';
import ViewMerchantProducts from '../pages/merchants/view/products';
import Reels from '../pages/merchants/view/reels';
import Support from '../pages/support/Support';
import UserVerification from '../pages/user-verification';
import UserVerificationDetails from '../pages/user-verification/view/user';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="merchants" element={<MerchantsLayout />}>
            <Route index element={<Merchants />} />
            <Route
              path="active"
              element={<Merchants approvalStates={[ApprovalState.Approved]} />}
            />
            <Route
              path="pending"
              element={
                <Merchants
                  approvalStates={[
                    ApprovalState.ReviewPending,
                    ApprovalState.ChangesRequired,
                    ApprovalState.NotInReview,
                  ]}
                />
              }
            />
            <Route
              path="deactivated"
              element={<Merchants approvalStates={[ApprovalState.Rejected]} />}
            />
          </Route>

          <Route path="/merchants/merchant" element={<MerchantDetailsLayout />}>
            <Route
              path="view/location/:merchantId"
              element={<ViewMerchantLocations />}
            />
            <Route
              index
              path="view/products/:merchantId"
              element={<ViewMerchantProducts />}
            />
            <Route
              path="view/products/productDetails/:merchantId/:productId"
              element={<ProductDetails />}
            />
            <Route
              path="view/products/productDetails/:merchantId/:productId/variants"
              element={<VariantList />}
            />

            <Route
              path="/merchants/merchant/view/reels/:merchantId"
              element={<Reels />}>
              <Route path="unrestricted" element={<Reels />} />
              <Route path="restricted" element={<Reels />} />
            </Route>

            <Route
              path="/merchants/merchant/view/reels/analytic/:merchantId/:reelId"
              element={<ReelsAnalytics />}
            />
            <Route
              path="view/about/:merchantId"
              element={<MerchantDetails />}
            />
            <Route
              path="documents/:merchantId"
              element={<MerchantDocumentsLayout />}>
              <Route index element={<MerchantDocuments />} />
              <Route
                path="pending"
                element={<MerchantDocuments status={DocumentStatus.Pending} />}
              />
              <Route
                path="approved"
                element={<MerchantDocuments status={DocumentStatus.Approved} />}
              />
              <Route
                path="denied"
                element={<MerchantDocuments status={DocumentStatus.Denied} />}
              />
            </Route>
            <Route path="hours/:merchantId" element={<OperatingHours />} />
            <Route
              path="transactions/:merchantId"
              element={<MerchantTransactionsLayout />}>
              <Route index element={<MerchantTransactions />} />
              <Route
                path="successful"
                element={
                  <MerchantTransactions status={TransactionStatus.Success} />
                }
              />
              <Route
                path="pending"
                element={
                  <MerchantTransactions status={TransactionStatus.Pending} />
                }
              />
              <Route
                path="failed"
                element={
                  <MerchantTransactions status={TransactionStatus.Failed} />
                }
              />
            </Route>
          </Route>

          <Route path="customers" element={<CustomerLayout />}>
            <Route index element={<Customers />} />
            <Route
              path="verified"
              element={
                <Customers walletStatus={[WalletActivationStatus.Verified]} />
              }
            />
            <Route
              path="pending"
              element={
                <Customers walletStatus={[WalletActivationStatus.Pending]} />
              }
            />
            <Route
              path="in-progress"
              element={
                <Customers walletStatus={[WalletActivationStatus.InProgress]} />
              }
            />
          </Route>

          <Route path="customer/:id" element={<CustomerViewLayout />}>
            <Route index path="account" element={<AccountDetails />} />
            <Route path="transactions" element={<CustomerTransactionsLayout />}>
              <Route index element={<Transactions />} />
              <Route
                path="successful"
                element={<Transactions status={TransactionStatus.Success} />}
              />
              <Route
                path="pending"
                element={<Transactions status={TransactionStatus.Pending} />}
              />
              <Route
                path="failed"
                element={<Transactions status={TransactionStatus.Failed} />}
              />
            </Route>
          </Route>

          <Route path="couriers" element={<DriversAndRidersLayout />}>
            <Route index element={<Couriers key="all" />} />
            <Route
              path="drivers"
              element={
                <Couriers
                  courierType={CourierType.Driver}
                  key={CourierType.Driver}
                />
              }
            />
            <Route
              path="riders"
              element={
                <Couriers
                  courierType={CourierType.Rider}
                  key={CourierType.Rider}
                />
              }
            />
          </Route>

          <Route path="courier/:id" element={<DriversAndRidersViewLayout />}>
            <Route index path="account" element={<CourierAccountDetails />} />
          </Route>

          <Route path="user-verification" element={<UserVerificationLayout />}>
            <Route index element={<UserVerification />} />
          </Route>
          <Route
            path="user-verification/user/:userId"
            element={<UserVerificationDetails />}
          />

          <Route path="merchant-categories">
            <Route index element={<MerchantCategories />} />
            <Route path=":merchantCategory/:id" element={<SubCategories />} />
            <Route path=":merchantCategory/:subMerchantCategory/:id">
              <Route index element={<NestedSubCategories active />} />
              <Route
                path="disabled"
                element={<NestedSubCategories active={false} />}
              />
            </Route>
          </Route>
          <Route
            path="merchant-categories/add"
            element={<AddMerchantCategory />}
          />
          <Route path="support" element={<Support />} />
          <Route
            path="merchants/verify-documents/:merchantId/:documentId"
            element={<VerifyDocument />}
          />
        </Route>
      </Routes>
      <Toaster />
    </>
  );
};
export default AppRoutes;
