import {ApolloCache, FetchResult} from '@apollo/client';
import {useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import useGetNestedSubCategories from './hooks/useGetNestedSubCategories';
// import ApproveIcon from '../../assets/icons/approve.svg';
import ArrowDown from '../../assets/icons/arrow-down.svg';
import ArrowLeft from '../../assets/icons/black-arrow-left.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import editIcon from '../../assets/icons/edit-icon.svg';
import redTrash from '../../assets/icons/red-trash.svg';
import AutoComplete, {
  AutoCompleteValue,
} from '../../components/common/AutoComplete';
import Button from '../../components/common/Button';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import {Tab} from '../../components/common/Tabs';
import TextInput from '../../components/common/TextInput';
import CommonTable from '../../components/common/table';
import {
  AddOrUpdateMerchantCategoryMutation,
  NestedMerchantCategoryDocument,
  NestedMerchantCategoryQuery,
  useAddOrUpdateMerchantCategoryMutation,
} from '../../generated';
import {createHashtag} from '../../utils/reusablefunctions';

interface LocationState {
  name: string;
  id: string;
}

type Props = {
  active: boolean;
};

const NestedSubCategories = ({active}: Props) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {name: parentCategoryName, id: parentCategoryId} = useLocation()
    .state as LocationState;

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [nestedCategory, setNestedCategory] = useState<AutoCompleteValue>();
  const {
    category,
    columns,
    data,
    setData,
    selectedIds,
    setSelectedIds,
    setColumns,
    autocompleteValues,
    sortingColumns,
    loading: dataLoading,
  } = useGetNestedSubCategories(id || '', active);
  const [addOrUpdateMerchantCategory, {loading}] =
    useAddOrUpdateMerchantCategoryMutation();

  const breadcrumbs = [
    {name: 'Merchants categories', location: '/merchant-categories'},
    {
      name: parentCategoryName,
      location: `/merchant-categories/${createHashtag(
        parentCategoryName
      )}/${parentCategoryId}`,
    },
    {
      name: category?.merchantCategory?.name || '',
    },
  ];

  const navState = {
    name: parentCategoryName || 'sub-category',
    id: parentCategoryId || '',
  };
  const link = `/merchant-categories/${createHashtag(
    parentCategoryName
  )}/${createHashtag(category?.merchantCategory?.name || '')}/${id || ''}`;

  const tabs: Tab[] = [
    {name: 'Active', link, navState},
    {name: 'Disabled', link: `${link}/disabled`, navState},
  ];

  const verifyData = useCallback(
    () =>
      Boolean(nestedCategory?.id ? name.length : nestedCategory?.name.length),
    [name.length, nestedCategory?.id, nestedCategory?.name.length]
  );

  const defaultReset = useCallback(() => {
    setOpen(!open);
    setNestedCategory(undefined);
    setName('');
  }, [open]);

  const variables = useMemo(
    () => ({
      id: id || '',
      active,
    }),
    [active, id]
  );
  const updateNestedCategory = useCallback(
    (
      cache: ApolloCache<unknown>,
      result: Omit<FetchResult<AddOrUpdateMerchantCategoryMutation>, 'context'>,
      nestedCategoryId?: string | null
    ) => {
      const cached = cache.readQuery<NestedMerchantCategoryQuery>({
        query: NestedMerchantCategoryDocument,
        variables,
      });
      if (!cached?.merchantCategory) return;
      cache.writeQuery({
        query: NestedMerchantCategoryDocument,
        variables,
        data: {
          ...cached,
          merchantCategory: {
            ...cached.merchantCategory,
            children: [
              ...(cached?.merchantCategory?.children || []).map((item) =>
                item.id === nestedCategoryId
                  ? {
                      ...item,
                      children: [
                        result.data?.addOrUpdateMerchantCategory,
                        ...(item.children || []),
                      ],
                    }
                  : item
              ),
            ],
          },
        },
      });
    },
    [variables]
  );

  const add = useCallback(async () => {
    if (!verifyData()) return;
    if (!nestedCategory?.id) {
      const res = await addOrUpdateMerchantCategory({
        variables: {
          input: {
            parentId: id,
            name: nestedCategory?.name || '',
          },
        },
        update: (cache, result) => {
          const cached = cache.readQuery<NestedMerchantCategoryQuery>({
            query: NestedMerchantCategoryDocument,
            variables,
          });
          if (!cached?.merchantCategory) return;
          cache.writeQuery({
            query: NestedMerchantCategoryDocument,
            variables,
            data: {
              ...cached,
              merchantCategory: {
                ...cached.merchantCategory,
                children: [
                  result.data?.addOrUpdateMerchantCategory,
                  ...(cached?.merchantCategory?.children || []),
                ],
              },
            },
          });
        },
      });
      if (res.data?.addOrUpdateMerchantCategory && name) {
        await addOrUpdateMerchantCategory({
          variables: {
            input: {
              parentId: res.data?.addOrUpdateMerchantCategory.id,
              name: name,
            },
          },
          update: (cache, result) => {
            updateNestedCategory(
              cache,
              result,
              res.data?.addOrUpdateMerchantCategory.id
            );
          },
        });
      }
      if (res.data) defaultReset();
    }

    if (nestedCategory?.id && name) {
      const res = await addOrUpdateMerchantCategory({
        variables: {
          input: {
            parentId: nestedCategory?.id,
            name,
          },
        },
        update: (cache, result) => {
          updateNestedCategory(cache, result, nestedCategory?.id);
        },
      });
      if (res.data) defaultReset();
    }
  }, [
    addOrUpdateMerchantCategory,
    defaultReset,
    id,
    name,
    nestedCategory?.id,
    nestedCategory?.name,
    updateNestedCategory,
    variables,
    verifyData,
  ]);

  // const Status = () => (
  //   <div className="flex items-center gap-1">
  //     <img src={ApproveIcon} className="h-6 w-6" />
  //     <p className="text-base font-medium leading-tight text-neutral-800">
  //       Active
  //     </p>
  //   </div>
  // );

  if (dataLoading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }
  return (
    <div className="flex h-full flex-col">
      <div className="flex-2">
        <Navbar
          title={category?.merchantCategory?.name || ''}
          placeholder="Find a category..."
          breadcrumbs={breadcrumbs}
          tabs={tabs}
          tabContainerStyles="ml-[175px]"
          tabLabelStyles="text-base leading-tight"
          // status={<Status />}
          omitSearch
        />
      </div>
      <div className="flex w-full flex-1 overflow-y-scroll scroll-smooth px-9 py-6 pb-0">
        <div className="w-[220px]">
          <h3
            className="flex cursor-pointer gap-1"
            onClick={() =>
              navigate(
                `/merchant-categories/${createHashtag(
                  parentCategoryName
                )}/${parentCategoryId}`
              )
            }>
            <img src={ArrowLeft} />
            <p className="text-base font-semibold text-neutral-800">
              subcategories
            </p>
          </h3>
        </div>
        <div className="w-full">
          <div className="flex justify-between">
            {active && (
              <Button
                label="Add nested category +"
                className={`w-inherit rounded-md px-[15px] ${
                  open ? 'border-greyish bg-greyish' : 'bg-mediumPurple'
                }`}
                labelStyle="text-white text-sm"
                onClick={defaultReset}
              />
            )}
          </div>
          {open && (
            <div className="overflow-hidden">
              <div className="relative mt-4 flex w-full flex-col gap-3 rounded-lg border border-cashia-grey py-5 pr-3">
                <img
                  src={closeIcon}
                  className="absolute right-6 top-6 cursor-pointer"
                  onClick={defaultReset}
                />
                <h1 className="mb-3 pl-8 text-xl font-semibold leading-snug text-neutral-800">
                  Add subcategories
                </h1>
                <div className="flex gap-3 px-4">
                  <div className="flex w-full flex-col gap-5">
                    <div className="flex flex-col px-4">
                      <TextInput
                        label="Name"
                        value={name}
                        description="This name is how the subcategory will appear on all
                      platforms"
                        descriptionStyle="text-[13px] font-medium text-neutral-500 pt-0"
                        labelStyle="text-base font-medium text-neutral-800"
                        inputStyle="h-6"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col px-4">
                      <AutoComplete
                        label="Nested subcategory"
                        value={nestedCategory}
                        description="Assign a parent term to create a hierarchy."
                        descriptionStyle="text-[13px] font-medium text-neutral-500 pt-0"
                        labelStyle="text-base font-medium text-neutral-800"
                        inputStyle="h-6"
                        setValue={setNestedCategory}
                        autocompleteValues={autocompleteValues}
                        placeholder="Select category / Type in a New Category"
                        endAdornment={<img src={ArrowDown} />}
                      />
                    </div>
                  </div>
                </div>
                <hr className="mt-3" />
                <div className="flex justify-between px-4">
                  <Button
                    label="Cancel"
                    text
                    labelStyle="font-semibold text-base leading-5"
                    onClick={defaultReset}
                  />
                  <Button
                    disabled={!verifyData()}
                    label={
                      loading ? <Spinner fillColor="fill-white" /> : 'Add +'
                    }
                    onClick={add}
                  />
                </div>
              </div>
            </div>
          )}
          {data.length ? (
            <div className="my-10 flex w-full flex-col justify-between">
              <CommonTable
                columns={columns}
                data={data}
                setData={setData}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                setColumns={setColumns}
                sortingColumns={sortingColumns}
                viewRecordIconOrText={editIcon}
                openModalIcon={redTrash}
                textstyle="text-lg font-semibold text-neutral-800"
              />
            </div>
          ) : (
            <div className="flex h-[90%] flex-col items-center justify-center">
              <p className="text-center text-[32px] font-semibold capitalize leading-tight text-black">
                No {active ? 'active' : 'disabled'} Subcategories
              </p>
              <p className="mb-8 mt-4 w-[392px] text-center text-base font-normal leading-tight text-neutral-500">
                All categories will be listed here
              </p>
              {active && (
                <Button
                  label="Add nested category +"
                  className={`w-inherit rounded-md px-[15px] ${
                    open ? 'border-greyish bg-greyish' : 'bg-mediumPurple'
                  }`}
                  labelStyle="text-white text-sm"
                  onClick={() => setOpen(!open)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NestedSubCategories;
