import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import React, {useMemo, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {useNavigate} from 'react-router-dom';

import CarIcon from '../../assets/icons/car-dashboard.svg';
// import exportIcon from '../../assets/icons/export.svg';
import filterIconLight from '../../assets/icons/filter-icon-light.svg';
import CustomersIcon from '../../assets/icons/profile-2user.svg';
import ShopIcon from '../../assets/icons/shop-da.svg';
import DropDown from '../../components/common/DropDown';
import IconButton from '../../components/common/IconButton';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import {
  useDeliveryActivityQuery,
  DatePreset as EarningsTimePeriod,
  TimeSeriesPeriod,
  useEarningsQuery,
  useMemberStatisticsQuery,
  DeliveryActivity,
} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  datasetStrokeWidth: 3,
  pointDotStrokeWidth: 4,
  scales: {
    y: {
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        display: true,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const zeroPaddedString = (s: number) => {
  if (s < 10) {
    return `0${s}`;
  }
  return `${s}`;
};

const getTodayStart = () => {
  const dateToday = new Date();
  const startD = `${dateToday.getFullYear()}-${zeroPaddedString(
    dateToday.getMonth() + 1
  )}-${zeroPaddedString(dateToday.getDate())}T00:00:00Z`;
  return new Date(startD).toISOString();
};

const Dashboard = () => {
  const {data: stats, loading: statsLoading} = useMemberStatisticsQuery({
    variables: {},
  });
  const [startDate, setStartDate] = useState(getTodayStart());
  const [endDate] = useState(new Date().toISOString());
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    EarningsTimePeriod.Today
  );

  const {data: deliveryData, loading: deliveryLoading} =
    useDeliveryActivityQuery({
      variables: {
        input: {
          date: {
            start: startDate,
            end: endDate,
          },
          datePreset: selectedTimePeriod,
          timeSeriesConfig: {
            period:
              selectedTimePeriod === EarningsTimePeriod.ThisWeek ||
              selectedTimePeriod === EarningsTimePeriod.ThisMonth ||
              selectedTimePeriod === EarningsTimePeriod.Last_7Days
                ? TimeSeriesPeriod.Days
                : selectedTimePeriod === EarningsTimePeriod.ThisQuarter ||
                  selectedTimePeriod === EarningsTimePeriod.ThisYear ||
                  selectedTimePeriod === EarningsTimePeriod.Last_4Months ||
                  selectedTimePeriod === EarningsTimePeriod.LastYear
                ? TimeSeriesPeriod.Months
                : selectedTimePeriod === EarningsTimePeriod.AllTime
                ? TimeSeriesPeriod.Years
                : TimeSeriesPeriod.Hours,
            step: 1,
          },
        },
      },
    });

  const navigate = useNavigate();

  const data = useMemo(() => {
    const labelName: string[] = [];
    const deliveryCount: number[] = [];
    let total = 0;

    if (deliveryData != undefined) {
      deliveryData.deliveryActivity.map((e: DeliveryActivity) => {
        labelName.push(
          formatDate(e.timeSeriesInterval.start as Date, {
            withShortMonth:
              selectedTimePeriod === EarningsTimePeriod.ThisQuarter ||
              selectedTimePeriod === EarningsTimePeriod.ThisYear,
            withTime: selectedTimePeriod === EarningsTimePeriod.Today,
            hour12: selectedTimePeriod === EarningsTimePeriod.Today,
            withYear:
              selectedTimePeriod === EarningsTimePeriod.ThisQuarter ||
              selectedTimePeriod === EarningsTimePeriod.ThisYear,
            withDate:
              selectedTimePeriod === EarningsTimePeriod.ThisWeek ||
              selectedTimePeriod === EarningsTimePeriod.ThisMonth,
          })
        );
        deliveryCount.push(e.count);
        total += e.count;
      });
    }

    const dataset1 = {
      labels: labelName,
      datasets: [
        {
          label: 'Deliveries',
          data: deliveryCount,
          borderColor: '#0AE1EF',
          backgroundColor: '#0AE1EF',
          fill: true,
          tension: 0.4,
        },
      ],
    };
    return {data: dataset1, total};
  }, [deliveryData, selectedTimePeriod]);

  const {data: earnings, loading: earningsLoading} = useEarningsQuery({
    variables: {
      input: {
        datePreset: EarningsTimePeriod.AllTime,
      },
    },
  });

  const {data: earningsToday, loading: earningsTodayLoading} = useEarningsQuery(
    {
      variables: {
        input: {
          datePreset: EarningsTimePeriod.Today,
        },
      },
    }
  );

  const deliverTimePeriod = (key: Partial<EarningsTimePeriod>) => {
    return {
      [EarningsTimePeriod.Today]: 'Today',
      [EarningsTimePeriod.ThisWeek]: 'This Week',
      [EarningsTimePeriod.ThisMonth]: 'This Month',
      [EarningsTimePeriod.Last_4Months]: 'Last 4 Months',
      [EarningsTimePeriod.Last_7Days]: 'Last 7 Days',
      [EarningsTimePeriod.LastYear]: 'Last Year',
      [EarningsTimePeriod.ThisQuarter]: 'This Quarter',
      [EarningsTimePeriod.ThisYear]: 'This Year',
      [EarningsTimePeriod.AllTime]: 'All Time',
    }[key];
  };

  const onPeriodChange = (period: EarningsTimePeriod) => {
    setSelectedTimePeriod(period);
    const now = new Date();

    setStartDate(now.toISOString());
  };

  return (
    <div className="flex h-full flex-col overflow-y-scroll">
      <Navbar
        title={
          <span>
            <p>
              Good morning, <span className=" font-bold"> Admin User!</span>
            </p>
            <div className="mb-3">
              <p className="mt-3 w-[70%] text-base font-normal">
                Welcome to the system administrator dashboard. Here you will
                find the important sections of systems and diagnostics. You will
                also be able to edit and manage all aspects of the system.
              </p>
            </div>
          </span>
        }
        omitSearch={true}
        isDashboard={false}
        placeholder="dashboard"
        status={false}
      />
      <div className=" mb-0 flex-col p-9">
        <div className="mb-4 flex w-[90%] flex-col rounded-lg p-3 shadow-[0_0_10px_0px_rgba(0,0,0,0.3)]">
          <h1 className="font-semibold">Member Statistics</h1>
          <div className="flex flex-row justify-between">
            <div
              className="flex cursor-pointer flex-row gap-1"
              onClick={() => navigate('../merchants')}>
              <span className="flex h-[65px] w-[65px] items-center justify-center rounded-full bg-lightBlue">
                <img src={ShopIcon} alt="" />
              </span>
              <div className="flex flex-col">
                <h1 className="flex items-center text-[34px] font-bold">
                  {stats?.memberStatistics.merchantCount.all}
                </h1>
                <h1 className="flex items-center">Merchants</h1>
              </div>
            </div>
            <div
              className="flex cursor-pointer flex-row gap-1"
              onClick={() => navigate('../customers')}>
              <span className="flex h-[65px] w-[65px] items-center justify-center rounded-full bg-lightPink">
                <img src={CustomersIcon} alt="" />
              </span>
              <div className="flex flex-col">
                <h1 className="flex items-center text-[34px] font-bold">
                  {stats?.memberStatistics.customerCount.all}
                </h1>
                <h1 className="flex items-center">Customers</h1>
              </div>
            </div>
            <div
              className="flex cursor-pointer flex-row gap-1"
              onClick={() => navigate('../couriers')}>
              <span className="flex h-[65px] w-[65px] items-center justify-center rounded-full bg-lightCyan">
                <img src={CarIcon} alt="" />
              </span>
              <div className="flex flex-col">
                <h1 className="flex items-center text-[34px] font-bold">
                  {stats?.memberStatistics.courierCount.all}
                </h1>
                <h1 className="flex items-center">Drivers &amp; Riders</h1>
              </div>
            </div>
          </div>
        </div>

        {statsLoading ||
        deliveryLoading ||
        earningsLoading ||
        earningsTodayLoading ? (
          <div className="flex h-full w-full flex-col justify-center">
            <Spinner width={45} height={45} />
          </div>
        ) : (
          <div className="flex w-[90%] flex-col rounded-xl shadow-[0_0_10px_0px_rgba(0,0,0,0.3)]">
            <div className="flex flex-row justify-between">
              <h1 className="p-4 text-[20px] font-semibold">
                Total deliveries {deliverTimePeriod(selectedTimePeriod)}
                <br />
                <span className="text-[34px] font-bold">{data.total}</span>
              </h1>
              <DropDown
                dropDownPosition="right"
                className="m-6 flex"
                persistent
                actionElement={
                  <div className="m-6 flex cursor-pointer gap-2 rounded-md border-2 p-2 hover:border-purple-600">
                    <IconButton className="pt-2" icon={filterIconLight} />
                    <p className="text-[#909090] hover:text-purple-700">
                      {deliverTimePeriod(selectedTimePeriod)}
                    </p>
                  </div>
                }>
                <div className="flex w-[200px] flex-col rounded-lg bg-white p-2 text-sm text-gray-700">
                  <p className="flex justify-end text-xs text-[#909090]">
                    Filters:
                  </p>
                  {Object.values(EarningsTimePeriod).map((timePeriod, i) => (
                    <div
                      className="flex cursor-pointer  p-4"
                      onClick={() => onPeriodChange(timePeriod)}>
                      <p className="text-sm font-medium normal-case" key={i}>
                        {deliverTimePeriod(timePeriod)}
                      </p>
                    </div>
                  ))}
                </div>
              </DropDown>
            </div>

            <Line options={options} data={data.data} />

            <div />
          </div>
        )}

        <div className="mb-6 mt-9 flex w-[90%] justify-between">
          <div className="flex w-[47%] flex-col rounded-lg shadow-xl">
            <div className="flex flex-row justify-between p-3">
              <h1 className="flex text-lg font-semibold">Earnings today</h1>
              {/* <img className="flex cursor-pointer" src={exportIcon} alt="" /> */}
            </div>
            <div className="flex flex-row border-t-2 border-slate-200">
              <div className="h-[98px] p-3">
                <p className="text-sm font-normal text-slate-400">
                  Total amount
                </p>
                <p className="text-base font-bold text-brightGreen">
                  {formatMoney(earningsToday?.earnings.total || 0)}
                </p>
              </div>
              <div className="h-[98px] border-l-2 border-slate-200 p-3">
                <p className="w-auto text-sm font-normal text-slate-400">
                  Outstanding amount
                </p>
                <p className="text-base font-bold text-brightRed">
                  {formatMoney(earningsToday?.earnings.pending || 0)}
                </p>
              </div>
              <div className="h-[98px] border-l-2 border-slate-200 p-3">
                <p className="text-sm font-normal text-slate-400">
                  Pending amount
                </p>
                <p className="text-base font-bold text-mildOrange">
                  {formatMoney(earningsToday?.earnings.failed || 0)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex w-[47%] flex-col rounded-xl shadow-xl">
            <div className="flex flex-row justify-between p-3">
              <h1 className="flex text-lg font-semibold">All time Earnings</h1>
              {/* <img className="flex cursor-pointer" src={exportIcon} alt="" /> */}
            </div>
            <div className="flex flex-row border-t-2 border-slate-200">
              <div className="h-[98px] p-3">
                <p className="text-sm font-normal text-slate-400">
                  Total amount
                </p>
                <p className="text-lg font-bold text-brightGreen">
                  {formatMoney(earnings?.earnings.total || 0)}
                </p>
              </div>
              <div className="h-[98px] border-l-2 border-slate-200 p-3">
                <p className="w-auto text-sm font-normal text-slate-400">
                  Outstanding amount
                </p>
                <p className="text-lg font-bold text-brightRed">
                  {formatMoney(earnings?.earnings.pending || 0.0)}
                </p>
              </div>
              <div className="h-[98px] border-l-2 border-slate-200 p-3">
                <p className="text-sm font-normal text-slate-400">
                  Pending amount
                </p>

                <p className="text-lg font-bold text-mildOrange">
                  {formatMoney(earnings?.earnings.failed || 0.0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
