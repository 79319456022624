import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import motorBike from '../../../assets/icons/motobike.svg';
import {EditAccordion} from '../../../components/common/EditAccordion';
import Spinner from '../../../components/common/Spinner';
import {useProductQuery} from '../../../generated';
import {useMyContext} from '../../../layouts/MerchantDetailsLayout';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';

const ProductDetails = () => {
  const {onPageChange, setShowRestrictProduct} = useMyContext();
  const {productId, merchantId} = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setShowRestrictProduct?.(true);
  }, [setShowRestrictProduct]);

  const {
    data,
    loading: getProductLoading,
    error: getProductError,
  } = useProductQuery({
    variables: {
      id: productId || '',
    },
  });

  const handleViewAll = () => {
    if (productId && merchantId) {
      navigate(
        `/merchants/merchant/view/products/productDetails/${merchantId}/${productId}/variants`
      );
    } else {
      return;
    }
  };

  const [displayCount] = useState(6);
  const variants = data?.product?.variants || [];

  useEffect(() => {
    data?.product?.title && onPageChange(data?.product?.title);
  }, [data?.product?.title, onPageChange]);

  const closedVariants = () => {
    return (
      <div>
        <div className="flex flex-row flex-wrap gap-3 overflow-x-scroll pt-[16px]">
          {variants.slice(0, displayCount).map((num) => (
            <div className="flex h-[120px] w-[330px] rounded-lg border-2 text-sm">
              <div className="flex items-center gap-2 pl-2">
                <div className="">
                  <img
                    className="h-24 w-24"
                    src={num.images?.[0]?.large || ''}
                    alt=""
                  />
                </div>

                <div className="flex flex-col text-base font-normal">
                  <div>{num.product?.title || 'Variant title unspecified'}</div>
                  <p className="text-[13px] text-smoothRed">
                    Ksh{' '}
                    {(num.price?.amountInCents &&
                      formatMoney(num.price?.amountInCents)) ||
                      'Price'}
                  </p>
                  <p className="text-base text-slate-400">
                    {num.title || 'Color not specified'}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const closedCategory = () => {
    return (
      <div className="flex">
        <div className="font-normal">
          {data?.product?.categories.map((category) => (
            <div>
              <label>{category.name}</label>
              <ul className="ml-4 list-disc">
                <ul className="ml-4 list-disc">
                  {category.children?.map((child) => (
                    <li>{child?.name}</li>
                  ))}
                </ul>
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const closedproductSize = () => {
    return (
      <div className="flex h-24 w-24 items-center justify-center rounded-lg border shadow-md">
        <img src={motorBike} alt="" />
      </div>
    );
  };
  const closedPictures = () => {
    return (
      <div className="no-scrollbar mb-16 flex h-[170px] w-full flex-nowrap gap-3 overflow-x-scroll bg-white">
        {data?.product?.variants?.[0].images?.map((item) =>
          item.large ? (
            <img
              className="rounded object-cover"
              src={item.large || ''}
              alt=""
            />
          ) : (
            'Image not available'
          )
        )}
      </div>
    );
  };
  const closedPrice = () => {
    return (
      <div className="flex flex-col">
        <p className="text-base font-normal">
          Base Price: KES{' '}
          {data?.product?.variants?.[0].price?.amountInCents &&
            formatMoney(data?.product?.variants?.[0].price?.amountInCents)}
        </p>
      </div>
    );
  };
  const closedTitle = () => {
    return (
      <div className="flex flex-col">
        <p className="text-base font-normal">
          {data?.product?.title || 'No Name Available'}
        </p>
      </div>
    );
  };
  const closedDescription = () => {
    return (
      <div className="flex flex-col">
        <p className="text-base font-normal">
          {data?.product?.variants?.[0]?.description ||
            'No Description Available'}
        </p>
      </div>
    );
  };
  const closedTags = () => {
    return (
      <div className="flex flex-col">
        <p className="text-t font-medium text-slate-400">
          Hastags are currently not supported
        </p>
      </div>
    );
  };
  const closedStock = () => {
    return (
      <div className="flex flex-col">
        <p className="text-t font-medium text-slate-400">
          This feature is currently under maintenance
        </p>
      </div>
    );
  };

  if (getProductLoading) {
    return (
      <div className="mt-3 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (getProductError) {
    return (
      <h1 className="mt-2 rounded-lg bg-red-500 p-6 text-white">
        Error: {getProductError.message}
      </h1>
    );
  }

  return (
    <div className="mr-[30px] flex basis-full flex-col overflow-scroll">
      <h1 className="pb-4 text-[24px] font-semibold">{data?.product?.title}</h1>
      <p className="font-normal">
        Customer can see this info before a purchase
      </p>
      <EditAccordion closedChildren={closedTitle()} title="Title" />
      <EditAccordion closedChildren={closedDescription()} title="Description" />
      <EditAccordion closedChildren={closedCategory()} title="Category" />
      <EditAccordion closedChildren={closedTags()} title="Tags" />
      <EditAccordion
        closedChildren={closedVariants()}
        title="Variants in stock"
        buttonLabel={variants.length > displayCount ? 'View All Variants' : ' '}
        onButtonClick={handleViewAll}
      />

      <EditAccordion closedChildren={closedStock()} title="Items in stock" />
      <EditAccordion closedChildren={closedPrice()} title="Price" />
      <EditAccordion
        closedChildren={closedproductSize()}
        title="Products size"
      />
      <EditAccordion closedChildren={closedPictures()} title="Pictures" />
    </div>
  );
};

export default ProductDetails;
