import {ReactNode, useCallback, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';

export type NavTreeType = {
  name?: string;
  link?: string;
  icon?: ReactNode | string;
  children?: {
    name: string;
    link: string;
  }[];
};

type Props = {
  tree: NavTreeType[];
};

const TreeNav = ({tree}: Props) => {
  const {pathname, hash} = useLocation();
  const hasChildren = useMemo(
    () => tree.some((item) => item.children && item.children?.length > 0),
    [tree]
  );

  const isChildActive = useCallback(
    (children: {name: string; link: string}[]) => {
      return children.some((child) => pathname.includes(child.link));
    },
    [pathname]
  );

  const isActive = useCallback(
    (path: string) => {
      if (hash) return path === `${pathname}${hash}`;
      else return pathname.includes(path);
    },
    [hash, pathname]
  );

  return (
    <div className="flex basis-[25%] select-none px-[30px]">
      {hasChildren ? (
        <ul className="flex basis-full flex-col">
          {tree.map((item, index) => (
            <div key={`parent-${index}`}>
              <li
                key={index}
                className={`flex  ${
                  item.name ? 'h-[44px]' : ''
                } items-center rounded-lg ${
                  (!!item.children?.length && isChildActive(item.children)) ||
                  isActive(item.link || '')
                    ? 'bg-offWhite'
                    : ''
                } px-[15px]`}>
                <Link to={item.link || ''}>{item.name}</Link>
              </li>
              {!!item.children?.length &&
                isChildActive(item.children) &&
                item.children &&
                item.children?.length && (
                  <ul
                    key={`child-${index}`}
                    className="my-[10px] ml-[15px] flex flex-col gap-[20px] border-l-half font-medium text-foggy ">
                    {item.children.map((child, position) => (
                      <li
                        key={position}
                        className={`peer px-[10px] pl-[15px] ${
                          isActive(child.link)
                            ? 'border-l-[3px] border-mediumPurple font-semibold text-mediumPurple'
                            : ''
                        } `}>
                        <Link to={child.link}>{child.name}</Link>
                      </li>
                    ))}
                  </ul>
                )}
            </div>
          ))}
        </ul>
      ) : (
        <div>
          <ul className=" flex flex-col gap-[20px] ">
            {tree.map((item, position) => (
              <li
                key={position}
                className={`peer  ${
                  isActive(item.link || '')
                    ? 'border-l-[3px] border-mediumPurple font-semibold text-mediumPurple'
                    : ''
                } `}>
                <Link
                  className={`flex gap-2 px-4 ${
                    position === 0
                      ? 'h-[44px] items-center rounded-lg bg-offWhite pl-3 pr-[80px]'
                      : ''
                  }`}
                  to={item.link || ''}>
                  {item.icon}
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TreeNav;
