import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import {toast} from 'react-hot-toast';
import {useParams} from 'react-router-dom';

import downArrow from '../../../assets/icons/close-circle.svg';
// import greenTick from '../../../assets/icons/tick-circle.svg';
import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal';
import Spinner from '../../../components/common/Spinner';
import {
  CustomerDocument,
  CustomerFieldsFragment,
  CustomerQuery,
  CustomersWithCursorPaginationDocument,
  CustomersWithCursorPaginationQuery,
  WalletActivationStatus,
  useChangeCustomerWalletStateMutation,
  useCustomerQuery,
  useGetKycVerificationStatusQuery,
} from '../../../generated';
import UserTopBar from '../components/UserTopBar';

type UserContent = {
  customer: CustomerFieldsFragment | null;
};
const UserContext = createContext<UserContent>({
  customer: {
    roles: [],
    transactionsCount: 0,
  },
});

export const useUserContext = () => useContext(UserContext);

const User = () => {
  const [showModal, setShowModal] = useState(false);
  const [restrictReason, setRestrictReason] = useState('');
  const [accountStatus, setAccountStatus] = useState('');

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  const {userId: customerId} = useParams();

  const {data: userDetails} = useCustomerQuery({
    variables: {
      id: customerId || '',
    },
  });

  const userValue: UserContent = {
    customer: userDetails?.customer || null,
  };

  const [changeCustomerWalletStateMutation] =
    useChangeCustomerWalletStateMutation();

  const {data: customerDetails, loading} = useGetKycVerificationStatusQuery({
    variables: {
      input: {
        userId: userDetails?.customer.user?.id || '',
      },
    },
  });

  useEffect(() => {
    if (userDetails?.customer?.wallet?.activation?.status) {
      setAccountStatus(userDetails.customer.wallet.activation.status);
    }
  }, [userDetails]);

  const confirmVerification = useCallback(async () => {
    const res = await changeCustomerWalletStateMutation({
      variables: {
        id: userDetails?.customer.id,
        reason: restrictReason,
        state: WalletActivationStatus.Verified,
      },
      update: (cache, result) => {
        const variables = {
          id: customerId || '',
        };
        const cached = cache.readQuery<CustomerQuery>({
          query: CustomerDocument,
          variables,
        });

        if (!cached) return;
        cache.writeQuery({
          variables,
          query: CustomerDocument,
          data: {
            ...cached,
            customer: {
              ...cached.customer,
              wallet: {
                ...cached.customer.wallet,
                activation: {
                  ...cached.customer.wallet?.activation,
                  status: result.data?.changeCustomerWalletState?.status,
                },
              },
            },
          },
        });

        const customersVariables = {
          input: {
            cursor: {
              first: 20,
            },
          },
        };

        const customersCached =
          cache.readQuery<CustomersWithCursorPaginationQuery>({
            query: CustomersWithCursorPaginationDocument,
            variables: customersVariables,
          });

        cache.writeQuery({
          variables: customersVariables,
          query: CustomersWithCursorPaginationDocument,
          data: {
            ...customersCached,
            customersWithCursorPagination: {
              ...customersCached?.customersWithCursorPagination,
              edges: [
                ...(
                  customersCached?.customersWithCursorPagination.edges || []
                ).map((item) =>
                  item.node.id === userDetails?.customer.id
                    ? {
                        ...item,
                        node: {
                          ...item.node,
                          wallet: {
                            ...item.node.wallet,
                            activation: {
                              ...item.node.wallet?.activation,
                              status:
                                result.data?.changeCustomerWalletState?.status,
                            },
                          },
                        },
                      }
                    : item
                ),
              ],
            },
          },
        });
      },
    });

    if (res.data?.changeCustomerWalletState) {
      toggleModal();
      toast.success(
        `Account for ${
          userDetails?.customer.user?.name || ''
        }  has been verified`,
        {
          position: 'bottom-left',
        }
      );

      setAccountStatus(WalletActivationStatus.Verified);
    }
  }, [
    changeCustomerWalletStateMutation,
    restrictReason,
    toggleModal,
    userDetails?.customer.id,
    customerId,
    userDetails?.customer.user?.name,
  ]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setRestrictReason(e.currentTarget.value);
  };

  return (
    <div className="flex flex-col gap-3">
      <UserContext.Provider value={userValue}>
        <UserTopBar />
      </UserContext.Provider>

      <div className="overflow-y-scroll scroll-smooth px-11 pb-0">
        <Modal
          isVisible={showModal}
          onClose={toggleModal}
          className="flex h-[320px] w-[420px] items-center justify-center rounded-t-2xl">
          <div>
            <div className="flex flex-col items-center p-3">
              <p className="text-xl font-semibold">Confirm Verification</p>
              <p className="py-2 text-xs">
                Are you sure you want to verify this user?
              </p>

              <textarea
                id="message"
                rows={4}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-xs text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Reason for changing the users' verification status..."
                onChange={() => handleChange}
              />

              <div className="flex pt-2">
                <span className="flex">
                  <Button
                    className="text-xs underline"
                    onClick={toggleModal}
                    label="Cancel"
                    text
                  />
                </span>
                <span className="flex">
                  <Button
                    className="h-8 text-xs"
                    label="Yes, Verify"
                    onClick={confirmVerification}
                  />
                </span>
              </div>
            </div>
          </div>
        </Modal>
        <div className=" mb-3 flex justify-between">
          <h2 className="font-bold">
            {userDetails?.customer.user?.name}'s Profile
          </h2>

          {accountStatus === 'VERIFIED' ? (
            <Button label="Verified Account" disabled />
          ) : (
            <Button
              className="h-[38px] bg-mediumPurple text-sm"
              label={
                loading ? (
                  <Spinner fillColor="fill-white" />
                ) : (
                  'Confirm Verification'
                )
              }
              onClick={toggleModal}
            />
          )}
        </div>

        <div className="border-black-700 relative overflow-x-auto border-[1px] p-2 shadow-md sm:rounded-lg">
          <p className="m-2 font-bold">Personal Data</p>

          <table className="w-full text-left text-sm text-gray-500 rtl:text-right">
            <thead className="text-xs text-gray-700 ">
              <tr>
                <th scope="col" className="bg-gray-50 px-6 py-3">
                  Value
                </th>
                <th scope="col" className="px-6 py-3">
                  Internal Data
                </th>
                <th scope="col" className="bg-gray-50 px-6 py-3">
                  External Data
                </th>
                <th scope="col" className="px-6 py-3" />
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-200">
                <th
                  scope="row"
                  className="whitespace-nowrap bg-gray-50 px-6 py-4 font-medium text-gray-900">
                  Primary Name
                </th>
                <td className="px-6 py-4">
                  {userDetails?.customer.user?.name || 'No name'}
                </td>
                <td className="bg-gray-50 px-6 py-4 ">
                  {customerDetails?.getKycVerificationStatus.aml?.refinitivCase
                    ? customerDetails?.getKycVerificationStatus.aml
                        ?.refinitivCase.results[0].primaryName
                    : 'Name Not Found'}
                </td>
                <td className="px-6 py-4">
                  {/* <span className="me-2 flex w-24 items-center justify-around rounded-[20px] bg-green-100 px-3 py-2 text-xs font-medium text-green-800">
                  <img src={greenTick} alt="" />
                  Match
                </span> */}
                  <span className="me-2 flex w-28 items-center justify-around rounded-[20px] bg-red-100 px-3 py-2 text-xs font-medium text-brightRed">
                    <img src={downArrow} alt="" />
                    Mismatch
                  </span>
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th
                  scope="row"
                  className="dark whitespace-nowrap bg-gray-50 px-6 py-4 font-medium text-gray-900">
                  National ID
                </th>
                <td className="px-6 py-4">
                  {userDetails?.customer.user?.identificationNumber ||
                    'ID number not entered'}
                </td>
                <td className="bg-gray-50 px-6 py-4">
                  {customerDetails?.getKycVerificationStatus.aml?.refinitivCase
                    ? customerDetails?.getKycVerificationStatus.aml
                        ?.refinitivCase.results[0].matchScore
                    : 'ID number not found'}
                  {}
                </td>
                <td className="px-6 py-4">
                  <span className="me-2 flex w-28 items-center justify-around rounded-[20px] bg-red-100 px-3 py-2 text-xs font-medium text-brightRed">
                    <img src={downArrow} alt="" />
                    Mismatch
                  </span>
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th
                  scope="row"
                  className="whitespace-nowrap bg-gray-50 px-6 py-4 font-medium text-gray-900">
                  Nationality
                </th>
                <td className="px-6 py-4">Kenya</td>
                <td className="bg-gray-50 px-6 py-4">
                  {customerDetails?.getKycVerificationStatus.aml?.refinitivCase
                    ? customerDetails?.getKycVerificationStatus.aml
                        ?.refinitivCase.results[0].nationality
                    : 'Nationality not found'}
                </td>
                <td className="px-6 py-4">
                  {/* <span className="me-2 flex w-24 items-center justify-around rounded-[20px] bg-green-100 px-3 py-2 text-xs font-medium text-green-800">
                  <img src={greenTick} alt="" />
                  Match
                </span> */}
                  <span className="me-2 flex w-28 items-center justify-around rounded-[20px] bg-red-100 px-3 py-2 text-xs font-medium text-brightRed">
                    <img src={downArrow} alt="" />
                    Mismatch
                  </span>
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th
                  scope="row"
                  className="dark whitespace-nowrap bg-gray-50 px-6 py-4 font-medium text-gray-900">
                  Phone Number
                </th>
                <td className="px-6 py-4">
                  {'+' +
                    (userDetails?.customer?.user?.phone?.countryCode ?? '-') +
                    ' ' +
                    (userDetails?.customer?.user?.phone?.number ?? '-')}
                </td>
                <td className="bg-gray-50 px-6 py-4">
                  {customerDetails?.getKycVerificationStatus.aml?.refinitivCase
                    ? customerDetails?.getKycVerificationStatus.aml
                        ?.refinitivCase.results[0].matchScore
                    : 'Phone Number not found'}
                  {}
                </td>
                <td className="px-6 py-4">
                  <span className="me-2 flex w-28 items-center justify-around rounded-[20px] bg-red-100 px-3 py-2 text-xs font-medium text-brightRed">
                    <img src={downArrow} alt="" />
                    Mismatch
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="flex justify-between p-4">
            <p>Risk Categories</p>
            <Button label="View from site" disabled />
          </div>
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right ">
            <thead className="text-xs text-gray-700 ">
              <tr>
                <th scope="col" className="bg-gray-50 px-6 py-3">
                  Primary Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="bg-gray-50 px-6 py-3">
                  Risk Categories
                </th>
                <th scope="col" className="px-6 py-3">
                  Gender
                </th>
                <th scope="col" className="px-6 py-3">
                  Nationality
                </th>
                <th scope="col" className="px-6 py-3">
                  Matchscore
                </th>
              </tr>
            </thead>
            <tbody>
              {customerDetails?.getKycVerificationStatus.aml?.refinitivCase
                ?.results ? (
                customerDetails?.getKycVerificationStatus.aml?.refinitivCase?.results?.map(
                  (item, i) => (
                    <tr key={i} className="border-b border-gray-200 ">
                      <th
                        scope="row"
                        className="whitespace-nowrap bg-gray-50 px-6 py-4 font-medium text-gray-900">
                        {item.primaryName}
                      </th>
                      <td className="px-6 py-4">{item.category}</td>
                      <td className="bg-gray-50 px-6 py-4 ">
                        <span className="me-2 rounded bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-800">
                          {item.riskCategories}
                        </span>
                      </td>
                      <td className="px-6 py-4">{item.gender}</td>
                      <td className="px-6 py-4">{item.nationality}</td>
                      <td className="px-6 py-4">{item.matchScore}</td>
                    </tr>
                  )
                )
              ) : (
                <tr className="flex p-6">
                  <td>Unavailable</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default User;
