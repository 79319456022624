import React, {useMemo, useState} from 'react';
import {Outlet} from 'react-router-dom';

import Navbar from '../components/common/Navbar';
import {useMemberStatisticsQuery} from '../generated';

const DriversAndRidersLayout = () => {
  const {data: stats} = useMemberStatisticsQuery();
  const [searchPhrase, setSearchPhrase] = useState('');
  const tabs = useMemo(
    () => [
      {
        name: 'All',
        count: stats?.memberStatistics.courierCount.all,
        link: '/couriers',
      },
      {
        name: 'Drivers',
        count: stats?.memberStatistics.courierCount.drivers,
        link: '/couriers/drivers',
      },
      {
        name: 'Riders',
        count: stats?.memberStatistics.courierCount.riders,
        link: '/couriers/riders',
      },
    ],
    [stats]
  );

  return (
    <div className="flex h-full flex-col">
      <div className="flex-2">
        <Navbar
          title="Drivers & Riders"
          tabs={tabs}
          placeholder="Find a driver/rider..."
          setSearchPhrase={setSearchPhrase}
        />
      </div>
      <div className="flex-1 overflow-y-scroll scroll-smooth px-11 py-7 pb-0">
        <Outlet context={[searchPhrase]} />
      </div>
    </div>
  );
};

export default DriversAndRidersLayout;
