import {useMemo, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import noPhoto from '../../../assets/icons/no-photo.svg';
import SmPhone from '../../../assets/icons/phone-small.svg';
import SmPerson from '../../../assets/icons/profile-circle.svg';
import ImgWithText from '../../../components/common/ImgWithText';
import Map, {MarkerDetails} from '../../../components/common/Map';
import {useCourierContext} from '../../../layouts/DriversAndRidersViewLayout';
import {readableWalletStatus} from '../../../utils/reusablefunctions';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import Section, {SectionProps} from '../../customers/components/Section';
import CourierTopBar from '../components/TopBar';

function CourierAccountDetails() {
  const {hash} = useLocation();
  const {courier} = useCourierContext();
  const topRef = useRef<HTMLDivElement>(null);

  const hashElement = useMemo(() => {
    return document.getElementById(hash ? hash.slice(1) : 'top');
  }, [hash]);

  const markers: MarkerDetails[] = useMemo(() => {
    if (!courier) return [];

    return [
      {
        id: courier?.id,
        online: courier?.online,
        name: courier.user?.name,
        lat: courier.currentPosition?.lat,
        lng: courier.currentPosition?.long,
        imgUrl: courier.avatar?.small,
      },
    ];
  }, [courier]);

  const displayInfo: SectionProps[] = useMemo(() => {
    return [
      {
        title: 'Profile Picture',
        body: (
          <div className="h-26 flex w-24 items-center justify-center rounded-lg  border shadow-sm">
            <img
              src={courier?.avatar?.large || noPhoto}
              alt="prof-picture"
              className="rounded-lg"
            />
          </div>
        ),
        showEditButton: false,
      },
      {
        title: 'Account status',
        body: (
          <ImgWithText
            text={readableWalletStatus(courier?.wallet?.activation?.status)[0]}
            url={readableWalletStatus(courier?.wallet?.activation?.status)[1]}
            variant="circle"
          />
        ),

        id: 'personal-details',
        showEditButton: false,
      },
      {
        title: 'Name',
        value: courier?.user?.name || '',
        endAdornment: 25,
        view: 'common',
        showEditButton: false,
      },
      {
        title: 'Phone number',
        value: `+${courier?.user?.phone?.countryCode || '-'}${
          courier?.user?.phone?.number || '-'
        }`,
        endAdornment: <img src={SmPhone} />,
        view: 'common',
        showEditButton: false,
      },
      {
        title: 'Identification details',
        body: `ID No: ${courier?.wallet?.activation?.idNumber || '-'}`,
        value: courier?.wallet?.activation?.idNumber || '',
        endAdornment: <img src={SmPerson} />,
        view: 'common',
        showEditButton: false,
      },
      {
        title: 'Email',
        value: courier?.user?.email || '',
        endAdornment: 50,
        view: 'common',
        showEditButton: false,
      },

      {
        title: 'Wallet details',
        body: (
          <p className="text-base font-medium leading-5 text-green-500">
            Balance: KES{' '}
            {courier?.wallet?.credit && formatMoney(courier?.wallet?.credit)}
          </p>
        ),
        value:
          (courier?.wallet?.credit && formatMoney(courier?.wallet?.credit)) ||
          '0',
        id: 'wallet-details',
        view: 'wallet',
        btnContent: 'View',
      },
    ];
  }, [courier]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    } else {
      topRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [hashElement]);

  return (
    <div
      ref={topRef}
      id="top"
      className="flex basis-full select-none flex-col pb-28 pt-0">
      <CourierTopBar />
      <div className="flex h-auto flex-col ">
        <div className="h-[400px] w-full pb-8">
          <Map markers={markers} />
        </div>
        {displayInfo.map((item, i) => (
          <div key={i} className="border-b border-cashia-grey">
            <Section walletId={courier?.wallet?.id} key={i} {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourierAccountDetails;
