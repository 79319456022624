export const localStorageRefreshTokenKey = 'cashia-token-refresh';
export const localStorageAccessTokenKey = 'cashia-token-access';

export const apiUrl =
  process.env.REACT_APP_API_URL || 'https://api.stg.cashia.com/graphql';
export const baseUrl =
  process.env.REACT_APP_BASE_URL || 'https://api.stg.cashia.com';

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const mapsApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
