import {useState, useEffect} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import useGetCustomers from './hooks/useGetCustomers';
import downArrow from '../../assets/icons/downArrow.svg';
import ExportIcon from '../../assets/icons/export.svg';
import Button from '../../components/common/Button';
import DropDown from '../../components/common/DropDown';
import Paginator from '../../components/common/Paginator';
import Spinner from '../../components/common/Spinner';
import CommonTable from '../../components/common/table';
import {WalletActivationStatus} from '../../generated';
import {readableWalletStatus} from '../../utils/reusablefunctions';

type Props = {
  walletStatus?: WalletActivationStatus[];
};

const Customers = ({walletStatus}: Props) => {
  const navigate = useNavigate();
  const [bulkAction, setBulkAction] = useState<string[]>([]);
  const [searchPhrase] = useOutletContext<[searchPhrase: string]>();

  const {
    columns,
    data,
    setData,
    parentColumns,
    selectedIds,
    setSelectedIds,
    setColumns,
    sortingColumns,
    loading,
    error,
    allCustomers,
    count,
    loadMore,
  } = useGetCustomers({walletStatus, searchTerm: searchPhrase || ''});

  const viewRecord = (id: string) => navigate(`/customer/${id}/account`);

  useEffect(() => {
    if (!searchPhrase) {
      setData(
        allCustomers?.customersWithCursorPagination.edges.map((customer) => ({
          id: customer.node.id || '',
          name: [
            customer.node.user?.name || '-',
            customer.node.avatar?.small || '',
          ],
          email: customer.node.user?.email || '-',
          phone_number: `+${customer.node.user?.phone?.countryCode || '-'}${
            customer.node.user?.phone?.number || '- '
          }`,
          cashtag: `@${customer.node.tag || '-'}`,
          status:
            (customer.node.wallet?.activation?.status &&
              readableWalletStatus(customer.node.wallet.activation.status)) ||
            '',
        })) || []
      );
      return;
    }

    setData(
      allCustomers?.customersWithCursorPagination.edges.map((customer) => ({
        id: customer.node.id || '',
        name: [
          customer.node?.user?.name || '-',
          customer.node.avatar?.small || '',
        ],
        email: customer.node.user?.email || '-',
        phone_number: `+${customer.node?.user?.phone?.countryCode || '-'}${
          customer.node.user?.phone?.number || '-'
        }`,
        cashtag: `@${customer.node.tag || '-'}`,
        status:
          (customer.node.wallet?.activation?.status &&
            readableWalletStatus(customer.node.wallet.activation.status)) ||
          '',
      })) || []
    );
  }, [
    allCustomers?.customersWithCursorPagination.edges,
    searchPhrase,
    setData,
  ]);

  if (loading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <>
      {data.length ? (
        <div className="flex h-full flex-col gap-6 overflow-y-scroll">
          <div className="ml-1">
            <DropDown
              dropDownPosition="left"
              className="top-12"
              actionElement={
                <Button
                  label="Bulk Actions"
                  rightIcon={<img src={downArrow} className="ml-2" />}
                  className="w-inherit rounded-md border border-black bg-transparent disabled:cursor-not-allowed disabled:border-greyish disabled:bg-greyish"
                  labelStyle="text-black text-sm"
                />
              }>
              <div className="w-[240px] py-4 text-sm text-gray-700">
                {['Delete', 'Disable'].map((item, i) => (
                  <label
                    key={i}
                    className="flex cursor-pointer gap-2 px-6 py-2 hover:bg-gray-100"
                    htmlFor={`input${i}`}>
                    <input
                      checked={bulkAction.includes(item)}
                      type="checkbox"
                      id={`input${i}`}
                      onChange={() =>
                        setBulkAction((prev) =>
                          prev.includes(item)
                            ? prev.filter((x) => item !== x)
                            : [...prev, item]
                        )
                      }
                      className="h-[16px] w-[16px] cursor-pointer self-center rounded-lg border-2 border-foggy accent-foggy checked:rounded-lg focus:border-none"
                    />
                    <p className="select-none text-base font-medium capitalize leading-5">
                      {item}
                    </p>
                  </label>
                ))}
                <hr className="my-3" />
                <div className="flex flex-row justify-between pr-6">
                  <Button
                    label="Clear"
                    text
                    labelStyle="text-base"
                    disabled={!bulkAction.length}
                    onClick={() => setBulkAction([])}
                  />
                  <Button
                    label="Apply"
                    labelStyle="text-base"
                    className="bg-black"
                  />
                </div>
              </div>
            </DropDown>
          </div>
          <div className="flex h-full w-full flex-col justify-between gap-6">
            <>
              <CommonTable
                columns={columns}
                data={data}
                setData={setData}
                parentColumns={parentColumns}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                setColumns={setColumns}
                sortingColumns={sortingColumns}
                viewRecord={viewRecord}
                viewRecordIconOrText={ExportIcon}
              />
              <Paginator
                loadMore={loadMore}
                count={count}
                pageInfo={allCustomers?.customersWithCursorPagination.pageInfo}
              />
            </>
          </div>
        </div>
      ) : (
        <h1 className="rounded-lg bg-slate-200 p-6 text-black">
          There are no customers to display
        </h1>
      )}
    </>
  );
};

export default Customers;
