import React, {PropsWithChildren} from 'react';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  width?: string;
  height?: string;
  className?: string;
  containerStyle?: string;
};

const Modal = ({
  isVisible,
  onClose,
  width,
  height,
  className,
  containerStyle,
  children,
}: PropsWithChildren<Props>) => {
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'wrapper') onClose();
  };

  const handleModal = (event: React.MouseEvent<HTMLDivElement>) =>
    event.stopPropagation();

  if (!isVisible) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black
      bg-opacity-80 ${containerStyle || ''}`}
      id="wrapper"
      onClick={handleClose}>
      <div
        className={`h-[${height || 'inherit'}] w-[${
          width || '600px'
        }] rounded-lg bg-white ${className || ''}`}
        id="modal"
        onClick={handleModal}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
