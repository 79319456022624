import {ChangeEvent, useState} from 'react';
import ReactPlayer from 'react-player';
import {useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as RestrictIcon} from '../../../assets/icons/eye-slash.svg';
import {ReactComponent as UnrestrictIcon} from '../../../assets/icons/eye.svg';
import {ReactComponent as LikesIcon} from '../../../assets/icons/reels_likes.svg';
import {ReactComponent as ReelsshareIcon} from '../../../assets/icons/reels_share.svg';
import {ReactComponent as ViewsIcon} from '../../../assets/icons/reels_views.svg';
import Modal from '../../../components/common/Modal';
import Spinner from '../../../components/common/Spinner';
import TextInput from '../../../components/common/TextInput';
import {useGetReelQuery, useUpdateReelMutation} from '../../../generated';
import EmptyProductsDisplay from '../components/EmptyProductsDisplay';
import ProductsDisplay from '../components/ProductsDisplay';

const ReelsAnalytics = () => {
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValue, setInputValue] = useState<string>('');
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsButtonEnabled(value.trim().length > 0);
  };
  const navigate = useNavigate();

  const {reelId} = useParams();

  const {data: reel, loading: reelLoading} = useGetReelQuery({
    variables: {
      id: reelId || '',
    },
    skip: !reelId,
  });

  const [updateReelMutation] = useUpdateReelMutation();

  return (
    <div className=" flex h-full w-full flex-col overflow-scroll">
      <div className="mb-3 h-[20%] w-[95%] items-center ">
        <h1 className=" pb-1  text-[27px] font-semibold text-neutral-800">
          Reels Analytics
        </h1>
        <div className=" mt-1 flex h-[250px] w-full gap-4 border-2 border-none">
          <div className=" flex  h-[40%] w-[20%] items-center rounded-[10px] border border-neutral-200 p-5">
            <ViewsIcon />
            <div className="ml-2 flex-col items-center justify-between">
              {reel?.reel?.views}
              <p className="  text-sm text-foggy">Views</p>
            </div>
          </div>
          <div className="flex  h-[40%] w-[20%] items-center rounded-[10px] border border-neutral-200 p-5">
            <LikesIcon />
            <div className="ml-2 flex-col items-center justify-between">
              {reel?.reel?.likes}
              <p className="flex-col text-sm text-foggy">Likes</p>
            </div>
          </div>
          <div className="flex  h-[40%] w-[20%] items-center rounded-[10px] border border-neutral-200 p-5">
            <ReelsshareIcon />
            <div className="ml-2 flex-col items-center justify-between">
              {reel?.reel?.shares}
              <p className="flex-col text-sm text-foggy">Shares</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 h-[90%] w-[97%] rounded-[10px] border border-neutral-200">
        <div className="ml-2 flex h-20 w-full items-center justify-between p-[10px]">
          <div className="flex flex-col">
            <h1 className="pt-[10px] text-[27px] font-semibold text-neutral-800">
              Reels Video
            </h1>

            <p className="text-[17px] font-semibold text-foggy">
              Track engangements, views and trends
            </p>
          </div>

          <div className="flex flex-col items-end">
            {reel?.reel.restricted ? (
              <>
                <button
                  className="mr-5 inline-flex h-[46px] w-[169px] cursor-pointer select-none items-center gap-2.5 rounded-lg bg-mediumPurple  p-[10px] text-white transition duration-200 hover:cursor-pointer"
                  onClick={() => setIsModalOpen(true)}>
                  <UnrestrictIcon />
                  Unrestrict Reel
                </button>
                <Modal
                  isVisible={isModalOpen}
                  className="w-[45%]"
                  onClose={() => setIsModalOpen(false)}>
                  <div className="p-6 text-center">
                    <p className="mb-3 p-4 text-3xl font-bold text-black">
                      Unrestrict Reel
                    </p>
                    <div className=" p-5">
                      <p className="mb-3 p-4 text-xl font-bold text-foggy">
                        This action will make the reel viewable for all
                        customers
                      </p>
                    </div>

                    <hr />
                  </div>

                  <div className="flex w-full flex-row justify-between  p-4">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className=" px-5  text-lg font-medium text-black  underline">
                      Cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={async () => {
                        await updateReelMutation({
                          variables: {
                            id: reelId || '',
                            input: {
                              restricted: !reel?.reel.restricted,
                            },
                          },
                        });
                        setIsModalOpen(false);
                      }}
                      className="mr-3 w-[145px] rounded-lg bg-black px-5 py-2.5 text-center text-sm font-medium text-white">
                      Yes, Unrestrict
                    </button>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                <button
                  className="mr-5 inline-flex h-[46px] w-[159px] cursor-pointer select-none items-center gap-2.5 rounded-lg bg-mediumPurple  p-[10px] text-white transition duration-200 hover:cursor-pointer"
                  onClick={() => setShowModal(true)}>
                  <RestrictIcon />
                  Restrict Reel
                </button>
                <Modal
                  isVisible={showModal}
                  className=" w-[45%]"
                  onClose={() => setShowModal(false)}>
                  <div className="p-4 text-center">
                    <p className="font-metropolis mb-3 p-2 text-2xl font-bold text-black">
                      Please give a reason for this restriction
                    </p>
                    <div className=" mb-3 pl-2 pr-2">
                      <textarea
                        className="w-[90%] resize-none border bg-offWhite p-4"
                        cols={16}
                        rows={4}
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>

                    <hr />
                  </div>
                  <div className="flex w-full flex-row justify-between  p-4">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => setShowModal(false)}
                      className=" px-5  text-lg font-medium text-black  underline">
                      Cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      disabled={!isButtonEnabled}
                      onClick={async () => {
                        await updateReelMutation({
                          variables: {
                            id: reelId || '',
                            input: {
                              restricted: !reel?.reel.restricted,
                              reason: inputValue,
                            },
                          },
                        });
                        setShowModal(false);
                        setInputValue('');
                      }}
                      className={`mr-3 w-[145px] rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white ${
                        isButtonEnabled ? 'bg-black' : 'bg-greyish'
                      }`}>
                      Restrict
                    </button>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
        {reelLoading ? (
          <Spinner className="mt-20 h-[40px] w-full" />
        ) : (
          <div key={reel?.reel.id}>
            <div className="flex h-full w-full gap-14">
              <div className="ml-12 flex h-[580px] w-[400px] items-center justify-center rounded-[10px] border-2 border-none border-neutral-200 object-cover">
                <div className="flex h-full flex-col items-center justify-center gap-7">
                  <div className="relative overflow-hidden rounded-[15px] border-4 border-neutral-800">
                    <ReactPlayer
                      controls={true}
                      pip={false}
                      loop={true}
                      url={reel?.reel.link.hls}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-[92%] pt-16">
                <div className="flex w-[92%] flex-col gap-12">
                  <div className="flex flex-col gap-1">
                    <p className="w-full font-['Metropolis'] text-xl font-semibold text-neutral-800">
                      Caption
                    </p>
                    <TextInput
                      containerStyle="h-12 "
                      inputStyle="font-metropolis font-normal text-3xl"
                      value={reel?.reel.name}
                      disabled
                    />
                  </div>
                  <div className="flex w-full flex-col gap-1">
                    <p className="font-['Metropolis'] text-xl font-semibold text-neutral-800">
                      Attached Products
                    </p>
                    {reel?.reel.products.length ? (
                      <ProductsDisplay
                        omitActions
                        products={reel?.reel.products}
                      />
                    ) : (
                      <EmptyProductsDisplay
                        icon={
                          <div
                            className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-[#E0F8FF] text-3xl font-bold text-cashiaBlue"
                            onClick={() =>
                              navigate(
                                `/reels/edit/${
                                  reel?.reel.id || ''
                                }/tag-products`
                              )
                            }>
                            +
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ReelsAnalytics;
