import React, {useMemo, useState} from 'react';
import {Outlet} from 'react-router-dom';

import {useMemberStatisticsQuery} from '../generated';
import Navbar from '../pages/customers/components/Navbar';

const CustomerLayout = () => {
  const {data: stats} = useMemberStatisticsQuery();
  const [searchPhrase, setSearchPhrase] = useState('');

  const tabs = useMemo(
    () => [
      {
        name: 'All',
        count: stats?.memberStatistics.customerCount.all,
        link: '/customers',
      },
      {
        name: 'Verified',
        count: stats?.memberStatistics.customerCount.verified,
        link: '/customers/verified',
      },
      {
        name: 'Pending',
        count: stats?.memberStatistics.customerCount.pending,
        link: '/customers/pending',
      },
      {
        name: 'In Progress',
        count: stats?.memberStatistics.customerCount.inProgress,
        link: '/customers/in-progress',
      },
    ],
    [stats]
  );

  return (
    <div className="flex h-full flex-col">
      <div className="flex-2">
        <Navbar
          title="Customers"
          tabs={tabs}
          placeholder="Find a customer..."
          setSearchPhrase={setSearchPhrase}
        />
      </div>
      <div className="flex-1 overflow-y-scroll scroll-smooth px-11 py-7 pb-0">
        <Outlet context={[searchPhrase]} />
      </div>
    </div>
  );
};

export default CustomerLayout;
