import {Money} from '../../generated';

const formatMoney = (money: Money | number, min = 0, max = 2) => {
  const formatter = new Intl.NumberFormat('en-KE', {
    style: 'decimal',
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
  if (typeof money === 'number') return formatter.format(money / 100);
  return formatter.format(money.amountInCents / 100);
};

export default formatMoney;
