import React from 'react';
import {useParams} from 'react-router-dom';

import AddressIcon from '../../../assets/icons/location-purple.svg';
import Spinner from '../../../components/common/Spinner';
import {useMerchantDetailsQuery} from '../../../generated';

const MerchantLocation = () => {
  const {merchantId} = useParams();

  const {data, loading, error} = useMerchantDetailsQuery({
    variables: {
      id: merchantId ? merchantId : '',
    },
  });

  if (loading) {
    return (
      <div className="mt-2 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-3 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }

  return (
    <div className="flex w-full">
      <div className="flex w-full flex-col">
        <h1 className="text-2xl font-semibold">Locations</h1>
        {data?.merchant?.addresses?.length ? (
          data?.merchant?.addresses?.map((address, id) => (
            <div
              key={id}
              className="mb-3 mt-2 flex w-[488px] flex-col rounded-md border-2 border-solid p-4">
              <p className="text-sm font-medium text-cashia-grey">
                Building Name
              </p>
              <h1 className="text-lg font-semibold">
                {address.location.formattedAddress.split(',')[0]}
              </h1>
              <h3 className="text-black">
                {address.location.formattedAddress.split(',').slice(1)}
              </h3>
              <p className="mb-2 mt-2 text-sm font-normal">Address</p>
              <div className="flex gap-1">
                <img src={AddressIcon} alt="" />
                <p className="font-medium text-purple-900">
                  {address.location.formattedAddress}
                </p>
              </div>
            </div>
          ))
        ) : (
          <h1 className="mt-2 max-h-20 w-full rounded-lg font-normal text-black">
            There are no locations to display
          </h1>
        )}
      </div>
    </div>
  );
};

export default MerchantLocation;
