import {useMemo, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import LocationTick from '../../../assets/icons/location-tick.svg';
import LockIcon from '../../../assets/icons/lock.svg';
import SmPhone from '../../../assets/icons/phone-small.svg';
import SmPerson from '../../../assets/icons/profile-circle.svg';
import ImgWithText from '../../../components/common/ImgWithText';
import {useCustomerContext} from '../../../layouts/CustomerViewLayout';
import {readableWalletStatus} from '../../../utils/reusablefunctions';
import formatMoney from '../../../utils/reusablefunctions/formatMoney';
import Section, {SectionProps} from '../components/Section';
import TopBar from '../components/TopBar';

const AccountDetails = () => {
  const {hash} = useLocation();
  const {customer} = useCustomerContext();

  const hashElement = useMemo(() => {
    return document.getElementById(hash ? hash.slice(1) : 'top');
  }, [hash]);

  const displayInfo: SectionProps[] = useMemo(() => {
    return [
      {
        title: 'Account status',
        body: (
          <ImgWithText
            text={readableWalletStatus(customer?.wallet?.activation?.status)[0]}
            url={readableWalletStatus(customer?.wallet?.activation?.status)[1]}
            variant="circle"
          />
        ),
        value: customer?.wallet?.activation?.status,
        id: 'personal-details',
        view: 'status',
      },
      {
        title: 'Name',
        value: customer?.user?.name || '',
        endAdornment: 25,
        view: 'common',
        updateUserKey: 'name',
      },
      {
        title: 'Email',
        value: customer?.user?.email || '',
        endAdornment: 50,
        view: 'common',
        updateUserKey: 'email',
      },
      {
        title: 'Phone number',
        value: `+${customer?.user?.phone?.countryCode || '-'}${
          customer?.user?.phone?.number || '-'
        }`,
        endAdornment: <img src={SmPhone} />,
        view: 'common',
        updateUserKey: 'phone',
      },
      {
        title: 'Identification details',
        body: `ID No: ${customer?.wallet?.activation?.idNumber || '-'}`,
        value: customer?.wallet?.activation?.idNumber || '',
        endAdornment: <img src={SmPerson} />,
        view: 'common',
        updateUserKey: 'identificationNumber',
      },
      {
        title: 'Location',
        body: (
          <ImgWithText
            url={LocationTick}
            text={customer?.currentAddress?.location.formattedAddress || '-'}
            variant="circle"
          />
        ),
        value: customer?.currentAddress?.location.formattedAddress || '',
        btnContent: <img src={LockIcon} />,
      },
      {
        title: 'Wallet details',
        body: (
          <p className="text-base font-medium leading-5 text-green-500">
            Balance: KES{' '}
            {customer?.wallet?.credit && formatMoney(customer?.wallet?.credit)}
          </p>
        ),
        value:
          (customer?.wallet?.credit && formatMoney(customer?.wallet?.credit)) ||
          '0',
        id: 'wallet-details',
        view: 'wallet',
        btnContent: 'View',
      },
      {
        title: 'Transactions',
        value: `${String(customer?.transactionsCount)} transactions`,
        btnContent: 'View',
      },
    ];
  }, [customer]);

  useEffect(() => {
    hashElement?.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
    });
  }, [hashElement]);
  return (
    <div id="top" className="flex select-none flex-col pb-28 pt-0	">
      <TopBar />
      {displayInfo.map((item, i) => (
        <Section walletId={customer?.wallet?.id} key={i} {...item} />
      ))}
    </div>
  );
};

export default AccountDetails;
